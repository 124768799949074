import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  addFrame,
  addLayer,
  showSortLayersView,
  hideSortLayersView,
  showExpandedFrameTools,
  hideExpandedFrameTools,
  generateCroppedSpriteSheetData //eslint-disable-line
} from '../../store/actions/uiActions';
import { centerFrameContent } from '../../store/actions/canvasActions';
import { cloneCurrentFrameToRest } from '../../store/actions';
import * as assets from '../../config/assets';
import { THEME_TYPES } from '../../store/actions/types';
import styles from '../styles/FrameContainer.module.scss';
import Frames from './Frames';
import LayersSort from '../layers/LayersSort';
import Layers from '../layers/Layers';
import layout from '../../config/layout';
import { View, Button, ScrollView, Text, Image } from '../BaseComponents';

class FrameContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indicator: false,
      scrollContentHeight: null,
      itemHeight: 92,
    };

    this.scrollRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentFrame !== this.props.currentFrame) {
      const height = this.props.showExpandedFrameTools ? this.props.screen.orgH - 430 : this.props.screen.orgH - 330;
      const indexOffset = this.props.currentFrame * 100;
      const pos = this.scrollRef.current.scrollTop;

      // console.log('index offset', indexOffset);
      // console.log('height', height);
      // console.log('scrolltop', pos);

      if (indexOffset > pos + height - 100) {
        this.scrollRef.current.scrollTop = indexOffset;
        // console.log('---------> ok');
      } else if (indexOffset < pos) {
        this.scrollRef.current.scrollTop = indexOffset;
      }
    }
  }

  renderScrollViewWithLayers() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    const light = theme.current === THEME_TYPES.LIGHT_THEME;
    return (
      <ScrollView
        scrollEnabled={false}
        horizontal
        className={styles.layerScroll}
        style={{
          backgroundColor: th.panelBackground,
        }}
      >
        <Layers light={light} />
      </ScrollView>
    );
  }

  renderScrollView() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    const light = theme.current === THEME_TYPES.LIGHT_THEME;
    return (
      <View style={{ opacity: 1 }}>
        <View
          className={styles.framesTitle}
          style={{
            backgroundColor: theme.themes[theme.current].sectionTitleBackground,
          }}
        >
          <Text className={styles.framesTitleText} style={{ color: theme.themes[theme.current].sectionTitleColor }}>
            {this.props.showSortLayersView ? 'Rearrange Layers' : 'Frames'}
          </Text>
          {!this.props.showSortLayersView && (
            <View id="expandTitle">
              <Button
                className={styles.expandBtn}
                style={{
                  backgroundColor: light ? '#181818' : '#181818',
                }}
                onClick={() => {
                  if (this.props.showExpandedFrameTools) {
                    this.props.hideFrameTools();
                  } else {
                    this.props.expandFrameTools();
                  }
                }}
                description="toggle advanced tools"
                position={{ width: 120 }}
              >
                <Image
                  src={assets.back}
                  style={{
                    width: 40,
                    transform: this.props.showExpandedFrameTools ? 'rotate(90deg)' : 'rotate(-90deg)',
                  }}
                />
              </Button>
            </View>
          )}
        </View>

        <div
          className={styles.frameScroll}
          ref={this.scrollRef}
          style={{
            backgroundColor: th.panelBackground,
            height: this.props.showExpandedFrameTools ? this.props.screen.orgH - 430 : this.props.screen.orgH - 330,
            top: this.props.showExpandedFrameTools ? 310 : 210,
          }}
        >
          {this.props.showSortLayersView ? <LayersSort /> : <Frames />}
        </div>
        <Button
          className={styles.addNewFrame}
          style={{ backgroundColor: '#101010' }}
          description="add frame"
          position={{ bottom: '5px', left: 'calc(50% - 33px)' }}
          onClick={() => this.props.addNewFrame()}
        >
          <View className={styles.addWrapper}>
            <View className={styles.addFrameBtn}>
              <Image
                className={styles.asset}
                src={assets.add}
                style={{ opacity: this.props.showSortLayersView ? 0 : 1 }}
              />
            </View>
          </View>
        </Button>
      </View>
    );
  }

  render() {
    const { theme } = this.props;
    const light = theme.current === THEME_TYPES.LIGHT_THEME;
    return (
      <React.Fragment>
        <div>
          <View
            className={styles.container}
            style={{
              marginLeft: this.props.showFrames ? 0 : -layout.framesViewWidth,
            }}
          >
            {/* LAYERS */}

            <View
              className={styles.layersTitle}
              style={{
                backgroundColor: theme.themes[theme.current].sectionTitleBackground,
              }}
            >
              <Text className={styles.layersTitleText} style={{ color: theme.themes[theme.current].sectionTitleColor }}>
                Layers
              </Text>
            </View>
            <View className={styles.layerInterface}>
              <View className={styles.currentLayer}>
                <Text className={styles.currentLayerText}>xxx</Text>
              </View>
              <ScrollView
                horizontal="true"
                className={styles.layerScroll}
                style={{
                  backgroundColor: '#1d1d1d',
                }}
              >
                <Layers light={light} />
              </ScrollView>
              <View className={styles.scrollWrapper}>
                <Button
                  disabled={this.props.numLayers < 2}
                  className={styles.addNewLayer}
                  style={{
                    backgroundColor: 'rgba(39,39,39,0.9)',
                    borderColor: '#333',
                    marginBottom: 10,
                    opacity: this.props.numLayers > 1 ? 1 : 0.1,
                    filter: this.props.showSortLayersView ? 'invert(100)' : 'invert(0)',
                  }}
                  description="rearrange layers"
                  position={{ marginLeft: -20, marginTop: -15, width: 120 }}
                  onClick={() =>
                    this.props.showSortLayersView ? this.props.hideSortLayers() : this.props.showSortLayers()
                  }
                >
                  <Image className={styles.asset} src={assets.drag} style={{ opacity: 1 }} />
                </Button>
                <Button
                  disabled={this.props.showSortLayersView}
                  className={styles.addNewLayer}
                  style={{
                    backgroundColor: light ? 'rgba(39,39,39,0.9)' : 'rgba(39,39,39,0.9)',
                    borderColor: light ? '#333' : '#333',
                    opacity: this.props.showSortLayersView ? 0.3 : 1,
                  }}
                  description="add layer"
                  position={{ marginLeft: -5, marginTop: -15 }}
                  onClick={() => this.props.addLayer()}
                >
                  <Image src={assets.newlayer} className={styles.asset} />
                </Button>
              </View>
            </View>

            {/* FRAME SPECIAL TOOLS */}

            <div className={styles.frameTools}>
              <Button
                description="center content"
                position={{ marginLeft: '-30px' }}
                onClick={() => {
                  this.props.centerContent();
                }}
              >
                <Image src={assets.centerFrame} className={styles.frameAsset} style={{ marginRight: 50 }} />
              </Button>

              <Button
                description="clone current frame to all frames"
                position={{ marginLeft: '-70px' }}
                onClick={() => {
                  this.props.cloneToRest();
                }}
              >
                <Image src={assets.duplicateFrameToAll} className={styles.frameAsset} />
              </Button>
            </div>

            {/* FRAMES */}

            {this.renderScrollView()}
          </View>
        </div>
        <div>
          {/* BACKGROUND CLOSE */}
          {this.props.showFrames && !this.props.dockFrames ? (
            <Button
              className={styles.overlayClose}
              style={{ width: window.innerWidth, height: window.innerHeight }}
              onClick={() => {
                this.props.hideFrames();
              }}
            />
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showFrames: state.settings.showFrames,
  dockFrames: state.settings.dockFrames,
  numLayers: state.data.layers.length,
  loading: state.data.loadingAnimation,
  isDragging: state.settings.framesDrag,
  showExpandedFrameTools: state.settings.showExpandedFrameTools,
  theme: state.theme,
  showSortLayersView: state.settings.showLayerSortView,
  currentFrame: state.data.currentFrame,
});

const mapDispatch = (dispatch) => ({
  addNewFrame: () => dispatch(addFrame()),
  addLayer: () => dispatch(addLayer()),
  hideFrames: () => dispatch({ type: 'HIDE_FRAMES_VIEW' }),
  showSortLayers: () => dispatch(showSortLayersView()),
  hideSortLayers: () => dispatch(hideSortLayersView()),
  expandFrameTools: () => dispatch(showExpandedFrameTools()),
  hideFrameTools: () => dispatch(hideExpandedFrameTools()),
  centerContent: () => dispatch(centerFrameContent()),
  cloneToRest: () => dispatch(cloneCurrentFrameToRest()),
});

export default connect(mapState, mapDispatch)(FrameContainer);
