import React from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import { toggleExpand } from '../../store/actions/uiActions';
import styles from '../styles/AnimationOverlay.module.scss';

class AnimationView extends React.Component {
  render() {
    return (
      <View style={{ position: 'absolute', zIndex: 300001, }}>
        {this.props.expanded && (
          <Button
            className={styles.expandOverlay}
            style={{ width: this.props.screen.orgW, height: this.props.screen.orgH, background: 'rgba(0, 0, 0, 0.85' }}
            onClick={() => {
              this.props.toggleExpand();
            }}
          />
        )}
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  expanded: state.settings.expanded,
});

const mapDispatch = (dispatch) => ({
  toggleExpand: () => dispatch(toggleExpand()),
});

export default connect(mapState, mapDispatch)(AnimationView);
