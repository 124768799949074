export default {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_ERROR: 'SIGN_IN_ERROR',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  REQUEST_SAVE: 'REQUEST_SAVE',
  SHOW_LOGIN_SCREEN: 'SHOW_LOGIN_SCREEN',
  HIDE_LOGIN_SCREEN: 'HIDE_LOGIN_SCREEN',
  SIGN_IN_AND_SAVE: 'SIGN_IN_AND_SAVE',
  SET_FIREBASE_REF: 'SET_FIREBASE_REF',
  SET_SIP: 'SET_SIP',
  SILENT_SIGN_IN_SUCCESS: 'SILENT_SIGN_IN_SUCCESS',
};
