/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import { View } from '../BaseComponents';
import { connect } from 'react-redux';
import layout from '../../config/layout';
import styles from '../styles/ToolCanvas.module.scss';
import { TOOL_TYPES } from '../../store/actions/types';
import { toolMode } from '../../utils';
import { normalizePixels } from '../../utils/normalizePixels';
import { generateLineFromPoints } from '../../utils/math';
import { reducePixels } from '../../utils/reducePixels';
import {
  setToolCanvasRef,
  generateSpriteData,
  setPixels,
  saveCurrentPixelData,
} from '../../store/actions/canvasActions';
import { selectDraw, selectSquare } from '../../store/actions/toolsActions';

class ToolCanvas extends Component {
  constructor(props) {
    super(props);
    this.toolCanvas = React.createRef();

    this.state = {
      isDrawing: false,
      isDragging: false,
    };

    this.iphone = window.navigator.userAgent.match('iPhone') || window.navigator.userAgent.match('iPod') ? true : false;
    this.ipad = window.navigator.userAgent.match('iPad') ? true : false;

    // track first vector
    this.pressXY = null;
    this.selectedArea = null;
    // keep track of last pixels
    this.lastPixel = null;
    this.lastMirroredPixelX = null;
    // store stream of unique pixels for state processing
    this.pixelStream = [];
    this.localSet = [];
    this.copyPixels = [];
    this.dragXY = null;
    this.dragShape = null;
    this.untouchedSet = [];

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  componentDidMount() {
    const canvas = this.toolCanvas.current;
    this.props.setCanvasRef(this.toolCanvas.current);

    if (this.iphone || this.ipad) {
      canvas.addEventListener('touchstart', this.onMouseDown, false);
      canvas.addEventListener('touchend', this.onMouseUp, false);
      canvas.addEventListener('touchmove', this.onMouseMove, false);
    } else {
      canvas.addEventListener('mousedown', this.onMouseDown, false);
      canvas.addEventListener('mouseup', this.onMouseUp, false);
      canvas.addEventListener('mousemove', this.onMouseMove, false);
    }
  }

  onMouseDown(evt) {
    this.pixelStream = [];
    this.squareShape = null;
    this.lineShape = null;
    this.pressXY = {
      x: this.getPixel(evt).x,
      y: this.getPixel(evt).y,
    };

    if (!this.state.isDrawing) {
      this.setState({ isDrawing: true });
    }

    this.handleInput(evt);
  }

  onMouseMove(evt) {
    if (this.state.isDrawing || this.state.isDragging) {
      this.state.isDragging && this.selectedArea ? this.handleDrag(evt) : this.handleTouch(evt);
    }
  }

  onMouseUp() {
    this.onDragEnd();
  }

  onDragEnd() {
    // reset pixels
    this.lastPixel = null;
    this.lastMirroredPixelX = null;
    this.pressXY = null;
    this.onRelease();
    this.squareShape = null;
    this.lineShape = null;
  }

  handleInput(evt) {
    if (this.selectedArea) {
      this.setState({ isDragging: true });
      this.handleDrag(evt);
    } else {
      this.selectedArea = null;
      this.setState({ isDragging: false });
      this.handleTouch(evt);
    }
  }

  buildShape() {
    if (!this.selectedArea) {
      return {
        x1: null,
        x2: null,
        y1: null,
        y2: null,
      };
    }
    const {
      direction: { v, h },
      x1,
      x2,
      y1,
      y2,
    } = this.selectedArea;
    const { x, y } = this.pressXY;
    const direction = `${v || null}_${h || null}`;
    // console.log("direc", direction);
    // console.log("x", x, "y", y, "---- x1", x1, "x2", x2, "y1", y1, "y2", y2);
    let shape = {};

    switch (direction) {
      case 'up_right':
        if (x >= x1 && x <= x2 && y <= y1 && y >= y2) {
          shape = {
            x1,
            x2,
            y1: y2,
            y2: y1,
          };
        }
        break;
      case 'up_left':
        if (x <= x1 && x >= x2 && y <= y1 && y >= y2) {
          shape = {
            x1: x2,
            x2: x1,
            y1: y2,
            y2: y1,
          };
        }
        break;
      case 'down_right':
        if (x >= x1 && x <= x2 && y >= y1 && y <= y2) {
          shape = {
            x1,
            x2,
            y1,
            y2,
          };
        }
        break;
      case 'down_left':
        if (x <= x1 && x >= x2 && y >= y1 && y <= y2) {
          shape = {
            x1: x2,
            x2: x1,
            y1,
            y2,
          };
        }
        break;
      default:
    }

    return shape;
  }

  getCursorPosition(event) {
    const factor = 1 - this.props.scale;
    const canvas = this.toolCanvas.current;
    const rect = canvas.getBoundingClientRect();
    const isIos = this.iphone || this.ipad;
    const clientX = isIos ? event.touches[0].clientX : event.clientX;
    const clientY = isIos ? event.touches[0].clientY : event.clientY;
    const cX = clientX / factor;
    const cY = clientY / factor;
    const rectLeft = rect.left / factor;
    const rectTop = rect.top / factor;
    const x = cX - rectLeft;
    const y = cY - rectTop;
    const locX = x;
    const locY = y;
    const w = (this.props.screen.width / this.props.settings.gridSize) * this.props.settings.zoom;
    const h = (this.props.screen.width / this.props.settings.gridSize) * this.props.settings.zoom;
    const _x = Math.floor(locX / w);
    const _y = Math.floor(locY / h);
    return {
      x: _x,
      y: _y,
      w,
      h,
    };
  }

  getPixel(e) {
    return this.getCursorPosition(e);
  }

  handleDrag(e) {
    const { x, y, w, h } = this.getPixel(e);
    const shape = this.buildShape();
    const diffX = Math.abs(shape.x2 - shape.x1);
    const diffY = Math.abs(shape.y2 - shape.y1);
    const ctx = this.toolCanvas.current.getContext('2d');

    ctx.clearRect(0, 0, layout.canvasDrawSize, layout.canvasDrawSize);

    const { data } = this.props;
    const rawPixelData = normalizePixels(data.layers[data.currentLayer].pixelData[data.currentFrame]);
    this.copyPixels = rawPixelData.filter(
      (p) => p.x >= shape.x1 && p.x <= shape.x2 && p.y >= shape.y1 && p.y <= shape.y2
    );

    this.untouchedSet = [];
    rawPixelData.forEach((p) => {
      if (this.copyPixels.findIndex((t) => t.x === p.x && t.y === p.y && t.color === p.color) === -1) {
        this.untouchedSet.push(p);
      }
    });

    const blur = 0;

    // copy area
    ctx.fillStyle = 'rgba(0,0,0,0.25)';
    const squareX = x - Math.floor(diffX / 2);
    const squareY = y - Math.floor(diffY / 2);
    ctx.fillRect(squareX * w, squareY * h, w * (diffX + 1) - blur, h * (diffY + 1) - blur);

    // pixels
    this.copyPixels.forEach((pixel) => {
      ctx.fillStyle = pixel.color;
      const posX = x + pixel.x - shape.x1 - Math.floor(diffX / 2);
      const posY = y + pixel.y - shape.y1 - Math.floor(diffY / 2);
      ctx.fillRect(posX * w - blur, posY * h - blur, w, h);
    });

    this.dragShape = shape;
    this.dragXY = { x, y };
  }

  applyCopy() {
    // console.log("**** applyCopy");
    const { moveMode, cloneMode } = toolMode(this.props.active);
    this.validateTool();

    const layersCopy = JSON.stringify(this.props.data.layers);
    this.props.saveCurrentPixelData(JSON.parse(layersCopy));

    const { x, y } = this.dragXY;
    const { data } = this.props;
    const diffX = Math.abs(this.dragShape.x2 - this.dragShape.x1);
    const diffY = Math.abs(this.dragShape.y2 - this.dragShape.y1);
    const ctx = this.toolCanvas.current.getContext('2d');
    ctx.clearRect(0, 0, layout.canvasDrawSize, layout.canvasDrawSize);

    // pixels
    const pixels =
      moveMode && !cloneMode ? this.untouchedSet : data.layers[data.currentLayer].pixelData[data.currentFrame];

    const localSet = this.copyPixels
      .map((pixel) => {
        const posX = x + pixel.x - this.dragShape.x1 - Math.floor(diffX / 2);
        const posY = y + pixel.y - this.dragShape.y1 - Math.floor(diffY / 2);
        return { x: posX, y: posY, color: pixel.color };
      })
      .concat(pixels);

    const reducedSet = [];
    reducePixels(localSet, reducedSet);
    const newSet = reducedSet.sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y);

    this.props.setPixels(newSet);
    this.props.generateSpriteData();
  }

  handleTouch(e) {
    const { lineMode } = toolMode(this.props.active);

    this.validateTool();

    const { x, y, w, h } = this.getPixel(e);

    let points = [];
    if (!lineMode) {
      points = this.modeMove(x, y);
      this.squareShape = { shape: points, w, h };
      this.drawSquareOverlay(points, w, h);
    } else {
      // linemode
      const { gridSize } = this.props;
      this.lastPixel = this.lastPixel ? this.lastPixel : { x, y };
      points = this.lineFromPoints(this.lastPixel.x, this.lastPixel.y, x, y, gridSize);

      this.lineShape = { shape: points, w, h };
      this.drawLineOverlay(points, w, h);
    }
  }

  validateTool() {
    const { moveMode, cloneMode, squareMode, lineMode } = toolMode(this.props.active);
    // console.log("validate tool", moveMode, cloneMode);
    if (!moveMode || !cloneMode || !squareMode || !lineMode) {
      return;
    }
  }

  modeDrag(_x, _y) {
    let points = null;
    const { gridSize } = this.props;
    this.lastPixel = this.lastPixel ? this.lastPixel : { x: _x, y: _y };
    points = generateLineFromPoints(this.lastPixel.x, this.lastPixel.y, _x, _y, gridSize);

    this.lastPixel = { x: _x, y: _y };
    return points;
  }

  lineFromPoints(x, y, _x, _y, gridSize) {
    return generateLineFromPoints(x, y, _x, _y, gridSize);
  }

  modeMove(_x, _y) {
    const { gridSize } = this.props;
    let points = null;
    this.lastPixel = this.lastPixel ? this.lastPixel : { x: _x, y: _y };
    points = this.lineFromPoints(this.lastPixel.x, this.lastPixel.y, _x, _y, gridSize);

    const dragData = this.dragData(points);
    const genPoints = this.genSquare(dragData);

    this.selectedArea = dragData;
    this.lastPixel = { x: _x, y: _y };

    return genPoints;
  }

  dragData(points) {
    if (!points.length) {
      return null;
    }
    const x1 = this.pressXY.x;
    const y1 = this.pressXY.y;
    const x2 = points[0].x;
    const y2 = points[0].y;

    const direction = { v: 'down', h: 'right' };
    if (x2 < x1 && y2 < y1) {
      direction.v = 'up';
      direction.h = 'left';
    } else if (x2 > x1 && y2 < y1) {
      direction.v = 'up';
      direction.h = 'right';
    } else if (x2 < x1 && y2 > y1) {
      direction.v = 'down';
      direction.h = 'left';
    } else if (x2 > x1 && y2 > y1) {
      direction.v = 'down';
      direction.h = 'right';
    }

    return {
      direction,
      x1,
      x2,
      y1,
      y2,
    };
  }

  valBetween(v, min, max) {
    return Math.min(max, Math.max(min, v));
  }

  genSquare(dragData) {
    if (!dragData || !dragData.direction) {
      return;
    }
    const { direction, x1, x2, y1, y2 } = dragData;
    const { gridSize } = this.props;

    const xDrag = this.valBetween(x2, 0, gridSize - 1);
    const yDrag = this.valBetween(y2, 0, gridSize - 1);

    const genPoints = [];

    const diffX = Math.abs(xDrag - x1);
    const diffY = Math.abs(yDrag - y1);
    // console.log("direction", direction, "diff_X", diffX, "diff_Y", diffY);

    if (diffX && diffY <= 1) {
      return genPoints;
    }

    for (let hLine = 0; hLine <= diffX; hLine++) {
      genPoints.push({
        x: direction.h === 'right' ? x1 + hLine : x1 - hLine,
        y: y1,
      });
      genPoints.push({
        x: direction.h === 'right' ? x1 + hLine : x1 - hLine,
        y: yDrag,
      });
    }

    for (let vLine = 0; vLine <= diffY; vLine++) {
      genPoints.push({
        x: x1,
        y: direction.v === 'up' ? y1 - vLine : y1 + vLine,
      });
      genPoints.push({
        x: xDrag,
        y: direction.v === 'up' ? y1 - vLine : y1 + vLine,
      });
    }
    return genPoints;
  }

  // update canvas context (move feedback)
  drawSquareOverlay(points, w, h) {
    if (!points) {
      return;
    }
    const ctx = this.toolCanvas.current.getContext('2d');
    const { active, activeColor } = this.props;
    ctx.fillStyle = active === TOOL_TYPES.SQUARE ? activeColor : 'rgba(0,0,0,0.5)';
    ctx.clearRect(0, 0, layout.canvasDrawSize, layout.canvasDrawSize);

    points.forEach((point) => {
      ctx.fillRect(point.x * w, point.y * h, w, h);
    });
  }

  drawLineOverlay(points, w, h) {
    if (!points) {
      return;
    }
    const ctx = this.toolCanvas.current.getContext('2d');
    const { active, activeColor } = this.props;
    ctx.fillStyle = active === TOOL_TYPES.SQUARE || active === TOOL_TYPES.LINE ? activeColor : 'rgba(0,0,0,0.5)';
    ctx.clearRect(0, 0, layout.canvasDrawSize, layout.canvasDrawSize);

    points.forEach((point) => {
      ctx.fillRect(point.x * w, point.y * h, w, h);
    });
  }

  saveShape(points, w, h) {
    if (!points) {
      return;
    }

    const layersCopy = JSON.stringify(this.props.data.layers);
    this.props.saveCurrentPixelData(JSON.parse(layersCopy));

    const ctx = this.toolCanvas.current.getContext('2d');
    const { activeColor } = this.props;
    ctx.fillStyle = activeColor;
    ctx.clearRect(0, 0, layout.canvasDrawSize, layout.canvasDrawSize);
    points.forEach((point) => {
      ctx.fillRect(point.x * w, point.y * h, w, h);
    });

    const { data } = this.props;
    const pixels = data.layers[data.currentLayer].pixelData[data.currentFrame] || [];
    points.forEach((point) => {
      point.color = activeColor;
      pixels.push(point);
    });
    const rawPixelData = normalizePixels(pixels);
    const reducedSet = [];
    reducePixels(rawPixelData, reducedSet);
    const newSet = reducedSet.sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y);

    this.props.setPixels(newSet);
    this.props.generateSpriteData().then(() => {
      ctx.clearRect(0, 0, layout.canvasDrawSize, layout.canvasDrawSize);
    });
  }

  onRelease() {
    const { active } = this.props;
    if (this.state.isDragging) {
      this.applyCopy();
      this.selectedArea = null;
    } else {
      if (active === TOOL_TYPES.SQUARE && this.squareShape) {
        this.saveShape(this.squareShape.shape, this.squareShape.w, this.squareShape.h);
        this.selectedArea = null;
        this.lastPixel = null;
        this.lastMirroredPixelX = null;
        this.pressXY = null;
        this.squareShape = null;
        this.lineShape = null;
      } else if (active === TOOL_TYPES.LINE && this.lineShape) {
        this.saveShape(this.lineShape.shape, this.lineShape.w, this.lineShape.h);
        this.selectedArea = null;
        this.lastPixel = null;
        this.lastMirroredPixelX = null;
        this.pressXY = null;
        this.squareShape = null;
        this.lineShape = null;
      }
    }
    this.setState({ isDrawing: false, isDragging: false });
  }

  render() {
    const { active } = this.props;
    const { width } = this.props.screen;
    return (
      <View
        className={styles.container}
        style={{
          backgroundColor: 'rgba(255, 0, 0, 0)',
          left: (window.innerWidth - width) / 2,
          top: (window.innerHeight - 25 - width) / 2,
          width: width,
          height: width,
          zIndex:
            active === TOOL_TYPES.MOVE ||
            active === TOOL_TYPES.MOVE_CLONE ||
            active === TOOL_TYPES.SQUARE ||
            active === TOOL_TYPES.LINE
              ? 100
              : -100,
        }}
      >
        <canvas
          width={width}
          height={width}
          ref={this.toolCanvas}
          style={{ transform: `scale(${1.0 - this.props.scale})`, marginLeft: 0, marginTop: 0 }}
        />
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  active: state.tools.activeTool,
  gridSize: state.settings.gridSize,
  activeColor: state.tools.activeColor,
  settings: state.settings,
  data: state.data,
  scale: state.settings.scaleOffset,
});

export const mapDispatch = (dispatch) => ({
  setCanvasRef: (ref) => dispatch(setToolCanvasRef(ref)),
  setPixels: (data) => dispatch(setPixels(data)),
  generateSpriteData: () => dispatch(generateSpriteData()),
  selectDrawTool: () => dispatch(selectDraw()),
  selectSquareTool: () => dispatch(selectSquare()),
  saveCurrentPixelData: (layers) => dispatch(saveCurrentPixelData(layers)),
});

export default connect(mapState, mapDispatch)(ToolCanvas);
