import React from 'react';
import { connect } from 'react-redux';
import {
  setQuickNavFrame,
  showFrameDialog,
  addFrameConfirm,
  cloneFrame,
  clearFrame,
} from '../../store/actions/uiActions';
import { selectDraw, undo, selectEraser } from '../../store/actions/toolsActions';
import KeyboardEventHandler from 'react-keyboard-event-handler';

/* eslint-disable */
function KeyPressHandler(props) {
  const {
    currentFrame,
    showFramePrompt,
    totalFrames,
    showFrameDialog,
    addFrame,
    setFrame,
    showSave,
    cloneFrame,
    selectDraw,
    clearFrame,
    undo,
    selectEraser
  } = props;

  return (
    <KeyboardEventHandler
      handleFocusableElements={true}
      handleKeys={['w', 'q', 'c', 'x', 'b', 'z', 'e']}
      onKeyEvent={(key, e) => {
        if (showSave) {
          return;
        }
        if (key === 'w') {
          if (currentFrame >= totalFrames - 1) {
            if (showFramePrompt) {
              showFrameDialog();
            } else {
              addFrame();
            }
          } else {
            setFrame(currentFrame + 1);
          }
        } else if (key === 'q') {
          if (currentFrame > 0) {
            setFrame(currentFrame - 1);
          }
        } else if (key === 'c') {
          cloneFrame(currentFrame);
        } else if (key === 'x') {
          clearFrame(currentFrame);
        } else if (key === 'b') {
          selectDraw();
        } else if (key === 'z') {
          undo();
        } else if (key === 'e') {
          selectEraser();
        }
      }}
    ></KeyboardEventHandler>
  );
}

const mapState = (state) => ({
  currentFrame: state.data.currentFrame,
  totalFrames: state.data.layers.length > 0 ? state.data.layers[state.data.currentLayer].pixelData.length : 1,
  showFramePrompt: state.settings.showAddFramePrompt,
  showSave: state.user.signInAndSave,
  currentFrame: state.data.currentFrame
});

const mapDispatch = (dispatch) => ({
  setFrame: (frame) => dispatch(setQuickNavFrame(frame)),
  showFrameDialog: () => dispatch(showFrameDialog()),
  addFrame: () => dispatch(addFrameConfirm()),
  cloneFrame: (index) => dispatch(cloneFrame(index)),
  selectDraw: () => dispatch(selectDraw()),
  clearFrame: (index) => dispatch(clearFrame(index)),
  undo: () => dispatch(undo()),
  selectEraser: () => dispatch(selectEraser())
});

export default connect(mapState, mapDispatch)(KeyPressHandler);
