import React from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/AreaControl.module.scss';
import { activateXA, activateXB, activateYA, activateYB, clearArea, activateDefineArea, closeDefineArea } from '../../store/actions/canvasActions';

class AreaControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  getMainBtnText() {
    return this.state.active ? 'Close define area' : 'Define area';
  }
  render() {
    const { width, orgW, orgH } = this.props.screen;
    const { areaDefineActive, areaDefineActiveType, xA, xB, yA, yB, gridSize } = this.props; // eslint-disable-line
    let xAO = 0.3;
    let xBO = 0.3;
    let yAO = 0.3;
    let yBO = 0.3;
    if (areaDefineActive) {
      // console.log('areaDefineActiveType', areaDefineActiveType);
      if (areaDefineActiveType === 'XA') {
        xAO = 1;
      }
      if (areaDefineActiveType === 'XB') {
        xBO = 1;
      }
      if (areaDefineActiveType === 'YA') {
        yAO = 1;
      }
      if (areaDefineActiveType === 'YB') {
        yBO = 1;
      }
    }

    return (
      <View
        id="areaControl"
        style={{
          position: 'absolute',
          zIndex: 10,
          left: orgW / 2 - width / 2,
          top: orgH / 2 - width / 2 - 35,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {this.state.active && (
          <>
        <View style={{position: 'absolute', width: 20, height: width, background: '#201d25', top: 22, left: -20 }}></View>
        <View style={{position: 'absolute', width: width, height: 20, background: '#201d25', top: 2, left: 0 }}></View>
        </>
        )}
        <Button
          style={{
            background: this.state.active ? '#222' : '#333333',
            padding: 5,
            color: 'lightgray',
            top: this.state.active ? -20 : 0,
          }}
          onClick={() => {
            if(!this.state.active) {
              this.props.activateDefineArea()
            } else {
              this.props.closeDefineArea()
            }
            this.setState({ active: !this.state.active });
            
          }}
        >
          {this.getMainBtnText()}
        </Button>
        {(xA || xB || yA || yB) && !this.state.active && (
          <Button
            style={{
              background: this.state.active ? '#222' : '#333333',
              padding: 5,
              color: 'lightgray',
              top: this.state.active ? -24 : 0,
              marginLeft: 2,
            }}
            onClick={() => {
              this.setState({ active: false });
              this.props.clearArea();
            }}
          >
            Clear area
          </Button>
        )}

        {this.state.active && (
          <View>
            <Button
              className={styles.anchorBtn}
              style={{
                left: xA ? xA * (width / gridSize) : 0,
                top: 2,
                opacity: areaDefineActive ? xAO : 1,
                ...(xA ? { background: '#39314c' } : {}),
              }}
              onClick={() => {
                this.props.activateXA();
              }}
            >
              A
            </Button>
            <Button
              className={styles.anchorBtn}
              style={{
                position: 'absolute',
                left: xB ? xB * (width / gridSize) : xA ? (xA + 2) * (width / gridSize) : 21,
                top: 2,
                opacity: areaDefineActive ? xBO : 1,
                ...(xB ? { background: '#39314c' } : {}),
              }}
              onClick={() => {
                this.props.activateXB();
              }}
            >
              B
            </Button>
            <Button
              className={styles.anchorBtn}
              style={{
                position: 'absolute',
                left: -20,
                top: yA ? yA * (width / gridSize) + 20 : 22,
                opacity: areaDefineActive ? yAO : 1,
                ...(yA ? { background: '#39314c' } : {}),
              }}
              onClick={() => {
                this.props.activateYA();
              }}
            >
              C
            </Button>
            <Button
              className={styles.anchorBtn}
              style={{
                position: 'absolute',
                left: -20,
                top: yB ? yB * (width / gridSize) + 20 : yA ? (yA + 2) * (width / gridSize) + 20: 43,
                opacity: areaDefineActive ? yBO : 1,
                ...(yB ? { background: '#39314c' } : {}),
              }}
              onClick={() => {
                this.props.activateYB();
              }}
            >
              D
            </Button>
          </View>
        )}
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  show: state.tools.activeTool,
  areaDefineActive: state.settings.areaDefineActive,
  areaDefineActiveType: state.settings.areaDefineActiveType,
  xA: state.settings.xA,
  xB: state.settings.xB,
  yA: state.settings.yA,
  yB: state.settings.yB,
  gridSize: state.settings.gridSize,
  canvasRef: state.settings.areaCanvasRef
});

const mapDispatch = (dispatch) => ({
  activateXA: () => dispatch(activateXA()),
  activateXB: () => dispatch(activateXB()),
  activateYA: () => dispatch(activateYA()),
  activateYB: () => dispatch(activateYB()),
  clearArea: () => dispatch(clearArea()),
  activateDefineArea: () => dispatch(activateDefineArea()),
  closeDefineArea: () => dispatch(closeDefineArea())
});

export default connect(mapState, mapDispatch)(AreaControl);
