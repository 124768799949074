import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View } from '../BaseComponents';
import styles from '../styles/Canvases.module.scss';
import BackgroundCanvas from './BackgroundCanvas';
import DrawCanvas from './DrawCanvas';
import RawCanvas from './RawCanvas';
import RawFrameCanvas from './RawFrameCanvas';
import SpriteSheetCanvas from './SpriteSheetCanvas';
import ToolCanvas from './ToolCanvas';
import AreaCanvas from './AreaCanvas';
// import CanvasInfo from './CanvasInfo';
import { shouldBlur } from '../../store/selectors';
import { resize, modifyScaleOffset } from '../../store/actions/canvasActions';
import { showDimensionView, hideDimensionView } from '../../store/actions/uiActions';

class Canvases extends Component {
  constructor(props) {
    super(props);

    this.canvases = React.createRef();

    this.debounceTimeout = false;
    this.debounceDelay = 250;
    this.iphone = window.navigator.userAgent.match('iPhone') || window.navigator.userAgent.match('iPod') ? true : false;
    this.ipad = window.navigator.userAgent.match('iPad') ? true : false;
    this.backgroundColorCanvas = React.createRef();
    this.updateDimensions = this.updateDimensions.bind(this);
    this.getDimensions = this.getDimensions.bind(this);
  }

  componentDidMount() {
    this.getDimensions();

    window.addEventListener('resize', this.updateDimensions);

    window.addEventListener('orientationchange', () => {
      setTimeout(() => {
        this.updateDimensions();
      }, 2000);
    });

    if (this.iphone || this.ipad) {
      window.addEventListener(
        'touchmove',
        (e) => {
          e.preventDefault();
        },
        { passive: false }
      );
    } else {
      window.addEventListener(
        'touchmove',
        function (event) {
          event.preventDefault();
        },
        { passive: false }
      );
    }
  }

  updateDimensions() {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(this.getDimensions, this.debounceDelay);
  }

  getDimensions() {
    this.props.updateCanvases();
  }

  renderCanvases() {
    const { settings, blur } = this.props;
    return (
      <View className={styles.container} style={{ filter: blur ? 'blur(5px)' : 'blur(0px)' }}>
        <BackgroundCanvas />
        <RawCanvas />
        <RawFrameCanvas />
        <SpriteSheetCanvas />
        <ToolCanvas />
        {settings.rawCanvasRef && settings.areaCanvasRef && settings.spritesheetCanvasRef && settings.rawFrameRef && (
          <DrawCanvas />
        )}
        <AreaCanvas />
      </View>
    );
  }

  render() {
    return this.renderCanvases();
  }
}

const mapState = (state) => ({
  hover: state.settings.canvasHover,
  expanded: state.settings.expanded,
  blur: shouldBlur(state),
  settings: state.settings,
  screen: state.settings.screen,
  zoom: state.settings.zoom,
  gridSize: state.settings.gridSize,
});

export const mapDispatch = (dispatch) => ({
  updateCanvases: () => dispatch(resize()),
  updateScaleOffset: (offset) => dispatch(modifyScaleOffset(offset)),
  showDimensionView: () => dispatch(showDimensionView()),
  hideDimensionView: () => dispatch(hideDimensionView()),
});

export default connect(mapState, mapDispatch)(Canvases);
