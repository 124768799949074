import React from 'react';
import { connect } from 'react-redux';
import { View, ScrollView } from '../BaseComponents';
import styles from '../styles/ColorSelector.module.scss';
import palettes from '../../config/palettes';
import { selectColor, hideColors } from '../../store/actions/toolsActions';
/* eslint-disable */
class ColorSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      pos: null,
      color: null
    };

    this.pos = 0;
    this.color = 'transparent'
    this.hoverEnd = this.hoverEnd.bind(this);
  }
  hoverStart(e, index, a) {
    this.setState({ hover: true, pos: e.pageY -5 , color: a });
  }
  hoverEnd() {
    this.setState({ hover: false });
  }
  render() {
    const { screen, show } = this.props;
    const h = screen.orgH - 180;
    return (
      <>
      <ScrollView className={styles.container} style={{ height: h, opacity: show ? 1 : 0, pointerEvents: show ? 'all' : 'none', left: show ? 250 : 200 }} onMouseLeave={this.hoverEnd}>
        {palettes.all.map((color, index) => (
          <View key={index} onMouseEnter={(e) => this.hoverStart(e, index, color)} onClick={() => this.props.select(color)}>
            <View style={{ height: 10, width: '100%', background: color, cursor: 'pointer' }}></View>
          </View>
        ))}
        
      </ScrollView>
      {this.state.hover && (
          <View
            className={styles.magnifier}
            style={{
              top: this.state.pos,
              background: this.state.color,
            }}
          ></View>
        )}
      </>
    );
  }
}

const mapState = (state) => {
  return {
    screen: state.settings.screen,
    activeColor: state.tools.activeColor,
    show: state.tools.showColors,
    currentPalette: state.settings.palette,
  };
};

const mapDispatch = (dispatch) => ({
  hide: () => dispatch(hideColors()),
  select: (color) => dispatch(selectColor(color)),
});

export default connect(mapState, mapDispatch)(ColorSelector);
