import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import * as reducers from './reducers';

const __DEV__ = process.env.NODE_ENV === 'development';

export default () => {
  const middlewares = [thunk];
  if (__DEV__) {
    middlewares.push(logger);
  }

  const rootReducer = combineReducers({ ...reducers });
  const middleware = applyMiddleware(...middlewares);
  const store = createStore(rootReducer, middleware);

  if (module.hot) {
    module.hot.accept('./reducers/index', () => {
      const reducers = require('./reducers/index');
      const rootReducer = combineReducers({ ...reducers });
      store.replaceReducer(rootReducer);
    });
  }

  return { store };
};
