import React from 'react';
import { connect } from 'react-redux';
import { View, ScrollView } from '../BaseComponents';
import styles from '../styles/ColorBackgroundSelector.module.scss';
import palettes from '../../config/palettes';
import { selectBackgroundColor, hideColors } from '../../store/actions/toolsActions';
class ColorBackgroundSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      pos: null,
      color: null,
    };

    this.pos = 0;
    this.color = 'transparent';
    this.hoverEnd = this.hoverEnd.bind(this);
  }
  hoverStart(e, index, a) {
    this.setState({ hover: true, pos: e.pageY - 5, color: a });
  }
  hoverEnd() {
    this.setState({ hover: false });
  }
  render() {
    const { showBackgroundColorSelector } = this.props;
    const { width } = this.props.screen;
    const show = showBackgroundColorSelector;
    return (
      <>
        <ScrollView
          className={styles.container}
          style={{
            height: width - 20,
            opacity: show ? 1 : 0,
            pointerEvents: show ? 'all' : 'none',
            left: show ? (window.innerWidth + width) / 2 : (window.innerWidth + width) / 2 - 40,
            top: (window.innerHeight - 25 - width) / 2,
          }}
          onMouseLeave={this.hoverEnd}
        >
          {palettes.all.map((color, index) => (
            <View
              key={index}
              onMouseEnter={(e) => this.hoverStart(e, index, color)}
              onClick={() => this.props.select(color)}
            >
              <View style={{ height: 10, width: '100%', background: color, cursor: 'pointer' }}></View>
            </View>
          ))}
        </ScrollView>
        {this.state.hover && (
          <View
            className={styles.magnifier}
            style={{
              top: this.state.pos,
              left: window.innerWidth / 2 + width / 2 - 10,
              background: this.state.color,
            }}
          ></View>
        )}
      </>
    );
  }
}

const mapState = (state) => {
  return {
    screen: state.settings.screen,
    activeColor: state.tools.activeColor,
    show: true,
    currentPalette: state.settings.palette,
    showBackgroundColorSelector: state.settings.showBackgroundColorSelector,
  };
};

const mapDispatch = (dispatch) => ({
  hide: () => dispatch(hideColors()),
  select: (color) => dispatch(selectBackgroundColor(color)),
});

export default connect(mapState, mapDispatch)(ColorBackgroundSelector);
