import React from 'react';
import styles from './styles/BaseComponents.module.scss';
import ReactGA from 'react-ga';

const __DEV__ = process.env.NODE_ENV === 'development';

export const Button = (props) => {
  const defaultCursor = { cursor: props.disabled ? 'not-allowed' : 'pointer' };

  const buttonStyles = { ...props.style, ...defaultCursor } || defaultCursor;
  return (
    <button
      {...props}
      style={buttonStyles}
      alt="spritelove link btn"
      onClick={(e) => {
        if (props.track && !__DEV__) {
          const { category, action } = props.track;
          ReactGA.event({ category: category, action: action });
        }
        // call btn click
        props.onClick(e);
      }}
    >
      {props.children}

      {!props.disabled && props.description && (
        <span style={{ ...(props.position && props.position) }}>{props.description}</span>
      )}
    </button>
  );
};

export const View = (props) => <div {...props}>{props.children}</div>;

export const TooltipView = (props) => {
  return (
    <div className={styles.tooltipView}>
      {props.children}
      {!props.disabled && props.description && (
        <span style={{ ...(props.position && props.position) }}>{props.description}</span>
      )}
    </div>
  );
};

export const ScrollView = (props) => {
  const scrollOverflow = props.horizontal ? 'overflowX' : 'overflowY';
  const scrollStyles = {
    ...props.style,
    [scrollOverflow]: 'scroll',
    ...(props.horizontal && { whiteSpace: 'noWrap' }),
  };
  return (
    <div {...props} style={scrollStyles}>
      {props.children}
    </div>
  );
};

export const Text = (props) => <div {...props}>{props.children}</div>;

export const Image = (props) => {
  const imageAlt = props.alt || 'image';
  return (
    <img {...props} alt={imageAlt}>
      {props.children}
    </img>
  );
};

export const TextInput = (props) => <input {...props}>{props.children}</input>;
