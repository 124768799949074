import { createSelector } from 'reselect';

const showFrames = (state) => state.settings.showFrames;
const dockFrames = (state) => state.settings.dockFrames;
const frames = (state) => !showFrames(state) && dockFrames(state);

const showSettings = (state) => state.settings.showSettings;
const showExpandedAnimation = (state) => state.settings.expanded;
const showAddFrameDialog = (state) => state.settings.showAddFrameDialog;
const showDimensionView = (state) => state.settings.showDimensionView;

export const shouldBlurForAnimationView = createSelector(
  [frames, showSettings, showAddFrameDialog, showDimensionView],
  (r1, r2, r3, r4, r5) => {
    return r1 || r2 || r3 || r4 || r5 === true;
  }
);

export const shouldBlur = createSelector(
  [frames, showSettings, showAddFrameDialog, showExpandedAnimation, showDimensionView],
  (r1, r2, r3, r4, r5, r6) => {
    return r1 || r2 || r3 || r4 || r5 || r6 === true;
  }
);
