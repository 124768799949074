/* eslint-disable camelcase */
import React, { Component } from 'react';
import { View } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Animation.module.scss';
import { THEME_TYPES } from '../../store/actions/types';
import { updateAnimationFrame } from '../../store/actions/animationActions';

class Animation extends Component {
  constructor(props) {
    super(props);
    this.animationRef = React.createRef();
    this.combined = null;
    this.animation = null;
    this.frameCount = 0;
    this.fpsInterval = null;
    this.startTime = null;
    this.now = null;
    this.then = null;
    this.elapsed = null;
    this.frame = 0;
    this.totalFrames = 0;
    this.transparent =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    this.semi =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkcAcAAE0ASdebhmAAAAAASUVORK5CYII=';
    this.images = [this.transparent];
  }

  componentDidMount() {
    this.requestRender(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.layers[prevProps.data.currentLayer] &&
      prevProps.data.layers[prevProps.data.currentLayer].pixelData.length >= 1 &&
      this.props.data.layers[this.props.data.currentLayer].pixelData.length === 0
    ) {
      this.animationRef.current.src = this.transparent;
    } else if (
      this.props.data.combinedRaw !== prevProps.data.combinedRaw ||
      this.props.animate !== prevProps.animate ||
      this.props.fps !== prevProps.fps ||
      this.props.gridSize !== prevProps.gridSize
    ) {
      if (this.props.animate === false) {
        // render frame before stopping the animation
        this.requestRender(this.props);
        cancelAnimationFrame(this.animation);
        this.animation = null;

        if (this.animationRef) {
          this.frame = this.props.settings.showLoad ? 0 : this.props.data.currentFrame;
          const stopFrame = this.images[this.frame];

          const layers = this.props.data.layers;
          const layerIndex = this.props.data.currentLayer;
          const currentLayer = layers[layerIndex];
          const emptyFrameData =
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

          if (currentLayer.rawData[this.props.data.currentFrame] === emptyFrameData) {
            // console.warn('empty layer data', layers);
            return;
          }
          this.updateView(stopFrame);
        }
      } else {
        //console.log('start animation')
        cancelAnimationFrame(this.animation);
        this.requestRender(this.props);
      }
    }
  }

  shouldComponentUpdate(newProps) {
    if (
      newProps.expanded !== this.props.expanded ||
      newProps.data.combinedRaw !== this.props.data.combinedRaw ||
      newProps.animate !== this.props.animate ||
      newProps.theme !== this.props.theme ||
      newProps.gridSize !== this.props.gridSize ||
      newProps.fps !== this.props.fps ||
      (this.props.data.layers[this.props.data.currentLayer] &&
        this.props.data.layers[this.props.data.currentLayer].pixelData.length >= 1 &&
        newProps.data.layers[newProps.data.currentLayer].pixelData.length === 0)
    ) {
      return true;
    }
    return false;
  }

  requestRender(props) {
    //console.log('ANIMATE - setup')
    this.combined = props.data.combinedRaw;
    // update fps from new props
    this.fpsInterval = 1000 / props.fps;
    if (props.data.layers[props.data.currentLayer]) {
      this.totalFrames = props.data.layers[props.data.currentLayer].rawData.length;
      this.buildFrames(props);
      this.setupAnimation(props);
    }
  }

  buildFrames(props) {
    this.images = [this.semi];
    for (let i = 0; i < this.totalFrames; i++) {
      if (props.data.combinedRaw[i] && props.data.combinedRaw[i].data) {
        this.images[i] = props.data.combinedRaw[i].data;
      } else {
        this.images[i] = this.transparent;
      }
    }
  }

  // initialize the timer variables and start the animation
  setupAnimation(props) {
    //console.log('ANIMATE - setupAnimation')
    this.fpsInterval = 1000 / props.fps;
    this.then = Date.now();
    this.startTime = this.then;

    if (props.animate) {
      this.animate(props);
    }
  }

  animate(props) {
    // request another frame
    this.animation = requestAnimationFrame(() => this.animate(props));
    // calc elapsed time since last loop
    this.now = Date.now();
    this.elapsed = this.now - this.then;
    // if enough time has elapsed, draw the next frame
    if (this.elapsed > this.fpsInterval) {
      // adjust for fpsInterval not being a multiple of RAF's interval (16.7ms)
      this.then = this.now - (this.elapsed % this.fpsInterval);
      // console.log("current frame", this.frame);
      this.frame = this.frame < this.totalFrames - 1 ? this.frame + 1 : 0;
      const currentFrame = this.images[this.frame];
      this.updateView(currentFrame);

      props.updateFrame(this.frame);
    }
  }

  updateView(frame) {
    document.getElementById('animation').src = frame;
  }

  renderAnimation() {
    const { expanded } = this.props;
    return (
      <View
        pointerEvents="none"
        style={{
          backgroundColor: this.props.theme === THEME_TYPES.LIGHT_THEME ? '#FFFFFF' : 'transparent',
        }}
      >
        <img
          id="animation"
          pointerEvents="none"
          ref={this.animationRef}
          src={this.transparent}
          className={styles.animContainer}
          alt="animation"
          style={{ height: expanded ? 600 : 150, marginTop: 30 }}
        />
      </View>
    );
  }

  render() {
    return (
      <View pointerEvents="none" className={styles.container}>
        {this.renderAnimation()}
      </View>
    );
  }
}

const mapState = (state) => ({
  animate: state.settings.animate,
  expanded: state.settings.expanded,
  fps: state.settings.fps,
  gridSize: state.settings.gridSize,
  data: state.data,
  settings: state.settings,
  theme: state.theme.current,
});

const mapDispatch = (dispatch) => ({
  updateFrame: (frame) => dispatch(updateAnimationFrame(frame)),
});

export default connect(mapState, mapDispatch)(Animation);
