/* eslint-disable camelcase */
export const pen = '/assets/pen.png';
export const L_pen = '/assets/pen.png'; // export const L_pen = "/assets/L_pen.png");
export const color = '/assets/color3.png';
export const L_color = '/assets/color3.png'; // export const L_color = "/assets/L_color.png");
export const undo = '/assets/undo.png';
export const eraser = '/assets/eraser.png';
export const picker = '/assets/picker.png';
export const mirror = '/assets/mirror.png';
export const fill = '/assets/fill.png';
export const clone = '/assets/clone.png';
export const del = '/assets/delete.png';
export const clear = '/assets/clear.png';
export const square = '/assets/square.png';
export const line = '/assets/line.png';
export const add = '/assets/add.png';
export const drag = '/assets/drag.png';
export const frames = '/assets/frames.png';
export const L_frames = '/assets/frames.png'; // export const L_frames = "/assets/L_frames.png");
export const layers = '/assets/layers.png';
export const L_layers = '/assets/layers.png'; // export const L_layers = "/assets/L_layers.png");
export const newlayer = '/assets/newlayer.png';
export const logo = '/assets/sl_logo.png';
export const zoomMoveLeft = '/assets/zoommoveleft.png';
export const zoomMoveRight = '/assets/zoommoveright.png';
export const zoomMoveUp = '/assets/zoommoveup.png';
export const zoomMoveDown = '/assets/zoommovedown.png';
export const zoomLess = '/assets/zoomless.png';
export const zoomMore = '/assets/zoommore.png';
export const zoomClose = '/assets/zoomclose.png';
export const zoom = '/assets/zoom.png';
export const settings = '/assets/settings.png';
export const settingsSel = '/assets/settings_sel.png';
export const play = '/assets/play.png';
export const pause = '/assets/pause.png';
export const scrollIndicator = '/assets/scroll.png';
export const expand = '/assets/expandanimation.png';
export const mirroreraser = '/assets/mirroreraser.png';

export const brush_sm = '/assets/brush_sm.png';
export const brush_l = '/assets/brush_l.png';
export const brush_ud = '/assets/brush_tilt_ud.png';
export const brush_du = '/assets/brush_tilt_du.png';

export const L_brush_sm = '/assets/brush_sm.png'; // export const L_brush_sm = "/assets/L_brush_sm.png");
export const L_brush_l = '/assets/brush_l.png'; // export const L_brush_l = "/assets/L_brush_l.png");
export const L_brush_ud = '/assets/brush_tilt_ud.png'; // export const L_brush_ud = "/assets/L_brush_tilt_ud.png");
export const L_brush_du = '/assets/brush_tilt_du.png'; // export const L_brush_du = "/assets/L_brush_tilt_du.png");

export const palette_color = '/assets/palette_color.png';
export const palette_color_selected = '/assets/palette_color_selected.png';
export const palette_bw = '/assets/palette_bw.png';
export const palette_bw_selected = '/assets/palette_bw_selected.png';
export const qn_next = '/assets/qn_nextFrame.png';
export const qn_prev = '/assets/qn_previousFrame.png';
export const quickAdd = '/assets/quickAdd.png';
export const saved = '/assets/saved.png';
export const unsaved = '/assets/unsaved.png';
export const noedits = '/assets/noedits.png';
export const option_settings = '/assets/option_settings.png';
export const option_profile = '/assets/option_profile.png';
export const option_details = '/assets/option_details.png';
export const back = '/assets/back.png';
export const locked = '/assets/locked.png';
export const unlocked = '/assets/unlocked.png';
export const select_move = '/assets/selectmove.png';
export const select_clone = '/assets/select_clone.png';
export const centerFrame = '/assets/dots.png';
export const duplicateFrameToAll = '/assets/clonelayers.png';
export const opacity = '/assets/opacity.jpg';
export const frametra = '/assets/tra2.png';
