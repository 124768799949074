import React from 'react';
import { View, Button, Image, Text } from '../BaseComponents';
import styles from '../styles/LockBar.module.scss';
import { locked, unlocked } from '../../config/assets';

class LockBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: true,
      selectedOptionIndex: this.props.selectedIndex,
    };
  }

  toggle = () => {
    const lockState = !this.state.isLocked;
    this.setState({
      isLocked: lockState,
    });

    if (lockState) {
      this.props.lock(this.state.selectedOptionIndex, this.props.options[this.state.selectedOptionIndex]);
    }
  };

  selectOption(index) {
    this.setState({ selectedOptionIndex: index });
  }

  renderLockBar() {
    const { isLocked } = this.state;
    return (
      <View className={styles.lockbar}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            flex: 1,
            backgroundColor: 'transparent',
          }}
        >
          <View
            style={{
              width: '75%',
              flexDirection: 'row',
              opacity: isLocked ? 0.15 : 1,
              display: 'flex',
            }}
          >
            {this.props.options.map((option, i) => (
              <Button
                key={'button_option_' + i}
                className={styles.btn}
                style={{
                  backgroundColor: this.state.selectedOptionIndex === i ? 'white' : '#181818',
                  alignItems: 'center',
                }}
                disabled={isLocked}
                description={i === 0 ? 'set to public' : 'set to private'}
                position={{ marginLeft: -12, marginTop: 20, position: 'relative' }}
                onClick={() => this.selectOption(i)}
              >
                <Text
                  className={styles.btnText}
                  style={{
                    color: 'gray',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                  }}
                >
                  {option}
                </Text>
              </Button>
            ))}
          </View>
          <Button
            className={styles.btn}
            style={{
              backgroundColor: isLocked ? '#151515' : '#222222',
            }}
            description={isLocked ? 'unlock' : 'lock'}
            position={{ marginLeft: isLocked ? 0 : 0, marginTop: 20, position: 'relative' }}
            onClick={this.toggle}
          >
            <Image src={isLocked ? locked : unlocked} className={styles.icon} />
          </Button>
        </View>
      </View>
    );
  }

  render() {
    return this.renderLockBar();
  }
}

export default LockBar;
