/* eslint-disable react/prop-types */
import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { init } from './store/actions';
import { setSIP, loadUserSimpleData } from './store/actions/userActions';

import TopSection from './components/misc/TopSection';
import styles from './components/styles/Base.module.scss';
import Canvases from './components/canvases/Canvases';
import Tools from './components/tools/Tools';
import ColorSelector from './components/tools/ColorSelector';
import ColorBackgroundSelector from './components/tools/ColorBackgroundSelector';
import BackgroundOpacitySelector from './components/tools/BackgroundOpacitySelector';
import SettingsView from './components/settings/SettingsView';
import FramesView from './components/frames/FramesView';
import LoadView from './components/frames/LoadView';
import AnimationView from './components/animation/AnimationView';
import AnimationOverlay from './components/animation/AnimationOverlay';
import DrawOptions from './components/tools/DrawOptions';
import MirrorOverlay from './components/tools/MirrorOverlay';
import AreaControl from './components/tools/AreaControl';
import ActiveColors from './components/misc/ActiveColors';
import FrameQuickNav from './components/frames/FrameQuickNav';
import FrameSlider from './components/frames/FrameSlider';
import AddFrameView from './components/frames/AddFrameView';
import StatusBottomBar from './components/misc/StatusBottomBar';
import LoginAndSaveView from './components/settings/LoginAndSaveView';
import ScreenDimension from './components/misc/ScreenDimension';
import DebugView from './components/misc/DebugView';
import { id } from './analytics.credentials';
import KeyPressHandler from './components/misc/KeyPressHandler';
import BackgroundSelector from './components/misc/BackgroundSelector';
import GridToggler from './components/misc/GridToggler';
import SignInProvider from './utils/SignInProvider';

import { debugMode } from './utils/mocks';

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.props.init();

    this.signInProvider = new SignInProvider();
    this.props.setSipReference(this.signInProvider);
    SignInProvider.setup();
  }

  componentDidMount() {
    const __DEV__ = process.env.NODE_ENV === 'development';
    if (!__DEV__) {
      ReactGA.initialize(id);
      ReactGA.pageview('/main');
    }

    if (!debugMode) {
      this.attemptSilentSignin();
    } else {
      console.warn('silent login disabled during debug mode'); // eslint-disable-line
    }
  }

  async attemptSilentSignin() {
    const token = await this.signInProvider.getToken();
    if (token === 'undefined') {
      // console.log('NO TOKEN');
      // this.setState({ showLoginPrompt: true })
    } else {
      // console.log('THERE IS A TOKEN');
      this.signInProvider
        .getFirebaseRefreshToken(token)
        .then(async (responseData) => {
          const obj = await responseData.json();

          // console.log('responseData obj', obj); // eslint-disable-line
          this.props.loadUserSimpleData(obj.user_id, obj.refresh_token);
        })
        .catch(() => {
          // console.log(`fetch error${err}`); // eslint-disable-line
        });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <ScreenDimension />
        <FramesView />
        <LoadView />
        <TopSection />
        <DrawOptions />
        <ColorBackgroundSelector />
        <BackgroundOpacitySelector />
        <GridToggler />
        <Canvases />
        {/* <ColorControl /> */}
        <ColorSelector />
        <SettingsView />
        <Tools />
        <AnimationOverlay />
        <AnimationView />
        <MirrorOverlay />
        <AreaControl />
        <ActiveColors />
        <StatusBottomBar />
        <FrameSlider />
        <FrameQuickNav />
        <BackgroundSelector />
        <AddFrameView />
        <LoginAndSaveView />
        <DebugView />
        <KeyPressHandler />
      </div>
    );
  }
}

const mapDispatch = (dispatch) => ({
  init: () => dispatch(init()),
  setSipReference: (provider) => dispatch(setSIP(provider)),
  loadUserSimpleData: (id, token) => dispatch(loadUserSimpleData(id, token)),
});

export default connect(null, mapDispatch)(Main);
