export const onionSkinLayers = (data, ctx, settings, camXOffset, camYOffset, w, h) => {
  const blur = 0;
  // Layer onion skinning (all layers except current)
  if (settings.onionLayers && data.layers.length > 0) {
    for (let i = 0; i < data.layers.length; i++) {
      const onionLayer = data.layers[i];
      const layerOpacity = onionLayer.opacity || 100;
      ctx.globalAlpha = (layerOpacity * settings.onionSkinAlpha) / 100;

      if (onionLayer && onionLayer.pixelData[data.currentFrame] && i !== data.currentLayer) {
        onionLayer.pixelData[data.currentFrame].forEach((pixel) => {
          // shape
          if (pixel.shape) {
            ctx.fillStyle = pixel.shape.e; // `#${((Math.random() * 0xffffff) << 0).toString(16)}`
            const posXs = Math.ceil(pixel.shape.a - camXOffset);
            const posXe = Math.ceil(pixel.shape.b + 1 - camXOffset);
            const posYs = Math.ceil(pixel.shape.c - camYOffset);
            const posYe = Math.ceil(pixel.shape.d + 1 - camYOffset);

            ctx.fillRect(
              Math.ceil(posXs * w),
              Math.ceil(posYs * w),
              Math.ceil((posXe - posXs) * w),
              Math.ceil((posYe - posYs) * w)
            );
          } else {
            // single pixel
            ctx.fillStyle = pixel.color;
            const posX = pixel.x - camXOffset;
            const posY = pixel.y - camYOffset;
            ctx.fillRect(
              Math.ceil(posX * w - blur),
              Math.ceil(posY * w - blur),
              Math.ceil(w + blur),
              Math.ceil(h + blur)
            );
          }
        });
      }
    }
  }
};

export const onionSkinPreFrame = (data, ctx, settings, camXOffset, camYOffset, w, h) => {
  // ctx.globalAlpha = settings.onionSkinAlpha;
  if (
    data.currentFrame > 0 &&
    data.layers[data.currentLayer] &&
    data.layers[data.currentLayer].pixelData[data.currentFrame - 1]
  ) {
    const onionLayer = data.layers[data.currentLayer];
    const layerOpacity = onionLayer.opacity || 100;
    ctx.globalAlpha = (layerOpacity * settings.onionSkinAlpha) / 100;

    data.layers[data.currentLayer].pixelData[data.currentFrame - 1].forEach((pixel) => {
      // shape
      if (pixel.shape) {
        ctx.fillStyle = pixel.shape.e; // `#${((Math.random() * 0xffffff) << 0).toString(16)}`
        const posXs = pixel.shape.a - camXOffset;
        const posXe = pixel.shape.b + 1 - camXOffset;
        const posYs = pixel.shape.c - camYOffset;
        const posYe = pixel.shape.d + 1 - camYOffset;

        ctx.fillRect(
          Math.ceil(posXs * w),
          Math.ceil(posYs * w),
          Math.ceil((posXe - posXs) * w),
          Math.ceil((posYe - posYs) * w)
        );
      } else {
        // single pixel
        const blur = 0;

        ctx.fillStyle = pixel.color;
        const posX = pixel.x - camXOffset;
        const posY = pixel.y - camYOffset;
        ctx.fillRect(Math.ceil(posX * w - blur), Math.ceil(posY * w - blur), Math.ceil(w + blur), Math.ceil(h + blur));
      }
    });
  }
};

export const onionSkinNextFrame = (data, ctx, settings, camXOffset, camYOffset, w, h) => {
  // ctx.globalAlpha = settings.onionSkinAlpha;
  if (
    data.currentFrame > 0 &&
    data.layers[data.currentLayer] &&
    data.layers[data.currentLayer].pixelData[data.currentFrame + 1]
  ) {
    const onionLayer = data.layers[data.currentLayer];
    const layerOpacity = onionLayer.opacity || 100;
    ctx.globalAlpha = (layerOpacity * settings.onionSkinAlpha) / 100;
    // console.info('ctx.globalAlpha', ctx.globalAlpha);

    data.layers[data.currentLayer].pixelData[data.currentFrame + 1].forEach((pixel) => {
      // shape
      if (pixel.shape) {
        ctx.fillStyle = pixel.shape.e; // `#${((Math.random() * 0xffffff) << 0).toString(16)}`
        const posXs = pixel.shape.a - camXOffset;
        const posXe = pixel.shape.b + 1 - camXOffset;
        const posYs = pixel.shape.c - camYOffset;
        const posYe = pixel.shape.d + 1 - camYOffset;

        ctx.fillRect(
          Math.ceil(posXs * w),
          Math.ceil(posYs * w),
          Math.ceil((posXe - posXs) * w),
          Math.ceil((posYe - posYs) * w)
        );
      } else {
        const blur = 0;
        // single pixel
        ctx.fillStyle = pixel.color;
        const posX = pixel.x - camXOffset;
        const posY = pixel.y - camYOffset;
        ctx.fillRect(Math.ceil(posX * w - blur), Math.ceil(posY * w - blur), Math.ceil(w + blur), Math.ceil(h + blur));
      }
    });
  }
};
