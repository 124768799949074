import React from 'react';
import { View, Button, Text } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/BackgroundSelector.module.scss';
import { shouldBlur } from '../../store/selectors';
import { showBackgroundSelector, hideBackgroundSelector } from '../../store/actions/toolsActions';

class BackgroundSelector extends React.Component {
  constructor(props) {
    super(props);
    this.btnSize = 25;
  }

  getDivs(backgroundColor) {
    return backgroundColor === 'transparent' ? (
      <div className={styles.divColors}>
        <div className={styles.divRed}></div>
        <div className={styles.divGreen}></div>
        <div className={styles.divBlue}></div>
      </div>
    ) : (
      <div className={styles.divColors}>
        <div className={styles.divRed} style={{ background: backgroundColor }}></div>
        <div className={styles.divGreen} style={{ background: backgroundColor }}></div>
        <div className={styles.divBlue} style={{ background: backgroundColor }}></div>
      </div>
    );
  }

  render() {
    const { blur, showBackgroundColorSelector, screen, backgroundColor } = this.props;
    const l = showBackgroundColorSelector
      ? screen.orgW / 2 + screen.width / 2 + 40
      : screen.orgW / 2 + screen.width / 2;

    return (
      <View
        className={styles.container}
        style={{
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          width: this.props.screen.width + 100,
          left: l,
          top: (this.props.screen.orgH - 25) / 2 - this.props.screen.width / 2,
          filter: blur ? 'blur(5px)' : 'blur(0px)',
          zIndex: 2000000,
        }}
      >
        <Button
          className={styles.quickNavBtn}
          style={{
            backgroundColor: '#222',
            pointerEvents: 'auto',
          }}
          description={'choose background color'}
          position={{ marginLeft: -25, marginTop: 25 }}
          track={{ category: 'Interface', action: 'Quick frame - next' }}
          onClick={() => {
            // console.log('click selector');
            this.props.showBackgroundSelector();
          }}
        >
          {showBackgroundColorSelector ? (
            <div
              className={styles.divColors}
              style={{ background: '#222222', color: 'red', alignItems: 'center' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                // console.log('click selector');
                this.props.hideBackgroundSelector();
              }}
            >
              <Text>x</Text>
            </div>
          ) : (
            this.getDivs(backgroundColor)
          )}
        </Button>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showColors: state.tools.showColors,
  colors: state.tools.userColors,
  blur: shouldBlur(state),
  data: state.data,
  currentFrame: state.data.currentFrame,
  totalFrames: state.data.layers.length > 0 ? state.data.layers[state.data.currentLayer].pixelData.length : 1,
  theme: state.theme,
  showFramePrompt: state.settings.showAddFramePrompt,
  showBackgroundColorSelector: state.settings.showBackgroundColorSelector,
  backgroundColor: state.settings.backgroundColor,
});

const mapDispatch = (dispatch) => ({
  showBackgroundSelector: () => dispatch(showBackgroundSelector()),
  hideBackgroundSelector: () => dispatch(hideBackgroundSelector()),
});

export default connect(mapState, mapDispatch)(BackgroundSelector);
