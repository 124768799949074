const config = {
  apiKey: 'AIzaSyDQ7sg_tcSgy0EUJSI9g3ai4J56mcpSovU',
  authDomain: 'app-spritelove.firebaseapp.com',
  databaseURL: 'https://app-spritelove.firebaseio.com',
  projectId: 'app-spritelove',
  storageBucket: 'app-spritelove.appspot.com',
  messagingSenderId: '885505188674',
  appId: '1:885505188674:web:5a25b89c741bdac8',
};

export default config;

// curl -X PUT -d "false"  https://app-spritelove.firebaseio.com/.settings/strictTriggerValidation/.json?auth\=ldrA3SBd03cdHofagkbzpD8DBqWY8wzfkGSDWC2d
