import React from 'react';
import { View, Button, Text } from '../BaseComponents';
import { connect } from 'react-redux';
import { addFrameConfirm, addFrameDialogReject } from '../../store/actions/uiActions';
import styles from '../styles/AddFrameDialog.module.scss';

import { disableShowFramePrompt } from '../../store/actions/uiActions';

class AddFrameDialog extends React.Component {
  render() {
    const { theme, screen } = this.props;
    const th = theme.themes[theme.current];
    const minSize = 200;
    const maxSize = 300;
    const size = Math.min(Math.max(parseInt(screen.orgW * 0.4), minSize), maxSize);
    return (
      <View
        className={styles.dialogBox}
        style={{
          width: size,
          backgroundColor: th.panelBackground,
          opacity: this.props.showAddFrameDialog ? 1 : 0,
          left: screen.orgW / 2 - size / 2,
          top: this.props.showAddFrameDialog ? screen.orgH / 2 - 50 : screen.orgH,
        }}
      >
        <View className={styles.boxHeader} style={{ backgroundColor: th.sectionTitleBackground }}>
          <Text
            style={{
              color: th.sectionTitleColor,
              fontSize: window.getComputedStyle(document.documentElement).getPropertyValue('--big-size'),
            }}
          >
            ADD A NEW FRAME?
          </Text>
          <View style={{ position: 'absolute', top: 40, right: -10 }} />
        </View>
        <View className={styles.boxContent}>
          <Button
            className={styles.boxBtn}
            style={{
              backgroundColor: theme.themes[theme.current].cancelBtnColor,
              borderWidth: 0,
              borderBottomWidth: 2,
              borderColor: 'transparent',
              borderBottomColor: 'transparent',
            }}
            onClick={() => this.props.reject()}
          >
            <Text
              style={{
                color: 'white',
                fontSize: window.getComputedStyle(document.documentElement).getPropertyValue('--big-size'),
              }}
            >
              No
            </Text>
          </Button>

          <Button
            className={styles.boxBtn}
            style={{
              backgroundColor: theme.themes[theme.current].buttonColor,
              borderWidth: 0,
              borderBottomWidth: 2,
              borderColor: 'transparent',
              borderBottomColor: 'transparent',
            }}
            onClick={() => this.props.confirm()}
          >
            <Text
              style={{
                color: 'white',
                fontSize: window.getComputedStyle(document.documentElement).getPropertyValue('--big-size'),
              }}
            >
              Yes
            </Text>
          </Button>
        </View>
        <View>
          <View
            style={{
              display: 'flex',
              margin: '0px 10px',
              background: 'transparent',
              borderTopColor: 'rgba(255,255,255,0.1)',
              borderWidth: 0,
              borderTopWidth: 1,
              borderStyle: 'solid',
              padding: 10,
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Text>Disable this prompt?</Text>
            <Button
              style={{ background: 'rgba(0,0,0,0.1)', padding: 8, marginTop: 10 }}
              onClick={() => {
                this.props.disableShowFramePrompt();
              }}
            >
              <Text>Yes please</Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showAddFrameDialog: state.settings.showAddFrameDialog,
  theme: state.theme,
});

const mapDispatch = (dispatch) => ({
  confirm: () => dispatch(addFrameConfirm()),
  reject: () => dispatch(addFrameDialogReject()),
  disableShowFramePrompt: () => dispatch(disableShowFramePrompt()),
});

export default connect(mapState, mapDispatch)(AddFrameDialog);
