import { normalizePixels } from './normalizePixels';
import layout from './../config/layout';

let closedSet = [];
let normalizedPixels = [];
let resultingPoints = [];
let baseColor = null;
let fillColor = null;
let targetFilledCells = null;
const gridSize = layout.gridSize;

export const fillPixels = (pixelsOnStage, pixelData) => {
  normalizedPixels = normalizePixels(pixelsOnStage) || [];
  closedSet = [];
  resultingPoints = [];
  fillColor = pixelData.color;

  if (normalizedPixels.length > 0) {
    const vector = { x: pixelData.x, y: pixelData.y };
    const clickedIndex = normalizedPixels.findIndex((np) => np.x === vector.x && np.y === vector.y);

    baseColor = clickedIndex > -1 ? normalizedPixels[clickedIndex].color : 'transparent';
    targetFilledCells = baseColor !== 'transparent';

    const activePixel = vector;
    try {
      baseColor !== fillColor && fill(activePixel);
      return resultingPoints;
    } catch (error) {
      return [];
    }
  } else {
    const fullBackgroundArr = [];
    for (let i = 0; i < gridSize; i++) {
      for (let z = 0; z < gridSize; z++) {
        const fillObj = { x: z, y: i, color: fillColor };
        fullBackgroundArr.push(fillObj);
      }
    }
    return fullBackgroundArr;
  }
};

const fill = (activePixel) => {
  // check if available
  const index = normalizedPixels.findIndex((np) => np.x === activePixel.x && np.y === activePixel.y);
  const pxColor = index > -1 ? normalizedPixels[index].color : 'transparent';
  const available = pxColor === baseColor;
  const px = { ...activePixel, color: fillColor };

  if (available && notIn(closedSet, activePixel)) {
    resultingPoints.push(px);
    closedSet.push(activePixel);

    // check neighbors
    possibleNeighbors(activePixel).forEach((neighbor) => {
      if (notIn(closedSet, neighbor)) {
        fill(neighbor);
        closedSet.push(neighbor);
      }
    });
  }
};

const possibleNeighbors = (activePixel) => {
  const arr = [];
  const leftPos = { x: activePixel.x - 1, y: activePixel.y };
  const upPos = { x: activePixel.x, y: activePixel.y - 1 };
  const rightPos = { x: activePixel.x + 1, y: activePixel.y };
  const downPos = { x: activePixel.x, y: activePixel.y + 1 };

  if (targetFilledCells) {
    const leftIndex = normalizedPixels.findIndex(
      (np) => np.x === leftPos.x && np.y === leftPos.y && np.color === baseColor
    );
    const upIndex = normalizedPixels.findIndex((np) => np.x === upPos.x && np.y === upPos.y && np.color === baseColor);
    const rightIndex = normalizedPixels.findIndex(
      (np) => np.x === rightPos.x && np.y === rightPos.y && np.color === baseColor
    );
    const downIndex = normalizedPixels.findIndex(
      (np) => np.x === downPos.x && np.y === downPos.y && np.color === baseColor
    );

    inBounds(leftPos) && leftIndex && arr.push(leftPos);
    inBounds(upPos) && upIndex && arr.push(upPos);
    inBounds(rightPos) && rightIndex && arr.push(rightPos);
    inBounds(downPos) && downIndex && arr.push(downPos);
  } else {
    inBounds(leftPos) && arr.push(leftPos);
    inBounds(upPos) && arr.push(upPos);
    inBounds(rightPos) && arr.push(rightPos);
    inBounds(downPos) && arr.push(downPos);
  }

  return arr;
};

const inBounds = (pixel) => pixel.x < gridSize && pixel.x >= 0 && pixel.y < gridSize && pixel.y >= 0;
const notIn = (arr, p) => arr.findIndex((t) => t.x === p.x && t.y === p.y) === -1;
