import React from 'react'

// PROPS:
// expanded
// areaDefineActive
// xA
// xB
// yA
// yB

const renderXA = (w) => {
  return w ? (<div style={{position: 'absolute', width: w, height: 150, background: '#181818', }}></div>) : null
}

const renderXB = (w, x) => {
  return w && x ? (<div style={{position: 'absolute', left: x, width: w, height: 150, background: '#181818',}}></div>) : null
}

const renderYA = (h) => {
  return h ? (<div style={{position: 'absolute', width: 150, height: h, background: '#181818', }}></div>) : null
}

const renderYB = (h, y) => {
  return h && y ? (<div style={{position: 'absolute', top: y, width: 150, height: h, background: '#181818', }}></div>) : null
}

const AreaAnimationOverlay = (props) => {
  const { 
    expanded,
    xA,
    xB,
    yA,
    yB,
    gridSize,
    zoom
  } = props

  if(expanded || zoom > 1) {
    return null
  }

  const size = 150;
  const ratio = size / gridSize;
  const xAWidth = ratio * xA;
  const xBX = ratio * xB;
  const xBWidth = (gridSize - xB) * ratio 
  const yAHeight = ratio * yA;
  const yBHeight = (gridSize - yB) * ratio
  const yBY = ratio * yB;
  
  return (
    <div style={{position: 'absolute', background: 'transparent', width: '100%', height: 150, top: 30, zIndex: 50000, pointerEvents: 'none'}}>
      {renderXA(xAWidth)}
      {renderXB(xBWidth, xBX)}
      {renderYA(yAHeight)}
      {renderYB(yBHeight, yBY)}
    </div>
  )
}

export default AreaAnimationOverlay