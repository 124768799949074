import React, { Component } from 'react';
import { View } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/BackgroundCanvas.module.scss';
// import { THEME_TYPES } from '../../store/actions/types';
import { setBackgroundCanvasRef } from '../../store/actions/canvasActions';

class BackgroundCanvas extends Component {
  constructor(props) {
    super(props);
    this.backgroundColorCanvas = React.createRef();
  }

  componentDidMount() {
    this.props.setCanvasRef(this.backgroundColorCanvas.current);
  }

  render() {
    const { backgroundColor } = this.props;
    const { width } = this.props.screen;
    return (
      <View
        className={styles.container}
        style={{
          left: (window.innerWidth - width) / 2,
          top: (window.innerHeight - 25 - width) / 2,
          width: width,
          height: width,
          background: backgroundColor !== 'transparent' ? backgroundColor : '#393939',
        }}
      >
        <canvas
          width={width}
          height={width}
          ref={this.backgroundColorCanvas}
          style={{ transform: `scale(${1.0 - this.props.scale})`, marginLeft: 0, marginTop: 0 }}
        />
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  scale: state.settings.scaleOffset,
  theme: state.theme.current,
  backgroundColor: state.settings.backgroundColor,
});

export const mapDispatch = (dispatch) => ({
  setCanvasRef: (ref) => dispatch(setBackgroundCanvasRef(ref)),
});

export default connect(mapState, mapDispatch)(BackgroundCanvas);
