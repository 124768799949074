import React, { Component } from 'react';
import { View, Text } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/ScreenDimension.module.scss';

class ScreenDimension extends Component {
  render() {
    const { showDimensionView } = this.props;
    const { orgW, orgH } = this.props.screen;
    return (
      <View
        className={styles.container}
        style={{
          pointerEvents: showDimensionView ? 'auto' : 'none',
          opacity: showDimensionView ? 1 : 0,
          width: orgW,
          height: orgH,
        }}
      >
        <Text>Sorry...</Text>
        <Text style={{ maxWidth: 500 }}>Spritelove only support landscape mode equal or larger than 1300x690</Text>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showDimensionView: state.settings.showDimensionView,
});
export default connect(mapState, null)(ScreenDimension);
