import { COMMON_TYPES, TOOL_TYPES, UI_TYPES } from '../actions/types';

const initialState = {
  showColors: false,
  activeColor: '#2196F3',
  lastColor: '#2196F3',
  userColors: [],
  activeTool: TOOL_TYPES.PEN,
  lastTool: null,
  mirrorX: true,
  mirrorY: false,
};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_TYPES.APP_RESET: {
      return initialState;
    }
    case UI_TYPES.SHOW_COLORS: {
      return {
        ...state,
        showColors: true,
        lastTool: state.activeTool,
        activeTool: TOOL_TYPES.COLOR,
      };
    }
    case UI_TYPES.HIDE_COLORS: {
      return {
        ...state,
        showColors: false,
        activeTool: state.activeTool,
      };
    }
    case COMMON_TYPES.SELECT_COLOR: {
      const uc = state.userColors.slice(0);
      if (uc.findIndex((c) => c === action.payload) === -1) {
        uc.unshift(action.payload);
      }
      return {
        ...state,
        showColors: false,
        activeColor: action.payload,
        userColors: uc,
        activeTool: state.lastTool,
      };
    }
    case COMMON_TYPES.SET_COLOR: {
      return {
        ...state,
        showColors: false,
        activeColor: action.payload,
      };
    }
    case COMMON_TYPES.SELECT_DRAW: {
      return {
        ...state,
        activeColor: state.activeColor === 'transparent' ? state.lastColor : state.activeColor,
        activeTool: TOOL_TYPES.PEN,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_MIRROR: {
      return {
        ...state,
        activeColor: state.activeColor === 'transparent' ? state.lastColor : state.activeColor,
        activeTool: TOOL_TYPES.MIRROR,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_MIRROR_X: {
      return {
        ...state,
        mirrorX: !state.mirrorX,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_MIRROR_Y: {
      return {
        ...state,
        mirrorY: !state.mirrorY,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_COLORPICKER: {
      return {
        ...state,
        activeTool: TOOL_TYPES.COLORPICKER,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_ERASER: {
      return {
        ...state,
        lastColor: state.activeColor,
        activeColor: 'transparent',
        activeTool: TOOL_TYPES.ERASER,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_FILL: {
      return {
        ...state,
        activeTool: TOOL_TYPES.FILL,
        showColors: false,
      };
    }
    case UI_TYPES.SHOW_FRAMES_VIEW: {
      return {
        ...state,
        showColors: false,
      };
    }
    case UI_TYPES.SHOW_SETTINGS_VIEW: {
      return {
        ...state,
        showColors: false,
      };
    }
    case COMMON_TYPES.LOAD_COLORS: {
      return {
        ...state,
        userColors: action.payload,
      };
    }
    case COMMON_TYPES.SELECT_MOVE: {
      return {
        ...state,
        activeTool: TOOL_TYPES.MOVE,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_CLONE: {
      return {
        ...state,
        activeTool: TOOL_TYPES.MOVE_CLONE,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_SQUARE: {
      return {
        ...state,
        activeTool: TOOL_TYPES.SQUARE,
        showColors: false,
      };
    }
    case COMMON_TYPES.SELECT_LINE: {
      return {
        ...state,
        activeTool: TOOL_TYPES.LINE,
        showColors: false,
      };
    }
    default:
  }

  return state;
}
