import React, { Component } from 'react';
import { Image, View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/DrawOptions.module.scss';
import * as assets from '../../config/assets';
import { setBrush } from '../../store/actions/toolsActions';
import { BRUSH_TYPES } from '../../store/actions/types';
import { shouldBlur } from '../../store/selectors';
import { validBrushToolSelected } from '../../utils';

class DrawOptions extends Component {
  constructor(props) {
    super(props);
    this.optionSize = '100%';
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.blur !== this.props.blur ||
      nextProps.brush !== this.props.brush ||
      nextProps.screen !== this.props.screen ||
      nextProps.activeTool !== this.props.activeTool
    ) {
      return true;
    }
    return false;
  }

  renderBrushSmall() {
    return (
      <Button
        onClick={() => {
          this.props.setBrush(BRUSH_TYPES.BRUSH_SMALL);
        }}
        description="small"
        position={{ marginLeft: 5 }}
        className={styles.item}
      >
        <Image
          src={assets.brush_sm}
          style={{
            width: this.optionSize,
            height: this.optionSize,
            opacity: this.props.brush === BRUSH_TYPES.BRUSH_SMALL ? 1 : 0.2,
          }}
        />
      </Button>
    );
  }

  renderBrushBig() {
    return (
      <Button
        onClick={() => {
          this.props.setBrush(BRUSH_TYPES.BRUSH_LARGE);
        }}
        description="large"
        position={{ marginLeft: 5 }}
        className={styles.item}
      >
        <Image
          src={assets.brush_l}
          style={{
            width: this.optionSize,
            height: this.optionSize,
            opacity: this.props.brush === BRUSH_TYPES.BRUSH_LARGE ? 1 : 0.2,
          }}
        />
      </Button>
    );
  }

  renderBrushTiltUD() {
    return (
      <Button
        onClick={() => {
          this.props.setBrush(BRUSH_TYPES.BRUSH_TILT_UD);
        }}
        description="tilt v1"
        position={{ marginLeft: 5 }}
        className={styles.item}
      >
        <Image
          src={assets.brush_ud}
          style={{
            width: this.optionSize,
            height: this.optionSize,
            opacity: this.props.brush === BRUSH_TYPES.BRUSH_TILT_UD ? 1 : 0.2,
          }}
        />
      </Button>
    );
  }

  renderBrushTiltDU() {
    return (
      <Button
        onClick={() => {
          this.props.setBrush(BRUSH_TYPES.BRUSH_TILT_DU);
        }}
        description="tilt v2"
        position={{ marginLeft: 5 }}
        className={styles.item}
      >
        <Image
          src={assets.brush_du}
          style={{
            width: this.optionSize,
            height: this.optionSize,
            opacity: this.props.brush === BRUSH_TYPES.BRUSH_TILT_DU ? 1 : 0.2,
          }}
        />
      </Button>
    );
  }

  render() {
    const { blur, screen, docked, showColors } = this.props;
    // const yPos = (screen.orgH - 25) / 2 - screen.width / 2 > 100 ? (screen.orgH - 25) / 2 - screen.width / 2 : 100;
    // const xPos = screen.orgW / 2 - screen.width / 2 - 80;
    let yPos, xPos;
    if (!docked) {
      xPos = screen.orgW / 2 - screen.width / 2 - 80;
      yPos = (screen.orgH - 25) / 2 - screen.width / 2 > 100 ? (screen.orgH - 25) / 2 - screen.width / 2 : 100;
    } else {
      xPos = showColors ? 290 : 250;
      yPos = -10;
    }

    return (
      <View
        pointerEvents={validBrushToolSelected(this.props) ? 'auto' : 'none'}
        className={styles.container}
        style={{
          zIndex: 300000,
          filter: blur ? 'blur(5px)' : 'blur(0px)',
          top: yPos + 80,
          left: validBrushToolSelected(this.props) ? xPos : xPos,
          opacity: validBrushToolSelected(this.props) ? 1 : 0.7,
        }}
      >
        {/* bar */}
        <View className={styles.barMask}>
          <View
            className={styles.bar}
            style={{
              opacity: validBrushToolSelected(this.props) ? 1 : 0.2,
            }}
          >
            {this.renderBrushSmall()}
            {this.renderBrushBig()}
            {this.renderBrushTiltDU()}
            {this.renderBrushTiltUD()}
          </View>
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  blur: shouldBlur(state),
  screen: state.settings.screen,
  brush: state.settings.brush,
  activeTool: state.tools.activeTool,
  docked: state.settings.dockFrames,
  showColors: state.tools.showColors,
});

const mapDispatch = (dispatch) => ({
  setBrush: (size) => dispatch(setBrush(size)),
});

export default connect(mapState, mapDispatch)(DrawOptions);
