import React, { Component } from 'react';
import { View, Text } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Camera.module.scss';
import { setCameraPosition } from '../../store/actions/cameraActions';
import Draggable from 'react-draggable';

class Camera extends Component {
  constructor(props) {
    super(props);
    this.state = {
      x: 0,
      y: 0,
    };

    this.onDrag = this.onDrag.bind(this);
  }

  componentDidUpdate(prevProps) {
    const box = document.getElementById('dragBox');
    const { setCameraPosition } = this.props;

    if (prevProps.zoom === 1 && this.props.zoom === 2) {
      setCameraPosition(0, 0);
    } else if (prevProps.zoom === 2 && this.props.zoom === 3) {
      const { x, y } = this.state;
      const posX = this.getCameraPos(x);
      const posY = this.getCameraPos(y);
      setCameraPosition(posX, posY);
    } else if (prevProps.zoom === 3 && this.props.zoom === 2) {
      const { x, y } = this.state;
      const { setCameraPosition } = this.props;
      const posX = this.getCameraPos(x);
      const posY = this.getCameraPos(y);
      setCameraPosition(posX, posY);
    } else if (prevProps.zoom === 2 && this.props.zoom === 1) {
      setCameraPosition(0, 0);
      box.style.transform = 'none';
    }
  }

  getCameraPos(axis) {
    const { gridSize } = this.props;
    return Math.round((axis * ((gridSize * 100 * 0.66) / 100) * 100) / 10000);
  }

  onDrag(e, ui) {
    const { setCameraPosition } = this.props;
    const { x, y } = ui;
    this.setState({ x: x, y: y });
    const posX = this.getCameraPos(x);
    const posY = this.getCameraPos(y);
    setCameraPosition(posX, posY);
  }

  renderCamera() {
    const box = 150;
    const { zoom, expanded } = this.props;
    const sqSize = box / zoom;
    const factor = 10; // gridSize / 4;
    return (
      <View className={styles.animation}>
        <Draggable bounds="parent" onDrag={this.onDrag} grid={[box / factor, box / factor]}>
          <View
            id="dragBox"
            className={styles.zoomWindow}
            style={{
              opacity: expanded ? 0 : 1,
              pointerEvents: zoom === 1 || expanded ? 'none' : 'auto',
              zIndex: zoom === 1 ? 3000 : 3000,
              width: zoom === 1 ? box - 2 : sqSize,
              height: zoom === 1 ? box - 2 : sqSize,
              backgroundColor: zoom === 1 ? 'transparent' : 'rgba(0, 0, 0, 0.15)',
              borderWidth: zoom === 1 ? 1 : 1,
              borderStyle: 'solid',
              borderColor: zoom === 1 ? '#252525' : '#696969',
              cursor: 'grab',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text style={{ opacity: zoom > 1 ? 1 : 0 }}>drag</Text>
          </View>
        </Draggable>
      </View>
    );
  }

  render() {
    return (
      <View className={styles.container} style={{ width: 150, height: 150, top: 30 }}>
        {this.renderCamera()}
      </View>
    );
  }
}

const mapState = (state) => ({
  fps: state.settings.fps,
  data: state.data,
  settings: state.settings,
  zoom: state.settings.zoom,
  expanded: state.settings.expanded,
  cameraY: state.settings.cameraY,
  cameraX: state.settings.cameraX,
  gridSize: state.settings.gridSize,
  s: state.settings.scaleOffset,
});

const mapDispatch = (dispatch) => ({
  setCameraPosition: (x, y) => dispatch(setCameraPosition(x, y)),
});

export default connect(mapState, mapDispatch)(Camera);
