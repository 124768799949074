import React from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import { selectColor } from '../../store/actions/toolsActions';
import { shouldBlur } from '../../store/selectors';
class ActiveColors extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.colors !== this.props.colors || nextProps.showColors !== this.props.colors) {
      return true;
    }
    return false;
  }
  render() {
    const { blur } = this.props;
    const containerWidth = this.props.screen.orgW * 0.4 - 250;
    return (
      <View
        style={{
          position: 'absolute',
          left: 250,
          bottom: 0,
          height: 28,
          width: containerWidth,
          zIndex: 300000,
          backgroundColor: '#111',
          display: this.props.showColors ? 'none' : 'flex',
          filter: blur ? 'blur(5px)' : 'blur(0px)',
        }}
      >
        <View style={{ display: 'flex' }}>
          {this.props.colors &&
            this.props.colors.length > 0 &&
            this.props.colors.map((color) => {
              return (
                <Button
                  key={color}
                  style={{
                    height: '100%',
                    width: Math.round(containerWidth / this.props.colors.length),
                    backgroundColor: color,
                    borderBottomLeftRadius: 0,
                    borderColor: this.props.activeColor === color ? 'white' : 'transparent',
                    borderWidth: 2,
                  }}
                  onClick={() => this.props.setColor(color)}
                />
              );
            })}
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showColors: state.tools.showColors,
  blur: shouldBlur(state),
  colors: state.tools.userColors,
  activeColor: state.tools.activeColor,
  theme: state.theme,
});

const mapDispatch = (dispatch) => ({
  setColor: (color) => dispatch(selectColor(color)),
});

export default connect(mapState, mapDispatch)(ActiveColors);
