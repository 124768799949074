import React, { Component } from 'react';
import { Text, Button, View } from '../BaseComponents';
import styles from '../styles/Layers.module.scss';
import LayerOpacity from '../settings/LayerOpacitySlider';

class LayerOpacitySelector extends Component {
  render() {
    const { setOpacityForLayer, theme } = this.props; // eslint-disable-line
    return (
      <div
        style={{
          background: '#0f0f0f',
          flex: 1,
          position: 'absolute',
          zIndex: 10,
          width: 250,
          height: 130,
          flexDirection: 'row',
          left: 0,
          opacity: 1,
        }}
      >
        <View
          style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', background: '#141414' }}
        >
          <View>
            <Text
              className={styles.titleText}
              style={{ color: theme.themes[theme.current].sectionTitleColor, background: 'transparent' }}
            >
              Set layer opacity
            </Text>
          </View>
          <Button
            style={{ position: 'absolute', width: 40, height: 32, background: 'green', right: 0 }}
            onClick={() => {
              this.props.close();
            }}
          >
            <Text className={styles.titleText} style={{ color: 'red', background: '#222' }}>
              x
            </Text>
          </Button>
        </View>
        <View style={{ justifyContent: 'center', display: 'flex', width: 250, height: 98 }}>
          <LayerOpacity {...this.props} />
        </View>
      </div>
    );
  }
}

export default LayerOpacitySelector;
