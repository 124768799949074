import React, { Component } from 'react';
import { Image, View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/MainTools.module.scss';
import * as assets from '../../config/assets';

import { TOOL_TYPES, THEME_TYPES } from '../../store/actions/types';
import { showColors, selectDraw } from '../../store/actions/toolsActions';

class MainTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      row: 0,
    };
  }

  render() {
    const { theme } = this.props;
    const light = theme.current === THEME_TYPES.LIGHT_THEME;

    return (
      <View
        className={styles.container}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          width: 70,
        }}
      >
        <View className={styles.edges}>
          <View
            className={styles.positionRow}
            style={{
              top: this.state.row,
            }}
          >
            <View className={styles.btns}>
              {/* DRAW */}
              <Button
                onClick={() => this.props.selectDraw()}
                track={{ category: 'Tools', action: 'Select draw' }}
                className={styles.drawContainer}
                description="pen (b)"
                position={{ marginLeft: 0, marginTop: -10 }}
              >
                <Image src={light ? assets.L_pen : assets.pen} className={styles.image} />
                <View
                  className={styles.imageBackground}
                  style={{
                    backgroundColor:
                      this.props.activeTool === TOOL_TYPES.PEN ? (light ? 'white' : 'white') : 'transparent',
                  }}
                />
              </Button>
            </View>
            <View className={styles.btns} style={{ marginTop: 5 }}>
              <Button
                onClick={() => {
                  this.props.showColors();
                }}
                track={{ category: 'Tools', action: 'Select colors' }}
                description="colors"
                position={{ marginLeft: 12, marginTop: -10 }}
                style={{ backgroundColor: this.props.activeColor }}
              >
                <Image src={light ? assets.L_color : assets.color} className={styles.image} />
                <View
                  className={styles.imageBackground}
                  style={{
                    backgroundColor: 'transparent',
                    // this.props.activeTool === TOOL_TYPES.COLOR ? (light ? 'white' : 'white') : 'transparent'
                  }}
                />
              </Button>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  activeTool: state.tools.activeTool,
  activeColor: state.tools.activeColor,
  expanded: state.settings.expanded,
  theme: state.theme,
});
const mapDispatchToProps = (dispatch) => ({
  showColors: () => {
    dispatch(showColors());
  },
  selectDraw: () => {
    dispatch(selectDraw());
  },
});

export default connect(mapState, mapDispatchToProps)(MainTools);
