export default {
  INIT: 'INIT',
  SELECT_COLOR: 'SELECT_COLOR',
  SET_COLOR: 'SET_COLOR',
  SELECT_DRAW: 'SELECT_DRAW',
  SELECT_MIRROR: 'SELECT_MIRROR',
  SELECT_MIRROR_X: 'SELECT_MIRROR_X',
  SELECT_MIRROR_Y: 'SELECT_MIRROR_Y',
  SELECT_SQUARE: 'SELECT_SQUARE',
  SELECT_LINE: 'SELECT_LINE',
  LOAD_DATA: 'LOAD_DATA',
  REGENERATE_AFTER_DROP: 'REGENERATE_AFTER_DROP',
  REGENERATE_AFTER_DROP_COMPLETE: 'REGENERATE_AFTER_DROP_COMPLETE',
  UPDATE_LOAD_TEXT: 'UPDATE_LOAD_TEXT',
  UPDATE_LOAD_PERCENTAGE: 'UPDATE_LOAD_PERCENTAGE',
  LOAD_DATA_COMPLETE: 'LOAD_DATA_COMPLETE',
  SET_GRID: 'SET_GRID',
  LIGHT_GRID: 'LIGHT_GRID',
  SAVE_HISTORY: 'SAVE_HISTORY',
  SELECT_COLORPICKER: 'SELECT_COLORPICKER',
  SELECT_ERASER: 'SELECT_ERASER',
  SELECT_FILL: 'SELECT_FILL',
  UNDO: 'UNDO',
  ADD_NEW_FRAME: 'ADD_NEW_FRAME',
  ADD_NEW_FRAME_ON_LAYER: 'ADD_NEW_FRAME_ON_LAYER',
  ADD_NEW_LAYER: 'ADD_NEW_LAYER',
  SET_CURRENT_FRAME: 'SET_CURRENT_FRAME',
  CLONE_FRAME: 'CLONE_FRAME',
  SELECT_FRAME: 'SELECT_FRAME',
  SELECT_LAYER: 'SELECT_LAYER',
  SELECT_MOVE: 'SELECT_MOVE',
  SELECT_CLONE: 'SELECT_CLONE',
  DELETE_LAYER: 'DELETE_LAYER',
  DELETE_FRAME: 'DELETE_FRAME',
  CLEAR_FRAME: 'CLEAR_FRAME',
  PUSH_PIXEL: 'PUSH_PIXEL',
  REPLACE_PIXEL: 'REPLACE_PIXEL',
  DELETE_PIXEL: 'DELETE_PIXEL',
  SET_PIXELS: 'SET_PIXELS',
  UPDATE_LAYER_SNAPSHOT: 'UPDATE_LAYER_SNAPSHOT',
  UPDATE_FRAME_SNAPSHOT: 'UPDATE_FRAME_SNAPSHOT',
  START_ANIMATION: 'START_ANIMATION',
  STOP_ANIMATION: 'STOP_ANIMATION',
  SET_BACKGROUND_CANVAS_REF: 'SET_BACKGROUND_CANVAS_REF',
  UPDATE_BACKGROUND_CANVAS: 'UPDATE_BACKGROUND_CANVAS',
  SET_DRAW_CANVAS_REF: 'SET_DRAW_CANVAS_REF',
  SET_RAW_FAME_CANVAS_REF: 'SET_RAW_FAME_CANVAS_REF',
  SET_SPRITESHEET_CANVAS_REF: 'SET_SPRITESHEET_CANVAS_REF',
  SET_TOOL_CANVAS_REF: 'SET_TOOL_CANVAS_REF',
  SET_RAW_CANVAS_REF: 'SET_RAW_CANVAS_REF',
  SET_AREA_CANVAS_REF: 'SET_AREA_CANVAS_REF',
  DRAW_CANVAS_UPDATE: 'DRAW_CANVAS_UPDATE',
  RAW_CANVAS_UPDATE: 'RAW_CANVAS_UPDATE',
  RAW_FRAME_CANVAS_UPDATE: 'RAW_FRAME_CANVAS_UPDATE',
  SET_FPS: 'SET_FPS',
  SET_ZOOM: 'SET_ZOOM',
  UPDATE_CAMERA_POSITON: 'UPDATE_CAMERA_POSITON',
  RESET_CAMERA: 'RESET_CAMERA',
  TOGGLE_EXPAND: 'TOGGLE_EXPAND',
  RESUME_ANIMATION: 'RESUME_ANIMATION',
  BRUSH_SMALL: 'BRUSH_SMALL',
  BRUSH_BIG: 'BRUSH_BIG',
  SET_BRUSH: 'SET_BRUSH',
  SET_PALETTE: 'SET_PALETTE',
  LOAD_COLORS: 'LOAD_COLORS',
  FIRST_PAINT: 'FIRST_PAINT',
  SAVE_ANIMATION: 'SAVE_ANIMATION',
  UPDATE_ANIMATION: 'UPDATE_ANIMATION',
  CANCEL_SAVE: 'CANCEL_SAVE',
  UPDATE_ANIMATION_LIST: 'UPDATE_ANIMATION_LIST',
  UPDATE_PUBLIC_ANIMATION_LIST: 'UPDATE_PUBLIC_ANIMATION_LIST',
  CLEAR_CHANGES_SINCE_LAST_SAVED: 'CLEAR_CHANGES_SINCE_LAST_SAVED',
  APP_RESET: 'APP_RESET',
  SHOW_ANIMATION_DETAILS: 'SHOW_ANIMATION_DETAILS',
  HIDE_ANIMATION_DETAILS: 'HIDE_ANIMATION_DETAILS',
  SAVE_CURRENT_DATA: 'SAVE_CURRENT_DATA',
  REMOVE_LAST_UNDO: 'REMOVE_LAST_UNDO',
  SET_GRIDSIZE: 'SET_GRIDSIZE',
  FORCE_UNSAVED_CHANGE: 'FORCE_UNSAVED_CHANGE',
  CLONE_CURRENT_FRAME_TO_REST: 'CLONE_CURRENT_FRAME_TO_REST',
  SET_RENDER_FACTOR: 'SET_RENDER_FACTOR',
  STORE_GENERATED_GIF: 'STORE_GENERATED_GIF',
};
