import React from 'react';
import { connect } from 'react-redux';
import { selectLayer, deleteLayer, swapLayers } from '../../store/actions/uiActions';
import LayerSortRow from './LayerSortRow';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#353535' : '#181818',
  padding: grid,
  width: 400,
});

class LayersSort extends React.Component {
  constructor(props) {
    super(props);
    this.scrollView = null;
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  getItems() {
    const data = this.props.data.layers.map((a, b) => ({
      id: `item-${b}`,
      index: b,
    }));
    return data;
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.props.swapLayer(result.source.index, result.destination.index);
  }

  renderLayersWrapper() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {this.getItems().map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <LayerSortRow
                        isCurrent={index === this.props.currentFrame}
                        currentFrame={this.props.currentFrame}
                        isDragging={snapshot.isDragging}
                        def={index}
                        data={this.props.data}
                        settings={this.props.settings}
                        deleteLayer={this.props.deleteLayer}
                        selectLayer={this.props.selectLayer}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    return this.renderLayersWrapper();
  }
}

const mapState = (state) => ({
  data: state.data,
  currentFrame: state.data.currentFrame,
  settings: state.settings,
});

const mapDispatch = (dispatch) => ({
  selectLayer: (index) => dispatch(selectLayer(index)),
  deleteLayer: (index) => dispatch(deleteLayer(index)),
  swapLayer: (a, b) => dispatch(swapLayers(a, b)),
});

export default connect(mapState, mapDispatch)(LayersSort);
