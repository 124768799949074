/*
reducePixels:
*/

export const reducePixels = (nextSet, result) => {
  const sprite = reducePixelsAtIndex(nextSet, 0);
  Object.keys(sprite.shape).length !== 0 && result.push(sprite.shape);

  if (sprite.nextIndex > -1 && sprite.nextSet) {
    reducePixels(sprite.nextSet, result);
  }
};

const getHeuristicDirection = (stream) => {
  const test = stream[0];

  const h = hasNeighborX(stream, test);
  const v = hasNeighborY(stream, test);

  if (h.res && !v.res) {
    return 'horisontal';
  }
  if (v.res && !h.res) {
    return 'vertical';
  }

  return 'horisontal';
};

const reducePixelsAtIndex = (stream, index) => {
  const pixels = [];
  // decide row or columns
  if (getHeuristicDirection(stream) === 'horisontal') {
    generateRowFromIndex(stream, pixels, index);
    if (pixels.length > 1) {
      generateShapeFromRow(stream, pixels);
    }
  } else {
    generateColumnFromIndex(stream, pixels, index);
  }

  const shape = pixels.length > 1 ? createShape(pixels) : { ...pixels[0] };

  // get unused pixels
  const result = stream.filter((x) => !pixels.includes(x));

  let tempIndex;
  if (pixels.length > 1) {
    tempIndex = pixels.findIndex((px) => px.x === shape.b) + 1;
  } else {
    tempIndex = stream.indexOf(...result);
  }

  const nextIndex = tempIndex;

  return {
    shape,
    nextSet: result,
    nextIndex,
  };
};

const hasNeighborX = (stream, testObj) => {
  const condition = stream.findIndex(
    (px) => px.x === testObj.x + 1 && px.y === testObj.y && px.color === testObj.color
  );
  return condition > -1 ? { res: true, neighbor: stream[condition], index: condition } : { res: false };
};

const hasNeighborY = (arr, testObj) => {
  const condition = arr.findIndex((p) => p.y === testObj.y + 1 && p.x === testObj.x && p.color === testObj.color);
  return condition > -1 ? { res: true, neighbor: arr[condition], index: condition } : { res: false };
};

const createShape = (pixels) => {
  let minX = 100;
  let maxX = -100;
  let minY = 100;
  let maxY = -100;
  let color = '';
  pixels.forEach((px) => {
    minX = px.x < minX ? px.x : minX;
    maxX = px.x > maxX ? px.x : maxX;
    minY = px.y < minY ? px.y : minY;
    maxY = px.y > maxY ? px.y : maxY;
    color = px.color;
  });

  return {
    shape: {
      a: minX,
      b: maxX,
      c: minY,
      d: maxY,
      e: color,
    },
  };
};

const generateRowFromIndex = (stream, pixels, index) => {
  pixels.push(stream[index]);
  if (index < stream.length - 1) {
    const a = hasNeighborX(stream, stream[index]);
    if (a.res === true) {
      generateRowFromIndex(stream, pixels, a.index);
    }
  }
};

const generateColumnFromIndex = (stream, pixels, index) => {
  pixels.push(stream[index]);
  if (index < stream.length - 1) {
    const a = hasNeighborY(stream, stream[index]);
    if (a.res === true) {
      generateColumnFromIndex(stream, pixels, a.index);
    }
  }
};

const generateShapeFromRow = (stream, pixels) => {
  const tempPixels = pixels.slice(0);
  const steps = pixels.length;
  const color = pixels[0].color;
  const rows = [];
  testRow(stream, rows, tempPixels, color, steps);

  pixels.push(...rows);
};

const testRow = (stream, rows, pixelSet, color, steps) => {
  if (!pixelSet[0]) {
    // exit
    return;
  }
  const neighbors = [];
  let tempSteps = 0;
  pixelSet.forEach((p) => {
    const b = hasNeighborY(stream, p);
    const n = b.neighbor;
    if (b.res && n.color === p.color) {
      tempSteps += 1;
      neighbors.push(b.neighbor);
    }
  });
  if (tempSteps === steps) {
    rows.push(...neighbors);
  }

  testRow(stream, rows, neighbors, color, steps);
};

// --------------------- test reducePixels --------------------- //
/*
[black][black][black][black][black][black][black][black][black][black]
[black][black][black][black][black][black][black][black][black][black]
[black][black][black][black][black][black][black][black][black][black]
[black][black][black][black][black][black][black][black][black][black][red]
*/

const testReducePixels = false;

if (testReducePixels) {
  const test = [];
  const reduced = [];
  reducePixels(test, reduced);

  // console.log('result', JSON.stringify(reduced))
  // console.log(test.length, '<->', reduced.length)
}
