/* eslint-disable */
/* prettier-ignore */
const paletteColorA = [
    '#B71C1C',      '#D32F2F',      '#F44336',      '#E57373',      '#FFCDD2',      '#880E4F', 
    '#C2185B',      '#E91E63',      '#F06292',      '#F8BBD0',      '#4A148C',      '#7B1FA2', 
    '#9C27B0',      '#BA68C8',      '#E1BEE7',      '#311B92',      '#512DA8',      '#673AB7',
    '#9575CD',      '#D1C4E9',      '#1A237E',      '#303F9F',      '#3F51B5',      '#7986CB',
    '#C5CAE9',      '#0D47A1',      '#1976D2',      '#2196F3',      '#64B5F6',      '#BBDEFB',
    '#01579B',      '#0288D1',      '#03A9F4',      '#4FC3F7',      '#B3E5FC',      '#006064',
    '#0097A7',      '#00BCD4',      '#4DD0E1',      '#B2EBF2',      '#004D40',      '#00796B', 
    '#009688',      '#4DB6AC',      '#B2DFDB',      '#194D33',      '#388E3C',      '#4CAF50', 
    '#81C784',      '#C8E6C9',      '#33691E',      '#689F38',      '#8BC34A',      '#AED581', 
    '#DCEDC8',      '#827717',      '#AFB42B',      '#CDDC39',      '#DCE775',      '#F0F4C3',
    '#F57F17',      '#FBC02D',      '#FFEB3B',      '#FFF176',      '#FFF9C4',      '#FF6F00', 
    '#FFA000',      '#FFC107',      '#FFD54F',      '#FFECB3',      '#E65100',      '#F57C00', 
    '#FF9800',      '#FFB74D',      '#FFE0B2',      '#3E2723',      '#5D4037',      '#795548', 
    '#A1887F',      '#D7CCC8',      '#263238',      '#455A64',      '#607D8B',      '#90A4AE',
    '#CFD8DC',      '#000000',      '#525252',      '#969696',      '#D9D9D9',      '#FFFFFF',
    '#fbf5ef',      '#f2d3ab',      '#c69fa5',      '#8b6d9c',      '#494d7e',      '#272744',
    '#ffe4c2',      '#dca456',      '#a9604c',      '#422936',      '#1b141e',      '#fe935a',
    '#e25322',      '#a82424',      '#691b28',      '#ffbf89',      '#e7825a',      '#be5340',
    '#7a321c',      '#d08058',      '#974e49',      '#5a303f',      '#ffc95c',      '#eb8a06',
    '#cde042',      '#68b229',      '#257d2c',      '#1b4e44',      '#7becbf',      '#38aa91',
    '#29777e',      '#25446c',      '#5ed7ef',      '#2096cd',      '#2662ab',      '#303386',
    '#a3ccff',      '#788dde',      '#5458c0',      '#efa1ce',      '#b66cbe',      '#74448d',
    '#432f65',      '#ffb2b2',      '#ea6d9d',      '#af407f',      '#75224a',      '#eb7171',
    '#b1415c',      '#e3c4b0',      '#b18e8e',      '#74647f',      '#3e3f64',      '#dfdd9a',
    '#9caa74',      '#617b47',      '#2a4e32',      '#b8d8d1',      '#759da9',      '#526a98',
    '#fdf5f1',      '#ccc1be',      '#918692',      '#5d5b6e',      '#38384c',      '#fefed7',
    '#dbbc96',      '#ddac46',      '#c25940',      '#683d64',      '#9c6659',      '#88434f',
    '#4d2831',      '#a9aba3',      '#666869',      '#51b1ca',      '#1773b8',      '#639f5b',
    '#376e49',      '#323441',      '#161323',      '#dbe0e7',      '#a3acbe',      '#67708b',
    '#4e5371',      '#393a56',      '#26243a',      '#141020',      '#7bcf5c',      '#509b4b',
    '#2e6a42',      '#1a453b',      '#0f2738',      '#0d2f6d',      '#0f4da3',      '#0e82ce',
    '#13b2f2',      '#41f3fc',      '#f0d2af',      '#e5ae78',      '#c58158',      '#945542',
    '#623530',      '#46211f',      '#97432a',      '#e57028',      '#f7ac37',      '#fbdf6b',
]
/* prettier-ignore */
const paletteColorB = [
    '#fe979b',      '#ed5259',      '#c42c36',      '#781f2c',      '#351428',      '#4d2352',
    '#7f3b86',      '#b45eb3',      '#e38dd6',      '#be4a2f',      '#d77643',      '#ead4aa',
    '#e4a672',      '#b86f50',      '#733e39',      '#3e2731',      '#a22633',      '#e43b44',
    '#f77622',      '#feae34',      '#fee761',      '#63c74d',      '#3e8948',      '#265c42',
    '#193c3e',      '#124e89',      '#0099db',      '#2ce8f5',      '#ffffff',      '#c0cbdc',
    '#8b9bb4',      '#5a6988',      '#3a4466',      '#262b44',      '#181425',      '#ff0044',
    '#68386c',      '#b55088',      '#f6757a',      '#e8b796',      '#c28569',      '#060608',
    '#141013',      '#3b1725',      '#73172d',      '#b4202a',      '#df3e23',      '#fa6a0a',
    '#f9a31b',      '#ffd541',      '#fffc40',      '#d6f264',      '#9cdb43',      '#59c135',
    '#14a02e',      '#1a7a3e',      '#24523b',      '#122020',      '#143464',      '#285cc4',
    '#249fde',      '#20d6c7',      '#a6fcdb',      '#ffffff',      '#fef3c0',      '#fad6b8',
    '#f5a097',      '#e86a73',      '#bc4a9b',      '#793a80',      '#403353',      '#242234',
    '#221c1a',      '#322b28',      '#71413b',      '#bb7547',      '#dba463',      '#f4d29c',
    '#dae0ea',      '#b3b9d1',      '#8b93af',      '#6d758d',      '#4a5462',      '#333941',
    '#422433',      '#5b3138',      '#8e5252',      '#ba756a',      '#e9b5a3',      '#e3e6ff',
    '#b9bffb',      '#849be4',      '#588dbe',      '#477d85',      '#23674e',      '#328464',
    '#5daf8d',      '#92dcba',      '#cdf7e2',      '#e4d2aa',      '#c7b08b',      '#a08662',
    '#796755',      '#5a4e44',      '#423934',      '#000000',      '#e03c28',      '#ffffff',
    '#d7d7d7',      '#a8a8a8',      '#7b7b7b',      '#343434',      '#151515',      '#0d2030',
    '#415d66',      '#71a6a1',      '#bdffca',      '#25e2cd',      '#0a98ac',      '#005280',
    '#00604b',      '#20b562',      '#58d332',      '#139d08',      '#004e00',      '#172808',
    '#376d03',      '#6ab417',      '#8cd612',      '#beeb71',      '#eeffa9',      '#b6c121',
    '#939717',      '#cc8f15',      '#ffbb31',      '#ffe737',      '#f68f37',      '#ad4e1a',
    '#231712',      '#5c3c0d',      '#ae6c37',      '#c59782',      '#e2d7b5',      '#4f1507',
    '#823c3d',      '#da655e',      '#e18289',      '#f5b784',      '#ffe9c5',      '#ff82ce',
    '#cf3c71',      '#871646',      '#a328b3',      '#cc69e4',      '#d59cfc',      '#fec9ed',
    '#e2c9ff',      '#a675fe',      '#6a31ca',      '#5a1991',      '#211640',      '#3d34a5',
    '#6264dc',      '#9ba0ef',      '#98dcff',      '#5ba8ff',      '#0a89ff',      '#024aca',
    '#00177d',      '#e4a672',      '#b86f50',      '#743f39',      '#3f2832',      '#9e2835',
    '#e53b44',      '#fb922b',      '#ffe762',      '#63c64d',      '#327345',      '#193d3f',
    '#4f6781',      '#afbfd2',      '#ffffff',      '#2ce8f4',      '#0484d1',      '#1a1c2c',
    '#572956',      '#b14156',      '#ee7b58',      '#ffd079',      '#a0f072',      '#38b86e',
    '#276e7b',      '#29366f',      '#405bd0',      '#4fa4f7',      '#86ecf8',      '#f4f4f4',
    '#93b6c1',      '#557185',      '#324056' 
]
/* prettier-ignore */
const paletteColorC = [
    '#000000',      '#191919',      '#323232',      '#4c4c4c',      '#666666',      '#7f7f7f',
    '#999999',      '#b2b2b2',      '#cccccc',      '#e5e5e5',      '#ffffff',
]
/* eslint-enable */

const palettes = {
  colorA: paletteColorA,
  colorB: paletteColorB,
  colorC: paletteColorC,
  all: paletteColorA.concat(paletteColorB).concat(paletteColorC),
};

export default palettes;
