import React from 'react';
import { Text, Image, View, Button } from '../BaseComponents';
import styles from '../styles/Frames.module.scss';

class LayerSortRow extends React.Component {
  getLayerBorder(index) {
    return index === this.props.data.currentLayer
      ? this.props.light
        ? '#ffffff'
        : '#ffffff' // this.props.light ? "transparent" : "#ffffff"
      : this.props.light
      ? '#333333'
      : '#333333'; // this.props.light ? "transparent" : "#333333";
  }

  getLayerIndexBgColor(index) {
    return index === this.props.data.currentLayer ? '#ffffff' : 'rgba(0,0,0,0.2)';
  }

  getLayerIndexColor(index) {
    return index === this.props.data.currentLayer ? '#000' : '#aaa'; // this.props.light ? "#f2f2f2" : "#aaa";
  }

  getLayerBg(index) {
    return index % 2 ? '#222222' : '#252525';
  }

  getLayerBottomBorder(index) {
    return index === this.props.data.currentLayer ? 'transparent' : 'transparent';
  }

  getBackground(index) {
    return index === this.props.data.currentLayer ? 'rgb(16, 16, 16)' : this.getLayerBg(index);
  }

  getframeTopBorder() {
    return 'transparent';
  }

  hasValid64SData() {
    const layerIndex = this.props.def;
    return (
      this.props.data.layers[layerIndex].rawData[this.props.currentFrame] &&
      parseInt(this.props.data.layers[layerIndex].rawData[this.props.currentFrame].length, 10) > 20
    );
  }

  renderFrame64() {
    const frameIndex = this.props.currentFrame;
    if (this.hasValid64SData()) {
      return (
        <Image
          src={this.props.data.layers[this.props.def].rawData[0]}
          className={styles.frame64Image}
          style={{
            backgroundColor: frameIndex === this.props.data.currentFrame ? '#f3f3f3' : 'transparent',
          }}
        />
      );
    }

    return null;
  }

  render() {
    const index = this.props.def;
    return (
      <View
        {...this.props.sortHandlers}
        className={styles.frameRow}
        style={{
          borderBottomColor: this.getLayerBottomBorder(index),
          borderTopColor: this.getLayerBottomBorder(index),
          backgroundColor: this.getBackground(index),
        }}
      >
        <View className={styles.frameSnapshotContainer}>
          <Button
            className={styles.frameSnapshot}
            style={{ borderColor: this.getLayerBorder(index) }}
            onClick={() => {
              this.props.selectLayer(index);
            }}
          >
            <View className={styles.frameSnapshotImageContainer}>{this.renderFrame64(index)}</View>

            <View className={styles.frameIndexBox} style={{ backgroundColor: this.getLayerIndexBgColor(index) }}>
              <Text
                className={styles.frameSnapshotIndexText}
                style={{
                  color: this.getLayerIndexColor(index),
                }}
              >
                {index + 1}
              </Text>
            </View>
          </Button>
        </View>
      </View>
    );
  }
}

export default LayerSortRow;
