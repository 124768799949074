import { COMMON_TYPES, BRUSH_TYPES, PALETTE_TYPES, UI_TYPES } from '../actions/types';
import { mockMode } from './../../utils/mocks';

import layout from './../../config/layout';

const baseState = {
  init: false,
  isWebClient: true,
  zoom: 1,
  renderFactor: 1,
  maxZoom: 3,
  cameraY: 0,
  cameraX: 0,
  fps: 8,
  canvasPxSize: 500,
  gridSize: layout.gridSize,
  loadText: '',
  currentAnimationFrame: 0,
  loadPercentage: 0,
  onionSkinAlpha: 0.35,
  showAddFramePrompt: true,
  showDimensionView: false,
  onionFramesPrev: true,
  onionFramesNext: true,
  onionLayers: true,
  showGrid: true,
  firstPaint: false,
  showResetConfirm: false,
  showDeleteConfirm: false,
  showSettings: false,
  showSettingsOptions: false,
  showSettingsProfile: true,
  showLayerSortView: false,
  showExpandedFrameTools: false,
  framesDrag: false,
  showFrames: true,
  dockFrames: true,
  showLoad: false,
  showAddFrameDialog: false,
  animate: false,
  resumeAnimation: false,
  expanded: false,
  canvasHover: false,
  backgroundCanvasRef: null,
  drawCanvasRef: null,
  rawCanvasRef: null,
  rawFrameRef: null,
  areaCanvasRef: null,
  spritesheetCanvasRef: null,
  screen: { width: null, height: null, orgW: window.innerWidth, orgH: window.innerHeight },
  scaleOffset: 0,
  brush: BRUSH_TYPES.BRUSH_SMALL,
  palette: PALETTE_TYPES.PALETTE_TYPE_A,
  exportSize: null,
  backgroundColor: 'transparent',
  showBackgroundColorSelector: false,
  xA: null,
  xB: null,
  yA: null,
  yB: null,
  areaDefineActive: false,
  areaDefineActiveType: null,
  tempAreaXY: null,
  areaControlActive: false,
};

const initialState = mockMode
  ? { ...baseState, gridSize: 48, showSettings: true, showSettingsProfile: true }
  : baseState;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_TYPES.INIT: {
      return { ...state, init: true };
    }
    case UI_TYPES.ACTIVATE_DEFINE_AREA: {
      return {...state, areaControlActive: true}
    }
    case UI_TYPES.CLOSE_DEFINE_AREA: {
      return {...state, areaControlActive: false}
    }
    case UI_TYPES.ACTIVATE_AREA_X_A: {
      return { ...state, areaDefineActive: true, areaDefineActiveType: 'XA' };
    }
    case UI_TYPES.ACTIVATE_AREA_X_B: {
      return { ...state, areaDefineActive: true, areaDefineActiveType: 'XB' };
    }
    case UI_TYPES.ACTIVATE_AREA_Y_A: {
      return { ...state, areaDefineActive: true, areaDefineActiveType: 'YA' };
    }
    case UI_TYPES.ACTIVATE_AREA_Y_B: {
      return { ...state, areaDefineActive: true, areaDefineActiveType: 'YB' };
    }
    case UI_TYPES.CLEAR_AREA_DEFINITION: {
      return { ...state, areaDefineActive: false, areaDefineActiveType: null, xA: null, xB: null, yA: null, yB: null };
    }
    case UI_TYPES.SET_X_A: {
      return { ...state, xA: action.payload, areaDefineActive: false, areaDefineActiveType: null, tempAreaXY: null };
    }
    case UI_TYPES.SET_X_B: {
      return { ...state, xB: action.payload, areaDefineActive: false, areaDefineActiveType: null, tempAreaXY: null };
    }
    case UI_TYPES.SET_Y_A: {
      return { ...state, yA: action.payload, areaDefineActive: false, areaDefineActiveType: null, tempAreaXY: null };
    }
    case UI_TYPES.SET_Y_B: {
      return { ...state, yB: action.payload, areaDefineActive: false, areaDefineActiveType: null, tempAreaXY: null };
    }
    case UI_TYPES.UPDATE_TEMP_AREA_VALUE: {
      return { ...state, tempAreaXY: action.payload };
    }
    case UI_TYPES.SET_EXPORT_SIZE: {
      return { ...state, exportSize: action.payload };
    }
    case UI_TYPES.ENABLE_SHOW_FRAME_PROMPT: {
      return { ...state, showAddFramePrompt: true };
    }
    case UI_TYPES.DISABLE_SHOW_FRAME_PROMPT: {
      return { ...state, showAddFramePrompt: false, showAddFrameDialog: false };
    }
    case COMMON_TYPES.APP_RESET: {
      return { ...state, showResetConfirm: false, firstPaint: false };
    }
    case UI_TYPES.SHOW_RESET_CONFIRM: {
      return { ...state, showResetConfirm: true };
    }
    case UI_TYPES.HIDE_RESET_CONFIRM: {
      return { ...state, showResetConfirm: false };
    }
    case UI_TYPES.SHOW_FRAMES_VIEW: {
      return { ...state, showFrames: true };
    }
    case UI_TYPES.SHOW_SETTINGS_VIEW: {
      return { ...state, showSettings: true };
    }
    case UI_TYPES.HIDE_SETTINGS_VIEW: {
      return { ...state, showSettings: false };
    }
    case UI_TYPES.HIDE_FRAMES_VIEW: {
      return { ...state, showFrames: false };
    }
    case COMMON_TYPES.SET_BACKGROUND_CANVAS_REF: {
      return { ...state, backgroundCanvasRef: action.payload };
    }
    case COMMON_TYPES.SET_DRAW_CANVAS_REF: {
      return { ...state, drawCanvasRef: action.payload };
    }
    case COMMON_TYPES.SET_RAW_CANVAS_REF: {
      return { ...state, rawCanvasRef: action.payload };
    }
    case COMMON_TYPES.SET_AREA_CANVAS_REF: {
      return { ...state, areaCanvasRef: action.payload };
    }
    case COMMON_TYPES.SET_RAW_FAME_CANVAS_REF: {
      return { ...state, rawFrameRef: action.payload };
    }
    case COMMON_TYPES.SET_SPRITESHEET_CANVAS_REF: {
      return { ...state, spritesheetCanvasRef: action.payload };
    }
    case COMMON_TYPES.SET_FPS: {
      return { ...state, fps: action.payload };
    }
    case COMMON_TYPES.SET_RENDER_FACTOR: {
      return { ...state, renderFactor: action.payload };
    }
    case UI_TYPES.SHOW_ONION_SKIN_FRAMES_LAYERS: {
      return { ...state, onionLayers: !state.onionLayers };
    }
    case UI_TYPES.SHOW_ONION_SKIN_FRAMES_PREV: {
      return { ...state, onionFramesPrev: !state.onionFramesPrev };
    }
    case UI_TYPES.SHOW_ONION_SKIN_FRAMES_NEXT: {
      return { ...state, onionFramesNext: !state.onionFramesNext };
    }
    case COMMON_TYPES.LOAD_DATA: {
      return { ...state, showSettings: false, showLoad: true };
    }
    case COMMON_TYPES.REGENERATE_AFTER_DROP: {
      return { ...state, showLoad: true };
    }
    case COMMON_TYPES.REGENERATE_AFTER_DROP_COMPLETE: {
      return { ...state, showLoad: false };
    }
    case COMMON_TYPES.UPDATE_LOAD_TEXT: {
      return { ...state, loadText: action.payload };
    }
    case COMMON_TYPES.LOAD_DATA_COMPLETE: {
      return {
        ...state,
        loadText: '',
        showLoad: false,
        loadPercentage: 0,
      };
    }
    case COMMON_TYPES.UPDATE_LOAD_PERCENTAGE: {
      return { ...state, loadPercentage: action.payload };
    }
    case COMMON_TYPES.SET_ZOOM: {
      return { ...state, zoom: action.payload };
    }
    case COMMON_TYPES.UPDATE_CAMERA_POSITON: {
      return {
        ...state,
        cameraX: action.payload.cameraX,
        cameraY: action.payload.cameraY,
      };
    }
    case COMMON_TYPES.RESET_CAMERA: {
      return {
        ...state,
        cameraX: 0,
        cameraY: 0,
        zoom: 1,
      };
    }
    case COMMON_TYPES.SET_GRID: {
      return { ...state, showGrid: action.payload };
    }
    case COMMON_TYPES.START_ANIMATION: {
      return { ...state, animate: true };
    }
    case COMMON_TYPES.STOP_ANIMATION: {
      return { ...state, animate: false };
    }
    case COMMON_TYPES.TOGGLE_EXPAND: {
      return { ...state, expanded: !state.expanded };
    }
    case COMMON_TYPES.RESUME_ANIMATION: {
      return { ...state, resumeAnimation: !state.resumeAnimation };
    }
    case COMMON_TYPES.SET_BRUSH: {
      return { ...state, brush: action.payload };
    }
    case COMMON_TYPES.SET_PALETTE: {
      return { ...state, palette: action.payload };
    }
    case UI_TYPES.SHOW_ADD_FRAME_DIALOG: {
      return { ...state, showAddFrameDialog: true };
    }
    case UI_TYPES.HIDE_ADD_FRAME_DIALOG: {
      return { ...state, showAddFrameDialog: false };
    }
    case COMMON_TYPES.ADD_NEW_FRAME: {
      return { ...state, showAddFrameDialog: false };
    }
    case COMMON_TYPES.FIRST_PAINT: {
      return { ...state, firstPaint: true };
    }
    case COMMON_TYPES.SELECT_COLOR: {
      return { ...state, firstPaint: true };
    }
    case COMMON_TYPES.LOAD_COLORS: {
      return { ...state, firstPaint: true };
    }
    case UI_TYPES.SHOW_SETTINGS_PROFILE: {
      return {
        ...state,
        showSettingsOptions: false,
        showSettingsProfile: true,
      };
    }
    case UI_TYPES.SHOW_SETTINGS_OPTIONS: {
      return {
        ...state,
        showSettingsOptions: true,
        showSettingsProfile: false,
      };
    }
    case UI_TYPES.UPDATE_ANIMATION_FRAME: {
      return { ...state, currentAnimationFrame: action.payload };
    }
    case UI_TYPES.START_FRAME_DRAG: {
      return { ...state, framesDrag: true };
    }
    case UI_TYPES.STOP_FRAME_DRAG: {
      return { ...state, framesDrag: false };
    }
    case UI_TYPES.SHOW_DELETE_ANIMATION_CONFIRM: {
      return { ...state, showDeleteConfirm: true };
    }
    case UI_TYPES.HIDE_DELETE_ANIMATION_CONFIRM: {
      return { ...state, showDeleteConfirm: false };
    }
    case UI_TYPES.DELETE_ANIMATION: {
      return { ...state, showDeleteConfirm: false, firstPaint: false };
    }
    case UI_TYPES.SHOW_SORT_LAYER_VIEW: {
      return { ...state, showLayerSortView: true, showExpandedFrameTools: false };
    }
    case UI_TYPES.HIDE_SORT_LAYER_VIEW: {
      return { ...state, showLayerSortView: false };
    }
    case UI_TYPES.SCREEN_RESIZE: {
      return { ...state, screen: action.payload };
    }
    case UI_TYPES.CANVAS_SCALE: {
      return { ...state, scaleOffset: action.payload };
    }
    case UI_TYPES.CANVAS_HOVER: {
      return { ...state, canvasHover: action.payload };
    }
    case COMMON_TYPES.SET_GRIDSIZE: {
      return { ...state, gridSize: action.payload };
    }
    case UI_TYPES.SHOW_EXPANDED_FRAME_TOOLS: {
      return { ...state, showExpandedFrameTools: true };
    }
    case UI_TYPES.HIDE_EXPANDED_FRAME_TOOLS: {
      return { ...state, showExpandedFrameTools: false };
    }
    case UI_TYPES.SHOW_DIMENSION_VIEW: {
      return { ...state, showDimensionView: true };
    }
    case UI_TYPES.HIDE_DIMENSION_VIEW: {
      return { ...state, showDimensionView: false };
    }
    case UI_TYPES.SET_CANVAS_PX_SIZE: {
      return { ...state, canvasPxSize: action.payload };
    }
    case UI_TYPES.SHOW_BACKGROUND_COLOR_SELECTOR: {
      return { ...state, showBackgroundColorSelector: true };
    }
    case UI_TYPES.HIDE_BACKGROUND_COLOR_SELECTOR: {
      return { ...state, showBackgroundColorSelector: false };
    }
    case UI_TYPES.SET_BACKGROUND_COLOR: {
      return { ...state, showBackgroundColorSelector: false, backgroundColor: action.payload };
    }
    case UI_TYPES.CLEAR_BACKGROUND_COLOR: {
      return { ...state, backgroundColor: 'transparent' };
    }
    default:
  }

  return state;
}
