import React from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/MirrorOverlay.module.scss';
import { TOOL_TYPES } from '../../store/actions/types/';
import { selectMirrorX, selectMirrorY } from '../../store/actions/toolsActions';

class AnimationView extends React.Component {
  render() {
    const { mirrorX, mirrorY } = this.props;
    const { width, orgW, orgH } = this.props.screen;
    return (
      <View id="mirror" pointerEvents="none" style={{ position: 'absolute', zIndex: 10 }}>
        {this.props.show === TOOL_TYPES.MIRROR && (
          <>
            <View
              className={styles.mirrorOptions}
              style={{ left: orgW / 2 - width / 2 - 40, top: (orgH - 25) / 2 - width / 2 }}
            >
              <Button onClick={() => this.props.selectX()} className={mirrorX ? styles.selected : null}>
                X
              </Button>
              <Button onClick={() => this.props.selectY()} className={mirrorY ? styles.selected : null}>
                Y
              </Button>
            </View>
            <View
              className={styles.mirrorOverlayX}
              style={{
                width: width / 2,
                height: width,
                left: orgW / 2,
                top: (orgH - 25) / 2 - width / 2,
                opacity: mirrorX ? 1 : 0,
              }}
            />
            <View
              className={styles.mirrorOverlayY}
              style={{
                width: width,
                height: width / 2,
                left: orgW / 2 - width / 2,
                top: (orgH - 25) / 2,
                opacity: mirrorY ? 1 : 0,
              }}
            />
          </>
        )}
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  show: state.tools.activeTool,
  mirrorX: state.tools.mirrorX,
  mirrorY: state.tools.mirrorY,
});

const mapDispatch = (dispatch) => ({
  selectX: () => dispatch(selectMirrorX()),
  selectY: () => dispatch(selectMirrorY()),
});

export default connect(mapState, mapDispatch)(AnimationView);
