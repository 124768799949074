import { UI_TYPES, THEME_TYPES } from '../actions/types';

const initialState = {
  current: THEME_TYPES.DARK_THEME,
  themes: [
    {
      statusbar: 'light-content',
      background: '#181818',
      panelBackground: '#252525',
      quickNavPanel: '#181818',
      statusBar: '#212121',
      gridBgA: 'rgba(0,0,0,0.25)', // "#232323",
      gridBgB: 'transparent', // "#191919",
      sectionTitleBackground: '#191919',
      sectionTitleColor: '#FFF',
      borderColor: '#2b2b2b',
      cancelBtnColor: '#871414',
      buttonColor: '#191919',
      activeBorderColor: '#494949',
      activePanelColor: '#212121',
      animationControls: '#1d1d1d',
      activeColorBg: '#181818',
      statusBarFramesBg: '#191919',
      statusBarFPSBg: '#212121',
      frameIndicatorText: '#555',
      frameIndicatorTextCurrent: '#FFF',
      optionTabColor: '#121212',
      optionTabSelectedColor: '#212121',
      optionTabTextColor: '#444',
      optionTabTextSelectedColor: 'white',
      loadBtnBg: '#131313',
      loadBtnTextColor: 'gray',
      loadItemBg: '#212121',
      loadItemBg2: '#242424',
      loadItemBorder: '#383838',
      loadItemText: 'white',
      loadThumbBorder: '#444444',
      loadThumbActiveBorder: '#888888',
      loadBorderBottom: '#292929',
      loadBorderBottomActive: '#666666',
      loadItemActiveBg: '#0e0e0e',
      profileBorderBottom: '#333333',
      colorSelectBg: '#121212',
      overlay: 'rgba(0,0,0,0.4)',
    },
    {
      statusbar: 'light-content',
      background: '#181818',
      panelBackground: '#252525',
      quickNavPanel: '#181818',
      statusBar: '#212121',
      gridBgA: 'rgba(0,0,0,1)', // "#232323",
      gridBgB: 'rgba(0,0,0,0)', // "#191919",
      sectionTitleBackground: '#191919',
      sectionTitleColor: '#FFF',
      borderColor: '#2b2b2b',
      cancelBtnColor: '#871414',
      buttonColor: '#191919',
      activeBorderColor: '#494949',
      activePanelColor: '#212121',
      animationControls: 'rgba(37, 37 ,37, 0.8)',
      activeColorBg: '#181818',
      statusBarFramesBg: '#191919',
      statusBarFPSBg: '#212121',
      frameIndicatorText: '#555',
      frameIndicatorTextCurrent: '#FFF',
      optionTabColor: '#121212',
      optionTabSelectedColor: '#212121',
      optionTabTextColor: '#444',
      optionTabTextSelectedColor: 'white',
      loadBtnBg: '#202020',
      loadBtnTextColor: 'gray',
      loadItemBg: '#212121',
      loadItemBg2: '#333333',
      loadItemBorder: '#383838',
      loadItemText: 'white',
      loadThumbBorder: '#444444',
      loadThumbActiveBorder: '#888888',
      loadBorderBottom: '#292929',
      loadBorderBottomActive: '#666666',
      loadItemActiveBg: '#0e0e0e',
      profileBorderBottom: '#333333',
      colorSelectBg: '#121212',
      overlay: 'rgba(0,0,0,0.9)',
    },
  ],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UI_TYPES.SELECT_LIGHT_THEME: {
      return {
        ...state,
        current: THEME_TYPES.LIGHT_THEME,
      };
    }
    case UI_TYPES.SELECT_DARK_THEME: {
      return {
        ...state,
        current: THEME_TYPES.DARK_THEME,
      };
    }
    default:
  }

  return state;
}

// light theme:
// {
//   statusbar: "dark-content",
//   background: "#FFFFFF",
//   panelBackground: "#f2f2f2",
//   gridBgA: "#fcfcfc",
//   gridBgB: "#efefef",
//   sectionTitleBackground: "#eaeaea",
//   sectionTitleColor: "#666",
//   borderColor: "#CCC",
//   cancelBtnColor: "#871414",
//   buttonColor: "#cdcdcd",
//   activeBorderColor: "#494949",
//   activePanelColor: "#CCCCCC",
//   statusBar: "#CCCCCC",
//   quickNavPanel: "#FFF",
//   animationControls: "rgba(255, 255 ,255, 0.6)",
//   activeColorBg: "#ededed",
//   statusBarFramesBg: "#d8d8d8",
//   statusBarFPSBg: "#c1c1c1",
//   frameIndicatorText: "#AAA",
//   frameIndicatorTextCurrent: "#848484",
//   optionTabColor: "#CCC",
//   optionTabSelectedColor: "#f2f2f2",
//   optionTabTextColor: "#777",
//   optionTabTextSelectedColor: "#333",
//   loadBtnBg: "#C1C1C1",
//   loadBtnTextColor: "#222",
//   loadItemBg: "#f2f2f2",
//   loadItemBorder: "transparent",
//   loadItemText: "#222",
//   loadThumbBorder: "#CCC",
//   loadThumbActiveBorder: "#f3f3f3",
//   loadBorderBottom: "#AAA",
//   loadBorderBottomActive: "#CCC",
//   loadItemActiveBg: "#FFFFFF",
//   profileBorderBottom: "#AAA",
//   colorSelectBg: "#f2f2f2",
//   overlay: "rgba(255,255,255,0.9)"
// }
