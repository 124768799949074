import React, { Component } from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import { addFrameDialogReject } from '../../store/actions/uiActions';
import styles from '../styles/AddFrameView.module.scss';
import AddFrameDialog from './AddFrameDialog';

class AddFrameView extends Component {
  renderAddFrameContainer() {
    return (
      <View className={styles.container}>
        {/* FRAMES */}
        <AddFrameDialog />
        {/* OVERLAY */}
        {this.props.showAddFrameDialog && (
          <Button
            className={styles.overlay}
            style={{ width: this.props.screen.orgW, height: this.props.screen.orgH }}
            onClick={() => {
              this.props.reject();
            }}
          />
        )}
      </View>
    );
  }

  render() {
    return this.renderAddFrameContainer();
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showAddFrameDialog: state.settings.showAddFrameDialog,
});

const mapDispatch = (dispatch) => ({
  reject: () => dispatch(addFrameDialogReject()),
});

export default connect(mapState, mapDispatch)(AddFrameView);
