import React from 'react';
import { connect } from 'react-redux';
import { swapFrames, setCurrentFrame, cloneFrame, deleteFrame, clearFrame } from '../../store/actions/uiActions';
import FrameRow from './FrameRow';
import { TooltipView } from '../BaseComponents';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 0;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#353535' : '#181818',
  padding: grid,
  width: 400,
});

class Frames extends React.Component {
  constructor(props) {
    super(props);
    this.scrollView = null;
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  getItems() {
    const data = this.props.data.layers[this.props.data.currentLayer].pixelData.map((a, b) => ({
      id: `item-${b}`,
      index: b,
    }));
    return data;
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    this.props.swap(result.source.index, result.destination.index);
  }

  renderFramesWrapper() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
              {this.getItems().map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      <TooltipView>
                        <FrameRow
                          isCurrent={index === this.props.currentFrame}
                          isDragging={snapshot.isDragging}
                          def={index}
                          data={this.props.data}
                          settings={this.props.settings}
                          cloneFrame={this.props.cloneFrame}
                          clearFrame={this.props.clearFrame}
                          deleteFrame={this.props.deleteFrame}
                          selectFrame={this.props.selectFrame}
                          backgroundColor={this.props.backgroundColor}
                        />
                      </TooltipView>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  render() {
    return this.props.settings.showFrames ? this.renderFramesWrapper() : null;
  }
}

const mapState = (state) => ({
  data: state.data,
  currentFrame: state.data.currentFrame,
  settings: state.settings,
  backgroundColor: state.settings.backgroundColor,
});

const mapDispatch = (dispatch) => ({
  selectFrame: (index) => dispatch(setCurrentFrame(index)),
  cloneFrame: (index) => dispatch(cloneFrame(index)),
  clearFrame: (index) => dispatch(clearFrame(index)),
  deleteFrame: (index) => dispatch(deleteFrame(index)),
  swap: (a, b) => dispatch(swapFrames(a, b)),
});

export default connect(mapState, mapDispatch)(Frames);
