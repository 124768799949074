export default {
  SHOW_COLORS: 'SHOW_COLORS',
  HIDE_COLORS: 'HIDE_COLORS',
  SHOW_FRAMES_VIEW: 'SHOW_FRAMES_VIEW',
  SHOW_SETTINGS_VIEW: 'SHOW_SETTINGS_VIEW',
  HIDE_SETTINGS_VIEW: 'HIDE_SETTINGS_VIEW',
  SHOW_SETTINGS_OPTIONS: 'SHOW_SETTINGS_OPTIONS',
  SHOW_SETTINGS_PROFILE: 'SHOW_SETTINGS_PROFILE',
  SHOW_ONION_SKIN_FRAMES_PREV: 'SHOW_ONION_SKIN_FRAMES_PREV',
  SHOW_ONION_SKIN_FRAMES_NEXT: 'SHOW_ONION_SKIN_FRAMES_NEXT',
  SHOW_ONION_SKIN_FRAMES_LAYERS: 'SHOW_ONION_SKIN_FRAMES_LAYERS',
  SHOW_ADD_FRAME_DIALOG: 'SHOW_ADD_FRAME_DIALOG',
  SHOW_SAVE_VIEW: 'SHOW_SAVE_VIEW',
  SHOW_PROFILE_AFTER_SIGN_IN: 'SHOW_PROFILE_AFTER_SIGN_IN',
  HIDE_FRAMES_VIEW: 'HIDE_FRAMES_VIEW',
  HIDE_ADD_FRAME_DIALOG: 'HIDE_ADD_FRAME_DIALOG',
  HIDE_SAVE_VIEW: 'HIDE_SAVE_VIEW',
  SHOW_RESET_CONFIRM: 'SHOW_RESET_CONFIRM',
  HIDE_RESET_CONFIRM: 'HIDE_RESET_CONFIRM',
  UPDATE_ANIMATION_FRAME: 'UPDATE_ANIMATION_FRAME',
  START_FRAME_DRAG: 'START_FRAME_DRAG',
  STOP_FRAME_DRAG: 'STOP_FRAME_DRAG',
  SHOW_SORT_LAYER_VIEW: 'SHOW_SORT_LAYER_VIEW',
  HIDE_SORT_LAYER_VIEW: 'HIDE_SORT_LAYER_VIEW',
  MOVE_FRAME: 'MOVE_FRAME',
  MOVE_LAYER: 'MOVE_LAYER',
  SHOW_DELETE_ANIMATION_CONFIRM: 'SHOW_DELETE_ANIMATION_CONFIRM',
  HIDE_DELETE_ANIMATION_CONFIRM: 'HIDE_DELETE_ANIMATION_CONFIRM',
  DELETE_ANIMATION: 'DELETE_ANIMATION',
  SELECT_LIGHT_THEME: 'SELECT_LIGHT_THEME',
  SELECT_DARK_THEME: 'SELECT_DARK_THEME',
  ONBOARDED: 'ONBOARDED',
  TRIGGER_ONBOARDING: 'TRIGGER_ONBOARDING',
  SCREEN_RESIZE: 'SCREEN_RESIZE',
  CANVAS_SCALE: 'CANVAS_SCALE',
  CANVAS_HOVER: 'CANVAS_HOVER',
  SHOW_EXPANDED_FRAME_TOOLS: 'SHOW_EXPANDED_FRAME_TOOLS',
  HIDE_EXPANDED_FRAME_TOOLS: 'HIDE_EXPANDED_FRAME_TOOLS',
  SHOW_DIMENSION_VIEW: 'SHOW_DIMENSION_VIEW',
  HIDE_DIMENSION_VIEW: 'HIDE_DIMENSION_VIEW',
  SET_CANVAS_PX_SIZE: 'SET_CANVAS_PX_SIZE',
  ENABLE_SHOW_FRAME_PROMPT: 'ENABLE_SHOW_FRAME_PROMPT',
  DISABLE_SHOW_FRAME_PROMPT: 'DISABLE_SHOW_FRAME_PROMPT',
  SET_EXPORT_SIZE: 'SET_EXPORT_SIZE',
  SET_LAYER_OPACITY: 'SET_LAYER_OPACITY',
  SHOW_LAYER_OPACITY: 'SHOW_LAYER_OPACITY',
  CLOSE_LAYER_OPACITY: 'CLOSE_LAYER_OPACITY',
  SET_LAYER_OPACITY_INDEX: 'SET_LAYER_OPACITY_INDEX',
  SHOW_BACKGROUND_COLOR_SELECTOR: 'SHOW_BACKGROUND_COLOR_SELECTOR',
  HIDE_BACKGROUND_COLOR_SELECTOR: 'HIDE_BACKGROUND_COLOR_SELECTOR',
  SET_BACKGROUND_COLOR: 'SET_BACKGROUND_COLOR',
  CLEAR_BACKGROUND_COLOR: 'CLEAR_BACKGROUND_COLOR',
  ACTIVATE_AREA_X_A: 'ACTIVATE_AREA_X_A',
  ACTIVATE_AREA_X_B: 'ACTIVATE_AREA_X_B',
  ACTIVATE_AREA_Y_A: 'ACTIVATE_AREA_Y_A',
  ACTIVATE_AREA_Y_B: 'ACTIVATE_AREA_Y_B',
  SET_X_A: 'SET_X_A',
  SET_X_B: 'SET_X_B',
  SET_Y_A: 'SET_Y_A',
  SET_Y_B: 'SET_Y_B',
  CLEAR_AREA_DEFINITION: 'CLEAR_AREA_DEFINITION',
  UPDATE_TEMP_AREA_VALUE: 'UPDATE_TEMP_AREA_VALUE',
  ACTIVATE_DEFINE_AREA: 'ACTIVATE_DEFINE_AREA',
  CLOSE_DEFINE_AREA: 'CLOSE_DEFINE_AREA'
};
