/* eslint-disable camelcase */
import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { USER_TYPES, COMMON_TYPES, UI_TYPES } from './types';
import { loadData, exportData, appReset } from './index';

import { saveAnimation } from './animationActions';
import { hideSettingsView } from './uiActions';
import { generateGIF } from './canvasActions';

import { snapshotToArray } from '../../utils';

export const setSIP = (sip) => (dispatch) => {
  dispatch({ type: USER_TYPES.SET_SIP, payload: sip });
};

export const firebaseSignInSuccess = (firebaseData) => async (dispatch, getState) => {
  dispatch({
    type: USER_TYPES.SIGN_IN_SUCCESS,
    payload: firebaseData,
  });

  const user = {
    name: firebaseData.user.displayName,
    email: firebaseData.user.email,
    photoURL: firebaseData.user.photoURL,
    id: firebaseData.user.uid,
    firebaseRefreshToken: firebaseData.user.refreshToken,
  };

  // console.log('USER ->', user);

  const refreshToken = user.firebaseRefreshToken;
  // console.log('REFRESH', refreshToken); // eslint-disable-line
  const provider = getState().user.sip;
  await provider.setTokenAsync(refreshToken);

  // console.log('token in storage', provider.getToken()); // eslint-disable-line
};

export const loadUserSimpleData = (id, token) => (dispatch) => {
  const firebaseDatabaseRef = Firebase.database();
  // console.log('ref', firebaseDatabaseRef);
  firebaseDatabaseRef.ref(`${'users/'}${id}`).on('value', (snapshot) => {
    const data = snapshot.val();
    // console.log('simple', data);
    const user = {
      name: data.name,
      email: data.email,
      photo: data.photo,
      firebaseRefreshToken: token,
      id,
    };

    dispatch(silentSignInSucess(user));

    dispatch(fetchUserDataOnce());
  });
};

export const requestSave = () => async (dispatch, getState) => {
  // user is logged out
  if (!getState().user.signedIn) {
    dispatch(signInAndSave());
    dispatch(showSignIn());
  } else {
    // user is logged in
    const { animationUID } = getState().data.animation;
    if (animationUID) {
      // console.log("req save", isPublic);
      // if we have an UID ref
      dispatch({ type: USER_TYPES.HIDE_LOGIN_SCREEN });
      dispatch(firebaseUpdate());
    } else {
      // if it has no name, show the save view
      dispatch(showSaveView());
    }
  }
};

export const generateGIFOnSave = () => async (dispatch) => {
  dispatch(generateGIF());
};

export const requestCancel = () => (dispatch, getState) => {
  const { user } = getState();
  if (user.signInAndSave) {
    dispatch({ type: USER_TYPES.HIDE_LOGIN_SCREEN });
  }
  dispatch(cancelSave());
};

const firebaseUpdate = () => (dispatch, getState) => {
  const layerData = dispatch(exportData());
  const data = JSON.stringify(layerData.data);
  const { animation } = getState().data;
  const settings = getState().settings;
  const tools = getState().tools;
  // console.log("update animation", animation);

  const date = new Date().getTime();

  const animationUpdate = {
    layerData: data,
    name: animation.name,
    fps: settings.fps,
    thumb: layerData.thumb.data,
    gridSize: settings.gridSize,
    saved: date,
    palette: tools.userColors,
    isWebClient: true,
    background: settings.backgroundColor,
  };
  const uid = Firebase.auth().currentUser.uid;
  const userRef = Firebase.database().ref('users');

  // update animation endpoint
  Firebase.database()
    .ref(`animations/${uid}/${animation.animationUID}`)
    .update(animationUpdate)
    .then(() => {
      // console.log("update complete");
      dispatch({
        type: COMMON_TYPES.UPDATE_ANIMATION,
        payload: {
          uid: animation.animationUID,
          name: animation.name,
          isPublic: animation.isPublic,
        },
      });
    });
  // .catch(error => console.log("error updating the animation", error));

  if (animation.isPublic) {
    const publicAnimationUpdate = { ...animationUpdate, isGenerating: false };

    Firebase.database()
      .ref(`public_animations/${uid}/${animation.animationUID}`)
      .update(publicAnimationUpdate)
      .then(() => {
        dispatch({
          type: COMMON_TYPES.UPDATE_PUBLIC_ANIMATION_LIST,
        });
      })
      .catch(() => {
        // console.log("error updating the public animation", error)
      });
  }

  // update user reference
  userRef
    .child(`${uid}/animations/${animation.animationUID}`)
    .update({
      name: animation.name,
      thumb: layerData.thumb.data,
      saved: date,
      isGenerating: false,
    })
    .then(() => {
      dispatch(
        saveAnimation({
          uid: animation.animationUID,
          name: animation.name,
          isPublic: animation.isPublic,
        })
      );
    });
};

export const firebaseSave = (animationName, isPublic) => (dispatch, getState) => {
  const layerData = dispatch(exportData());
  const data = JSON.stringify(layerData.data);
  const date = new Date().getTime();

  const autoSignedIn = getState().user.autoSignedIn;
  const settings = getState().settings;
  const tools = getState().tools;
  const userName = autoSignedIn ? getState().user.name : getState().user.user.user.displayName;

  const animationObject = {
    layerData: data,
    name: animationName,
    fps: settings.fps,
    gridSize: settings.gridSize,
    created: date,
    palette: tools.userColors,
    public: isPublic,
    isWebClient: true,
    background: settings.backgroundColor,
  };

  const userAnimObject = {
    name: animationName,
    thumb: layerData.thumb.data,
    created: date,
    saved: date,
    public: isPublic,
  };

  const uid = Firebase.auth().currentUser.uid;
  const userRef = Firebase.database().ref('users');

  const userAnimation = userRef.child(`${uid}/animations`).push({ ...userAnimObject, isGenerating: false });
  const animKey = userAnimation.key;

  Firebase.database()
    .ref(`animations/${uid}/${animKey}`)
    .set({ ...animationObject });

  if (isPublic) {
    Firebase.database()
      .ref(`public_animations/${uid}/${animKey}`)
      .set({
        ...animationObject,
        thumb: layerData.thumb.data,
        author: userName,
        isGenerating: false,
      });
  }

  dispatch(
    saveAnimation({
      uid: animKey,
      name: animationName,
      public: isPublic,
    })
  );

  dispatch(updateAnimationMetadata());
};

const updateAnimationMetadata = (add = true) => () => {
  Firebase.database()
    .ref('metadata')
    .once('value')
    .then((snapshot) => {
      const total = snapshot.val().total_animations;
      Firebase.database()
        .ref('metadata')
        .update({ total_animations: add ? total + 1 : total - 1 })
        .then(() => {
          // console.log("update total animations - success");
        })
        .catch(() => {
          // console.log("update total animations - failure");
        });
    })
    .catch(() => {
      // console.log("read total animations - failure");
    });
};

export const fetchUserData = () => (dispatch) => {
  if (Firebase.auth().currentUser) {
    const firebaseDatabaseRef = Firebase.database();
    const uid = Firebase.auth().currentUser.uid;
    firebaseDatabaseRef.ref(`${'users/'}${uid}`).on('value', (snapshot) => {
      if (snapshot.val()) {
        const arrayFromSnapshot = snapshotToArray(snapshot.val().animations);
        // console.log('FIREBASE sUPDATE ->', arrayFromSnapshot); // eslint-disable-line
        dispatch(updateAnimationList(arrayFromSnapshot));
      }
    });
  }
};

export const fetchUserDataOnce = () => (dispatch) => {
  if (Firebase.auth().currentUser) {
    const firebaseDatabaseRef = Firebase.database();
    const uid = Firebase.auth().currentUser.uid;
    firebaseDatabaseRef
      .ref(`${'users/'}${uid}`)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          const arrayFromSnapshot = snapshotToArray(snapshot.val().animations);
          dispatch(updateAnimationList(arrayFromSnapshot));
        }
      });
  } else {
    // console.error('no firebase user');
  }
};

export const setAnimationPrivacySetting = (setting, animationUid, thumb) => (dispatch, getState) => {
  const uid = Firebase.auth().currentUser.uid;
  const userName = getState().user.user.user.displayName;
  const date = new Date().getTime();

  if (setting === 'public') {
    // console.log("-> make public", animation_uid);
    Firebase.database()
      .ref(`animations/${uid}`)
      .child(animationUid)
      .update({
        public: true,
        saved: date,
      })
      .then(() => {
        Firebase.database()
          .ref(`animations/${uid}`)
          .child(animationUid)
          .once('value')
          .then((snapshot) => {
            Firebase.database()
              .ref(`public_animations/${uid}`)
              .child(animationUid)
              .set({
                ...snapshot.val(),
                public: true,
                thumb,
                author: userName,
                saved: date,
              })
              .then(() => {
                Firebase.database()
                  .ref(`${'users/'}${uid}/animations/${animationUid}`)
                  .update({
                    public: true,
                    saved: date,
                  })
                  .then(() => {
                    // dispatch(fetchUserData())
                    // console.log("add animation to public - success");
                  })
                  .catch(() => {
                    // console.log("add animation to public - failure");
                  });
              })
              .catch(() => {
                // console.log("add animation to public - failure");
              });
          })
          .catch(() => {
            //
          });
      })
      .catch(() => {});
  } else {
    // console.log("-> make private", animation_uid);

    Firebase.database()
      .ref(`animations/${uid}`)
      .child(animationUid)
      .update({ public: false, saved: date })
      .then(() => {
        Firebase.database()
          .ref(`public_animations/${uid}`)
          .child(animationUid)
          .remove()
          .then(() => {
            Firebase.database()
              .ref(`${'users/'}${uid}/animations/${animationUid}`)
              .update({
                public: false,
                saved: date,
              })
              .then(() => {
                // dispatch(fetchUserData())
                // console.log("remove animation from public - success");
              })
              .catch(() => {
                // console.log("remove animation from public - failure");
              });
          })
          .catch(() => {
            // console.log("remove animation from public - failure");
          });
      })
      .catch(() => {});
  }
};

export const deleteAnimation = (animationUid) => (dispatch) => {
  const uid = Firebase.auth().currentUser.uid;

  Firebase.database()
    .ref(`${'users/'}${uid}/animations/${animationUid}`)
    .remove()
    .then(() => {
      // console.log('remove animation from user - success');

      dispatch(updateAnimationMetadata(false));
      dispatch(appReset());
      dispatch(fetchUserDataOnce());

      Firebase.database()
        .ref(`${'animations/'}${uid}/${animationUid}`)
        .remove()
        .then(() => {
          // console.log("remove animation from private animations success");
        })
        .catch(() => {
          // console.log("remove animation from private animations failure");
        });

      Firebase.database()
        .ref(`${'public_animations/'}${uid}/${animationUid}`)
        .remove()
        .then(() => {
          // console.log("remove animation from public animations success");
        })
        .catch(() => {
          // console.log("remove animation from public animations failure");
        });
    })
    .catch(() => {
      // console.log("remove animation from user - failure");
    });
};

export const loadAnimationByUID = (AnimationUid, isPublic) => (dispatch) => {
  const uid = Firebase.auth().currentUser.uid;

  Firebase.database()
    .ref(`${'animations/'}${uid}/${AnimationUid}`)
    .once('value')
    .then((snapshot) => {
      dispatch(loadData(snapshot.val(), AnimationUid, isPublic));
    })
    .catch(() => {
      // log error
    });
};

export const updateAnimationList = (animations) => (dispatch) => {
  dispatch({
    type: COMMON_TYPES.UPDATE_ANIMATION_LIST,
    payload: animations,
  });
};

export const signInAndSave = () => ({ type: USER_TYPES.SIGN_IN_AND_SAVE });

export const showSignIn = () => (dispatch, getState) => {
  dispatch({ type: USER_TYPES.SHOW_LOGIN_SCREEN });
  if (getState().settings.showSettings) {
    // toggle settings view
    dispatch(hideSettingsView());
    dispatch(showProfileAfterSignInSuccess());
  }
};

export const signInSuccess = (user) => {
  // console.log('user when signed in -->', user); // eslint-disable-line
  return {
    type: USER_TYPES.SIGN_IN_SUCCESS,
    payload: user,
  };
};

export const silentSignInSucess = (user) => {
  return {
    type: USER_TYPES.SILENT_SIGN_IN_SUCCESS,
    payload: user,
  };
};

export const showProfileAfterSignInSuccess = () => ({
  type: UI_TYPES.SHOW_PROFILE_AFTER_SIGN_IN,
});

export const showAnimationDetails = (uid) => (dispatch) => {
  dispatch({ type: COMMON_TYPES.SHOW_ANIMATION_DETAILS, payload: uid });
};

export const hideAnimationDetails = () => (dispatch) => {
  dispatch({ type: COMMON_TYPES.HIDE_ANIMATION_DETAILS });
};

export const showSaveView = () => ({ type: UI_TYPES.SHOW_SAVE_VIEW });

export const hideSaveView = () => ({ type: UI_TYPES.HIDE_SAVE_VIEW });

export const cancelSave = () => ({ type: COMMON_TYPES.CANCEL_SAVE });

export const signIn = () => ({ type: USER_TYPES.SIGN_IN });

export const signOut = () => ({ type: USER_TYPES.SIGN_OUT });

export const signOutSuccess = () => ({ type: USER_TYPES.SIGN_OUT_SUCCESS });
