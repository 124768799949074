/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mockMode, debugMode } from '../../utils/mocks';
import {
  generateCroppedSpriteSheetData,
  generateSpriteSheet,
  setRenderFactor,
  generateZipOfGif,
  saveFramesAsZip,
} from '../../store/actions/uiActions';
import { centerFrameContent } from '../../store/actions/canvasActions';
import { View, Button, ScrollView, Text, Image } from '../BaseComponents';
import styles from '../styles/DebugStyles.module.scss';
import { generateGIFAnimation } from '../../store/actions';

class DebugView extends Component {
  render() {
    return (
      debugMode && (
        <View className={styles.container}>
          <Button
            className={styles.btn}
            onClick={() => {
              this.props.action2();
            }}
          >
            <Text>spritesheet (normal)</Text>
          </Button>
          <Button
            className={styles.btn}
            onClick={() => {
              this.props.action3();
            }}
          >
            <Text>spritesheet (cropped)</Text>
          </Button>
          <Button
            className={styles.btn}
            onClick={() => {
              this.props.dFrames();
            }}
          >
            <Text>Download frames</Text>
          </Button>
          <Button
            className={styles.btn}
            onClick={() => {
              this.props.saveGIF();
            }}
          >
            <Text>save gif</Text>
          </Button>
        </View>
      )
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  layers: state.data.layers,
});

const mapDispatch = (dispatch) => ({
  action: () => dispatch(generateCroppedSpriteSheetData()),
  action2: () => dispatch(generateSpriteSheet()),
  action3: () => dispatch(generateSpriteSheet(true)),
  renderFactor: (rf) => dispatch(setRenderFactor(rf)),
  genGIF: (layers, width, height, fps, size) => dispatch(generateGIFAnimation(layers, width, height, fps, size)),
  saveGIF: () => dispatch(generateZipOfGif(true)),
  dFrames: () => dispatch(saveFramesAsZip()),
});

export default connect(mapState, mapDispatch)(DebugView);
