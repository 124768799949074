import layout from './../config/layout';

export const generateLineFromPoints = (x0, y0, x1, y1, gridSize = layout.gridSize) => {
  const dx = Math.abs(x1 - x0);
  const dy = Math.abs(y1 - y0);
  const sx = x0 < x1 ? 1 : -1;
  const sy = y0 < y1 ? 1 : -1;
  let err = dx - dy;

  const drawPoints = [];

  // eslint-disable-next-line
  while (true) {
    const point = { x: x0, y: y0 };

    if (point.x >= gridSize) {
      return [];
    }

    drawPoints.push(point);

    if (x0 === x1 && y0 === y1) break;
    const e2 = 2 * err;
    if (e2 > -dy) {
      err -= dy;
      x0 += sx;
    }
    if (e2 < dx) {
      err += dx;
      y0 += sy;
    }
  }

  return drawPoints;
};
