import React, { Component } from 'react';
import { Button, View } from '../BaseComponents';
import { connect } from 'react-redux';
import SettingsOptions from './SettingsOptions';
import styles from '../styles/SettingsControl.module.scss';
import { hideSettingsView } from '../../store/actions/uiActions';

class SettingsView extends Component {
  renderSettings() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <>
        <View className={styles.container}>
          {/* Settings */}
          <SettingsOptions />
        </View>
        {/* OVERLAY */}
        {this.props.show && (
          <Button
            className={styles.overlay}
            style={{ backgroundColor: th.overlay }}
            onClick={() => {
              this.props.hideSettingsView();
            }}
          />
        )}
      </>
    );
  }

  render() {
    return this.renderSettings();
  }
}

const mapState = (state) => {
  return {
    show: state.settings.showSettings,
    theme: state.theme,
  };
};

const mapDispatch = (dispatch) => ({
  hideSettingsView: () => dispatch(hideSettingsView()),
});

export default connect(mapState, mapDispatch)(SettingsView);
