const dimensions = {
  width: window.innerWidth,
  height: window.innerHeight,
};
const canvasPixelBlur = 0;
const canvasPadding = 100;
const devices = {};
const gridSize = 48;

let topBarHeight;
let canvasHMargin;
let menuViewWidth;
let framesViewWidth;
let totalHeight;
let topSectionHeight;
let canvasDrawSize;
let ipadMainToolsWidth;
let ipadSubtoolsWidth;
let ipadAnimationSize;
let ipadCameraControlSize;
let ipadControlAreaHeight;
let ipadTopAreaHeight;
let sectionTitleHeight;
let sectionTitleFontSize;
let ipadTitleFontSize;
let frameScrollY;
let canvasAnimationSize;

sectionTitleHeight = 40;
sectionTitleFontSize = dimensions.width * 0.03;
topBarHeight = 30;
canvasHMargin = 20;
framesViewWidth = 250;
menuViewWidth = 350;
totalHeight = dimensions.height - 30;
topSectionHeight = totalHeight * 0.35;
canvasDrawSize = window.innerWidth * 0.75;
canvasAnimationSize = dimensions.height / 2 / 2;
frameScrollY = 240;
const topSectionMarginTop = topBarHeight;
const canvasTopMargin = devices.IS_TABLET ? ipadTopAreaHeight : topSectionHeight + topSectionMarginTop;

const canvasFrameSize = (dimensions.height - topBarHeight) / 7;
const history = [];
const historyLevels = 2;
const addToHistory = true;
const sectionTitleBackground = '#191919';
const sectionTitleColor = '#666666';
const buttonColor = '#191919';
const cancelBtnColor = '#871414';
const gridBgLight = '#232323'; // '#292929'
const gridBgDark = '#191919'; // '#222222'
const borderColor = 'rgba(0,0,0,0.04)'; // "#2e2e2e";
const activeBorderColor = '#494949';
const addFrameHeight = 200;
const onionSkinAlpha = 0.35;
const canvasSnapshotSize = 100;

const config = {
  dimensions,
  topBarHeight,
  canvasHMargin,
  totalHeight,
  topSectionHeight,
  topSectionMarginTop,
  menuViewWidth,
  framesViewWidth,
  canvasDrawSize,
  canvasSnapshotSize,
  canvasTopMargin,
  canvasPixelBlur,
  canvasAnimationSize,
  canvasFrameSize,
  history,
  historyLevels,
  addToHistory,
  gridBgLight,
  gridBgDark,
  sectionTitleBackground,
  sectionTitleHeight,
  sectionTitleColor,
  sectionTitleFontSize,
  ipadTitleFontSize,
  borderColor,
  cancelBtnColor,
  buttonColor,
  activeBorderColor,
  onionSkinAlpha,
  frameScrollY,
  addFrameHeight,
  ipadMainToolsWidth,
  ipadSubtoolsWidth,
  ipadAnimationSize,
  ipadCameraControlSize,
  ipadControlAreaHeight,
  ipadTopAreaHeight,
  gridSize,
  canvasPadding,
};

export default config;
