import React, { Component } from 'react';
import { Image, View, Button, ScrollView } from '../BaseComponents';
import { TOOL_TYPES } from '../../store/actions/types';
import * as assets from '../../config/assets';
import styles from '../styles/SubTools.module.scss';

class SubTools extends Component {
  renderEraser() {
    return (
      <View className={styles.tool}>
        <Button
          onClick={() => this.props.erase()}
          className={styles.toolTouch}
          description="eraser (e)"
          position={{ position: 'relative' }}
          track={{ category: 'Tools', action: 'Select eraser' }}
        >
          <Image src={assets.eraser} className={styles.toolImage} />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.ERASER ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderColorPicker() {
    return (
      <View className={styles.tool}>
        <Button
          onClick={() => this.props.copyColor()}
          className={styles.toolTouch}
          description="picker"
          position={{ position: 'relative' }}
          track={{ category: 'Tools', action: 'Select color picker' }}
        >
          <Image src={assets.picker} className={styles.toolImage} />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.COLORPICKER ? 'white' : 'transparent', // all same
            }}
          />
        </Button>
      </View>
    );
  }

  renderColorMirror() {
    return (
      <View className={styles.tool} pointerEvents={this.props.zoom > 1 ? 'none' : 'auto'}>
        <Button
          onClick={() => this.props.mirror()}
          className={styles.toolTouch}
          description="mirror"
          track={{ category: 'Tools', action: 'Select mirror mode' }}
          position={{ position: 'relative' }}
        >
          <Image src={assets.mirror} className={styles.toolImage} style={{ opacity: this.props.zoom > 1 ? 0.2 : 1 }} />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.MIRROR ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderUndo() {
    return (
      <View className={styles.tool} pointerEvents={this.props.undoEnabled ? 'none' : 'auto'}>
        <Button
          disabled={!this.props.undoEnabled}
          onClick={() => this.props.undo()}
          className={styles.toolTouch}
          description="undo (z)"
          track={{ category: 'Interface', action: 'Undo' }}
          position={{ marginLeft: 0, width: 38 }}
        >
          <Image
            src={assets.undo}
            className={styles.toolImage}
            style={{ opacity: !this.props.undoEnabled ? 0.2 : 1 }}
          />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderFill() {
    return (
      <View className={styles.tool}>
        <Button
          onClick={() => this.props.fill()}
          className={styles.toolTouch}
          description="bucket"
          position={{ position: 'relative' }}
          track={{ category: 'Tools', action: 'Select paint bucket' }}
        >
          <Image src={assets.fill} className={styles.toolImage} />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.FILL ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderSelectMove() {
    return (
      <View className={styles.tool} pointerEvents={this.props.zoom > 1 ? 'none' : 'auto'}>
        <Button
          onClick={() => this.props.selectMoveTool()}
          className={styles.toolTouch}
          description="move"
          track={{ category: 'Tools', action: 'Select move' }}
          position={{ position: 'relative', marginLeft: 5 }}
        >
          <Image
            src={assets.select_move}
            className={styles.toolImage}
            style={{ opacity: this.props.zoom > 1 ? 0.2 : 1 }}
          />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.MOVE ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderSelectClone() {
    return (
      <View className={styles.tool} pointerEvents={this.props.zoom > 1 ? 'none' : 'auto'}>
        <Button
          onClick={() => this.props.selectCloneTool()}
          className={styles.toolTouch}
          description="clone"
          track={{ category: 'Tools', action: 'Select clone' }}
          position={{ position: 'relative', marginLeft: 5 }}
        >
          <Image
            src={assets.select_clone}
            className={styles.toolImage}
            style={{ opacity: this.props.zoom > 1 ? 0.2 : 1 }}
          />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.MOVE_CLONE ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderSelectSquare() {
    return (
      <View className={styles.tool} pointerEvents={this.props.zoom > 1 ? 'none' : 'auto'}>
        <Button
          onClick={() => this.props.selectSquareTool()}
          className={styles.toolTouch}
          description="square"
          position={{ position: 'relative' }}
          track={{ category: 'Tools', action: 'Select square' }}
        >
          <Image src={assets.square} className={styles.toolImage} style={{ opacity: this.props.zoom > 1 ? 0.2 : 1 }} />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.SQUARE ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderSelectLine() {
    return (
      <View className={styles.tool} pointerEvents={this.props.zoom > 1 ? 'none' : 'auto'}>
        <Button
          onClick={() => this.props.selectLineTool()}
          className={styles.toolTouch}
          description="line"
          track={{ category: 'Tools', action: 'Select line' }}
          position={{ position: 'relative' }}
        >
          <Image src={assets.line} className={styles.toolImage} style={{ opacity: this.props.zoom > 1 ? 0.2 : 1 }} />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.LINE ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  renderMirrorEraser() {
    return (
      <View className={styles.tool}>
        <Button onClick={() => this.props.fill()} className={styles.toolTouch}>
          <Image src={assets.mirroreraser} className={styles.toolImage} />
          <View
            className={styles.toolBackground}
            style={{
              backgroundColor: this.props.activeButton === TOOL_TYPES.FILL ? 'white' : 'transparent',
            }}
          />
        </Button>
      </View>
    );
  }

  render() {
    return (
      <View
        className={styles.container}
        style={{
          backgroundColor: 'rgba(0,0,0,0.2)',
          height: this.props.height,
          width: 70,
        }}
      >
        <div className={styles.gradientbox + ' ' + styles.gradientTop} />
        <div className={styles.gradientbox + ' ' + styles.gradientBottom} />
        <ScrollView>
          <View
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            {this.renderUndo()}
            {this.renderColorMirror()}
            {this.renderSelectSquare()}
            {this.renderSelectLine()}
            {this.renderEraser()}
            {this.renderColorPicker()}
            {this.renderFill()}
            {this.renderSelectMove()}
            {this.renderSelectClone()}
          </View>
        </ScrollView>
      </View>
    );
  }
}

export default SubTools;
