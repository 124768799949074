import React from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/BackgroundSelector.module.scss';

import { setGrid } from '../../store/actions/uiActions';

class GridToggler extends React.Component {
  constructor(props) {
    super(props);
    this.btnSize = 25;
  }

  render() {
    const { showGrid, screen, showBackgroundColorSelector } = this.props;
    const l = showBackgroundColorSelector
      ? screen.orgW / 2 + screen.width / 2 + 40
      : screen.orgW / 2 + screen.width / 2;

    return (
      <View
        className={styles.container}
        style={{
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          width: this.props.screen.width + 100,
          left: l,
          top: (this.props.screen.orgH + 143) / 2 - this.props.screen.width / 2,
        }}
      >
        <Button
          className={styles.quickNavBtn}
          style={{
            backgroundColor: '#222',
            pointerEvents: 'auto',
          }}
          description={showGrid ? 'disable grid' : 'enable grid'}
          position={{ marginLeft: -25, marginTop: 5 }}
          track={{ category: 'Interface', action: 'Quick frame - next' }}
          onClick={() => {
            // console.log('click selector');
            if (showGrid) {
              this.props.setGrid(false);
            } else {
              this.props.setGrid(true);
            }
          }}
        >
          <div className={styles.divGrid}>
            <div className={styles.wrapper} style={{ opacity: showGrid ? 1 : 0.5 }}>
              <div className={styles.gridRow}>
                <div className={styles.gridA}></div>
                <div className={styles.gridB}></div>
              </div>
              <div className={styles.gridRow}>
                <div className={styles.gridB}></div>
                <div className={styles.gridA}></div>
              </div>
            </div>
          </div>
        </Button>
      </View>
    );
  }
}

const mapState = (state) => ({
  showGrid: state.settings.showGrid,
  screen: state.settings.screen,
  showBackgroundColorSelector: state.settings.showBackgroundColorSelector,
});

const mapDispatch = (dispatch) => ({
  setGrid: (val) => dispatch(setGrid(val)),
});

export default connect(mapState, mapDispatch)(GridToggler);
