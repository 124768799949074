import React, { Component } from 'react';
import { View, Button, Image, Text } from '../BaseComponents';
import { connect } from 'react-redux';
import * as assets from '../../config/assets';
import styles from '../styles/AnimationControls.module.scss';
import { startAnimation, stopAnimation } from '../../store/actions/animationActions';
import { selectColorPicker, selectEraser, selectMirror, selectFill, undo } from '../../store/actions/toolsActions';

import { moveCamera } from '../../store/actions/cameraActions';
import { toggleExpand } from '../../store/actions/uiActions';

class AnimationControls extends Component {
  constructor(props) {
    super(props);
    this.btnSize = '33.333%';
  }

  enablePlay() {
    const { data, animate } = this.props;
    if (data.layers[data.currentLayer] && data.layers[data.currentLayer].pixelData.length === 1) {
      return false;
    }
    if (animate) {
      return false;
    }
    return true;
  }

  renderAnimationName() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View className={styles.animationName} style={{ backgroundColor: th.animationControls }}>
        <Text>{this.props.animation.name ? this.props.animation.name : 'untitled'}</Text>
      </View>
    );
  }

  renderAnimationControls() {
    const { theme, expanded } = this.props;
    const expandedSize = 600;

    const th = theme.themes[theme.current];
    return (
      <View
        className={styles.animationControls}
        style={{
          width: expanded ? expandedSize : 150,
          height: expanded ? 50 : 50,
          backgroundColor: expanded ? 'rgba(20, 20 ,20, 0.9)' : th.animationControls,
          top: expanded ? 550 : 180,
        }}
      >
        <Button
          style={{
            width: '33.333%',
            height: this.btnSize,
          }}
          description={this.props.expanded ? 'close expanded view' : 'expand animation view'}
          position={{ marginLeft: this.props.expanded ? 36 : 10, marginTop: -70 }}
          track={{ category: 'Interface', action: 'Toggle expanded animation view' }}
          onClick={() => {
            this.props.expand();
          }}
        >
          <Image src={assets.expand} className={styles.animationImage} />
        </Button>
        <Button
          disabled={!this.enablePlay()}
          style={{
            width: '33.333%',
            height: this.btnSize,
          }}
          description="play animation"
          position={{ marginLeft: this.props.expanded ? 55 : -20, marginTop: -70 }}
          track={{ category: 'Interface', action: 'Play animation' }}
          onClick={() => this.props.play()}
        >
          <Image
            src={assets.play}
            className={styles.animationImage}
            style={{
              opacity: this.enablePlay() ? 1 : 0.2,
            }}
          />
        </Button>
        <Button
          disabled={!this.props.animate}
          style={{
            width: '33.333%',
            height: this.btnSize,
          }}
          description="stop animation"
          position={{ marginLeft: this.props.expanded ? 55 : -46, marginTop: -70 }}
          track={{ category: 'Interface', action: 'Stop animation' }}
          onClick={() => this.props.stop()}
        >
          <Image
            src={assets.pause}
            className={styles.animationImage}
            style={{
              opacity: this.props.animate ? 1 : 0.2,
            }}
          />
        </Button>
      </View>
    );
  }

  render() {
    return (
      <View>
        <View className={styles.container}>
          {!this.props.expanded && this.renderAnimationName()}
          {this.renderAnimationControls()}
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  data: state.data,
  animate: state.settings.animate,
  zoom: state.settings.zoom,
  animation: state.data.animation,
  expanded: state.settings.expanded,
  theme: state.theme,
});

const mapDispatch = (dispatch) => ({
  selectColorPicker: () => dispatch(selectColorPicker()),
  undo: () => dispatch(undo()),
  selectEraser: () => dispatch(selectEraser()),
  selectFill: () => dispatch(selectFill()),
  moveCamera: (direction) => dispatch(moveCamera(direction, true)),
  mirror: () => dispatch(selectMirror()),
  play: () => dispatch(startAnimation()),
  stop: () => dispatch(stopAnimation()),
  expand: () => dispatch(toggleExpand()),
});

export default connect(mapState, mapDispatch)(AnimationControls);
