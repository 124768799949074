export const hex2rgb = (hex) => ({
  r: parseInt(hex.substr(1, 2), 16),
  g: parseInt(hex.substr(3, 2), 16),
  b: parseInt(hex.substr(5, 2), 16),
});

const dec2hex = (d) => (d < 16 ? '0' : '') + d.toString(16);
export const rgb2hex = ({ r, g, b }) => dec2hex(r) + dec2hex(g) + dec2hex(b);

export const getUsedColors = (layers) => {
  const colors = [];
  layers.forEach((layer) => {
    layer.pixelData.forEach((frame) => {
      // get colors
      frame.forEach((pixel) => {
        const color = pixel.shape ? pixel.shape.e : pixel.color;
        if (colors.findIndex((c) => c === color) === -1) {
          colors.push(color);
        }
      });
    });
  });

  return colors;
};

export const getUnusedColor = (usedColors) => {
  usedColors = usedColors || [];
  // create check map
  var colorMap = {};
  usedColors.forEach(function (color) {
    colorMap[color.toUpperCase().replace('#', '')] = true;
  });

  // start with white
  var color = {
    r: 255,
    g: 255,
    b: 0,
  };
  var match = null;
  while (true) { // eslint-disable-line
    var hex = rgb2hex(color).toUpperCase();

    if (!colorMap[hex]) {
      match = hex;
      break;
    } else {
      // pick a non null component to decrease its value
      var component = (color.r && 'r') || (color.g && 'g') || (color.b && 'b');
      if (component) {
        color[component] = color[component] - 1;
      } else {
        // no component available, no match found
        match = 'FF00FF';
        break;
      }
    }
  }

  return { decimal: parseInt(match, 16), rgb: hex2rgb('#' + match) };
};
