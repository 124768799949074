import React from 'react';
import { connect } from 'react-redux';
import { View, Text } from '../BaseComponents';
import styles from '../styles/ExportOptions.module.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { setExportSize } from '../../store/actions/uiActions';

class ExportOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      size: this.props.gridSize,
    };
  }

  renderExportOptions() {
    const { size } = this.state;
    const { gridSize } = this.props;
    return (
      <View className={styles.container}>
        <View className={styles.section}>
          <Text>
            Grid size: {gridSize}x{gridSize}px
          </Text>
        </View>
        <View className={styles.section}>
          <Text>
            Export size: {size}x{size}px
          </Text>
        </View>
        <View style={{ paddingTop: 10 }}>
          <Slider
            min={8}
            max={512}
            defaultValue={gridSize}
            marks={{ [gridSize]: gridSize, 512: 512 }}
            step={2}
            railStyle={{ background: '#292929' }}
            trackStyle={{ background: '#5d5d5d' }}
            onChange={(e) => {
              // console.log('e end', e) // eslint-disable-line
              this.setState({ size: e });
            }}
            onAfterChange={(e) => {
              // console.log('e end', e) // eslint-disable-line
              this.setState({ size: e });
              this.props.setExportSize(e);
            }}
          />
        </View>
      </View>
    );
  }

  render() {
    return this.renderExportOptions();
  }
}

const mapState = (state) => ({
  gridSize: state.settings.gridSize,
});

const mapDispatch = (dispatch) => ({ // eslint-disable-line
  setExportSize: (size) => dispatch(setExportSize(size)),
});

export default connect(mapState, mapDispatch)(ExportOptions);
