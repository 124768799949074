import React from 'react';
import { ScrollView, View, Text, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Options.module.scss';
import layout from '../../config/layout';
import { THEME_TYPES } from '../../store/actions/types/';
import { loadData, appReset } from '../../store/actions';
import { setFPS } from '../../store/actions/animationActions';
import { renderNewGridSize } from '../../store/actions/';

import {
  toggleOnionPrev,
  toggleOnionNext,
  toggleOnionLayers,
  setGrid,
  showResetConfirm,
  hideResetConfirm,
  selectDarkTheme,
  selectLightTheme,
  triggerOnboarding,
  enableShowFramePrompt,
  disableShowFramePrompt,
} from '../../store/actions/uiActions';

class Options extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      frameRates: [4, 8, 12, 24],
    };
  }

  getTextStyle(a, b) {
    return a === b ? { color: 'black' } : { color: 'white' };
  }

  mainOptions() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View
        style={{
          display: 'flex',
          position: 'relative',
          height: 70,
          marginTop: -10,
          width: layout.menuViewWidth - 20,
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <View
          className={styles.resetWrapper}
          style={{
            position: 'absolute',
            width: '100%',
            marginTop: this.props.showResetConfirm ? -70 : 0,
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <View
            className={styles.buttons}
            style={{
              marginTop: 10,
              marginBottom: 10,
              flexDirection: 'column',
              height: 60,
            }}
          >
            <Button
              className={styles.optionSelector}
              style={{
                width: '100%',
                backgroundColor: th.buttonColor,
              }}
              description="clear the stage and start over"
              position={{ marginTop: 25 }}
              onClick={() => this.props.showReset()}
            >
              <Text
                style={{
                  color: 'gray',
                }}
              >
                New sprite / animation
              </Text>
            </Button>
          </View>
          {/* RESET */}
          <View
            className={styles.buttons}
            style={{
              marginTop: 0,
              marginBottom: 10,
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 60,
              flex: 1,
            }}
          >
            <Button
              className={styles.confirmBtns}
              style={{
                borderColor: th.borderColor,
                backgroundColor: th.cancelBtnColor,
                height: 50,
                flex: 1,
              }}
              onClick={() => this.props.hideReset()}
            >
              <Text
                style={{
                  color: 'white',
                }}
              >
                Cancel
              </Text>
            </Button>
            <Button
              className={styles.confirmBtns}
              style={{
                borderColor: th.borderColor,
                backgroundColor: th.buttonColor,
                height: 50,
                flex: 1,
              }}
              track={{ category: 'Interface', action: 'Reset stage' }}
              onClick={() => this.props.reset()}
            >
              <Text
                style={{
                  color: 'white',
                }}
              >
                Ok
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }

  fps() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    const fpsButtons = this.state.frameRates.map((rate, index) => {
      return (
        <View key={`rate${index}`} className={styles.option}>
          <Button
            className={styles.optionSelector}
            style={{
              backgroundColor: rate === this.props.currentFPS ? '#555' : th.buttonColor,
            }}
            description={`set fps to ${rate}`}
            position={{ marginTop: 25 }}
            track={{ category: 'Interface', action: `Change fps to ${rate}` }}
            onClick={() => this.props.fps(rate)}
          >
            <Text
              style={{
                color: rate === this.props.currentFPS ? 'white' : 'white',
              }}
            >
              {rate}
            </Text>
          </Button>
        </View>
      );
    });

    return (
      <View>
        <View className={styles.buttons}>{fpsButtons}</View>
      </View>
    );
  }

  framesPrompt() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View>
        <View className={styles.buttons}>
          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.showPrompt ? '#555' : th.buttonColor,
              }}
              description="On"
              position={{ marginLeft: 30, marginTop: 25 }}
              onClick={() => this.props.enableShowFramePrompt()}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.showPrompt ? 'white' : 'white',
                }}
              >
                On
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: !this.props.showPrompt ? '#555' : th.buttonColor,
              }}
              description="Off"
              position={{ marginTop: 25 }}
              onClick={() => this.props.disableShowFramePrompt()}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: !this.props.showPrompt ? 'white' : 'white',
                }}
              >
                Off
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }

  onions() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View>
        <View className={styles.buttons}>
          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.onionPrev ? '#555' : th.buttonColor,
              }}
              description="show for previous frame"
              position={{ marginLeft: 30, marginTop: 25 }}
              onClick={() => this.props.onOnionPrev()}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.onionPrev ? 'white' : 'white',
                }}
              >
                Previous
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.onionNext ? '#555' : th.buttonColor,
              }}
              description="show for next frame"
              position={{ marginTop: 25 }}
              onClick={() => this.props.onOnionNext()}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.onionNext ? 'white' : 'white',
                }}
              >
                Next
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.onionLayers ? '#555' : th.buttonColor,
              }}
              description="show for layers"
              position={{ marginTop: 25 }}
              onClick={() => this.props.onOnionLayers()}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.onionLayers ? 'white' : 'white',
                }}
              >
                Layers
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }

  grid() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View>
        <View className={styles.buttons}>
          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.grid ? '#555' : th.buttonColor,
              }}
              description="turn grid on"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Turn grid on' }}
              onClick={() => this.props.setGrid(true)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.grid ? 'white' : 'white',
                }}
              >
                On
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: !this.props.grid ? '#555' : th.buttonColor,
              }}
              description="turn grid off"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Turn grid off' }}
              onClick={() => this.props.setGrid(false)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: !this.props.grid ? 'white' : 'white',
                }}
              >
                Off
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }

  gridSize() {
    const { theme, gridSize } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View>
        <View className={styles.buttons}>
          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 8 ? '#555' : th.buttonColor,
              }}
              description="8x8"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 8x8' }}
              onClick={() => this.props.setGridSize(8)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.grid ? 'white' : 'white',
                }}
              >
                8x8
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 16 ? '#555' : th.buttonColor,
              }}
              description="16x16"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 16x16' }}
              onClick={() => this.props.setGridSize(16)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.grid ? 'white' : 'white',
                }}
              >
                16x16
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 24 ? '#555' : th.buttonColor,
              }}
              description="24x24"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 24x24' }}
              onClick={() => this.props.setGridSize(24)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.grid ? 'white' : 'white',
                }}
              >
                24x24
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 32 ? '#555' : th.buttonColor,
              }}
              description="32x32"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 32x32' }}
              onClick={() => this.props.setGridSize(32)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.grid ? 'white' : 'white',
                }}
              >
                32x32
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 48 ? '#555' : th.buttonColor,
              }}
              description="48x48"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 48x48' }}
              onClick={() => this.props.setGridSize(48)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.grid ? 'white' : 'white',
                }}
              >
                48x48
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 64 ? '#555' : th.buttonColor,
              }}
              description="64x64"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 64x64' }}
              onClick={() => this.props.setGridSize(64)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: !this.props.grid ? 'white' : 'white',
                }}
              >
                64x64
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 80 ? '#555' : th.buttonColor,
              }}
              description="80x80"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 80x80' }}
              onClick={() => this.props.setGridSize(80)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: !this.props.grid ? 'white' : 'white',
                }}
              >
                80x80
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: gridSize === 100 ? '#555' : th.buttonColor,
              }}
              description="100x100"
              position={{ marginTop: 25 }}
              track={{ category: 'Interface', action: 'Change grid size - 100x100' }}
              onClick={() => this.props.setGridSize(100)}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: !this.props.grid ? 'white' : 'white',
                }}
              >
                100x100
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }

  theme() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View>
        <View className={styles.buttons}>
          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.theme.current === THEME_TYPES.DARK_THEME ? '#555' : th.buttonColor,
              }}
              onClick={() => {
                this.props.selectDark();
              }}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.theme.current === THEME_TYPES.DARK_THEME ? 'white' : 'white',
                }}
              >
                DARK
              </Text>
            </Button>
          </View>

          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.theme.current === THEME_TYPES.LIGHT_THEME ? th.buttonColor : th.buttonColor,
              }}
              onClick={() => {
                this.props.selectLight();
              }}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.theme.current === THEME_TYPES.LIGHT_THEME ? 'white' : 'white',
                }}
              >
                LIGHT
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }

  resetApp() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View>
        <View className={styles.buttons}>
          <View className={styles.option}>
            <Button
              className={styles.optionSelector}
              style={{
                backgroundColor: this.props.theme.current === THEME_TYPES.DARK_THEME ? '#555' : th.buttonColor,
              }}
              onClick={() => {
                this.props.showOnboard();
              }}
            >
              <Text
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: this.props.theme.current === THEME_TYPES.DARK_THEME ? 'white' : 'white',
                }}
              >
                Show onboarding
              </Text>
            </Button>
          </View>
        </View>
      </View>
    );
  }

  renderOptions() {
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View
        style={{
          flexDirection: 'column',
          backgroundColor: th.panelBackground,
        }}
      >
        <ScrollView style={{ marginBottom: 100, overflowY: 'scroll', height: this.props.screen.orgH - 100 }}>
          <View className={styles.title} style={{ backgroundColor: th.sectionTitleBackground }}>
            <Text className={styles.titleText} style={{ color: th.sectionTitleColor }}>
              Main
            </Text>
          </View>
          <View className={styles.container}>{this.mainOptions()}</View>
          <View className={styles.title} style={{ backgroundColor: th.sectionTitleBackground }}>
            <Text className={styles.titleText} style={{ color: th.sectionTitleColor }}>
              FPS
            </Text>
          </View>
          <View className={styles.container}>{this.fps()}</View>
          <View className={styles.title} style={{ backgroundColor: th.sectionTitleBackground }}>
            <Text className={styles.titleText} style={{ color: th.sectionTitleColor }}>
              Onion Skinning
            </Text>
          </View>
          <View className={styles.container}>{this.onions()}</View>
          <View className={styles.title} style={{ backgroundColor: th.sectionTitleBackground }}>
            <Text className={styles.titleText} style={{ color: th.sectionTitleColor }}>
              Show add new frame prompt?
            </Text>
          </View>
          <View className={styles.container}>{this.framesPrompt()}</View>
          <View className={styles.title} style={{ backgroundColor: th.sectionTitleBackground }}>
            <Text className={styles.titleText} style={{ color: th.sectionTitleColor }}>
              Display Grid?
            </Text>
          </View>
          <View className={styles.container}>{this.grid()}</View>
          <View className={styles.title} style={{ backgroundColor: th.sectionTitleBackground }}>
            <Text className={styles.titleText} style={{ color: th.sectionTitleColor }}>
              Canvas Size
            </Text>
          </View>
          <View className={styles.container}>{this.gridSize()}</View>
        </ScrollView>
      </View>
    );
  }

  render() {
    return this.renderOptions();
  }
}

const mapState = (state) => ({
  currentFPS: state.settings.fps,
  onionPrev: state.settings.onionFramesPrev,
  onionNext: state.settings.onionFramesNext,
  onionLayers: state.settings.onionLayers,
  screen: state.settings.screen,
  grid: state.settings.showGrid,
  show: state.settings.showSettings,
  gridSize: state.settings.gridSize,
  showResetConfirm: state.settings.showResetConfirm,
  light: state.settings.lightGrid,
  theme: state.theme,
  showPrompt: state.settings.showAddFramePrompt,
});

const mapDispatch = (dispatch) => ({
  fps: (fps) => dispatch(setFPS(fps)),
  onOnionNext: () => dispatch(toggleOnionNext()),
  onOnionPrev: () => dispatch(toggleOnionPrev()),
  onOnionLayers: () => dispatch(toggleOnionLayers()),
  loadAnimation: (animation) => dispatch(loadData(animation)),
  setGrid: (bool) => dispatch(setGrid(bool)),
  setGridSize: (size) => dispatch(renderNewGridSize(size)),
  // setLightGrid: bool => dispatch(setLightGrid(bool)),
  reset: () => dispatch(appReset()),
  showReset: () => dispatch(showResetConfirm()),
  hideReset: () => dispatch(hideResetConfirm()),
  selectDark: () => {
    dispatch(selectDarkTheme());
  },
  selectLight: () => {
    dispatch(selectLightTheme());
  },
  showOnboard: () => {
    dispatch(triggerOnboarding());
  },
  enableShowFramePrompt: () => dispatch(enableShowFramePrompt()),
  disableShowFramePrompt: () => dispatch(disableShowFramePrompt()),
});

export default connect(mapState, mapDispatch)(Options);
