import React, { Component } from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/LoginView.module.scss';
import SignIn from './SignIn';
import Save from './Save';
import { requestCancel } from '../../store/actions/userActions';

class LoginAndSaveView extends Component {
  renderLoginContainer() {
    const { screen } = this.props;
    return (
      <View className={styles.container}>
        <SignIn />
        <Save />
        {this.props.show && (
          <Button
            className={styles.overlay}
            style={{ width: screen.orgW, height: screen.orgH }}
            onClick={() => {
              this.props.hide();
            }}
          />
        )}
      </View>
    );
  }

  render() {
    return this.renderLoginContainer();
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showLogin: state.user.showLoginScreen,
  showSave: state.data.showSaveView,
  show: state.user.showLoginScreen || state.data.showSaveView,
});

const mapDispatch = (dispatch) => ({
  hide: () => dispatch(requestCancel()),
});

export default connect(mapState, mapDispatch)(LoginAndSaveView);
