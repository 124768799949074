import { COMMON_TYPES, USER_TYPES, UI_TYPES } from '../actions/types';
import { mockMode, fakeState } from './../../utils/mocks';

const baseState = {
  init: false,
  layers: [],
  priorData: [],
  combinedRaw: [{ data: null }],
  gif: null,
  currentLayer: 0,
  currentFrame: 0,
  droppedIndex: null,
  loadingAnimation: false,
  showSaveView: false,
  animationUID: null,
  animation: {
    animationUID: null,
    name: null,
    thumb: null,
    isPublic: null,
  },
  changesSinceLastSave: 0,
  animationDetailsUID: null,
  showSetLayerOpacity: false,
  opacityLayerIndex: 0,
};

const initialState = mockMode ? JSON.parse(fakeState) : baseState;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_TYPES.APP_RESET: {
      return {
        ...initialState,
        init: true,
        layers: [{ pixelData: [[]], rawData: [[]] }],
        combinedRaw: [{ data: null }],
      };
    }
    case COMMON_TYPES.STORE_GENERATED_GIF: {
      return { ...state, gif: action.payload };
    }
    case COMMON_TYPES.SAVE_HISTORY: {
      return { ...state, init: true };
    }
    case COMMON_TYPES.UNDO: {
      const levels = JSON.parse(JSON.stringify(state.priorData));
      const prior = levels.pop();
      return { ...state, layers: prior, priorData: levels };
    }
    case COMMON_TYPES.REMOVE_LAST_UNDO: {
      return { ...state };
    }
    case COMMON_TYPES.ADD_NEW_FRAME: {
      const { clone, clickIndex } = action.payload;

      const tempCombined = [...state.combinedRaw];
      if (!tempCombined[state.currentFrame + 1]) {
        tempCombined.push({ data: null });
      } else if (tempCombined[state.currentFrame + 1] && tempCombined.length < state.layers[0].pixelData.length + 1) {
        tempCombined.splice(state.currentFrame + 1, 0, { data: null });
      }

      const tempLayers = [...state.layers];
      tempLayers.forEach((layer, layerIndex) => {
        if (!clone) {
          const toIndex = clickIndex ? clickIndex + 1 : state.currentFrame + 1;
          // add a new frame to every layer
          layer.pixelData.splice(toIndex, 0, []);
          layer.rawData.splice(toIndex, 0, []);
        } else {
          // add a clone frame to the current layer
          if (layerIndex === state.currentLayer) {
            layer.pixelData.splice(clickIndex, 0, clone.pixelData);
            layer.rawData.splice(clickIndex, 0, clone.rawData);
          } else {
            // add empty frames to other layers
            layer.pixelData.splice(clickIndex, 0, []);
            layer.rawData.splice(clickIndex, 0, []);
          }
        }
      });

      const changes = state.init ? state.changesSinceLastSave + 1 : 0;

      return {
        ...state,
        layers: tempLayers,
        combinedRaw: tempCombined,
        changesSinceLastSave: changes,
      };
    }
    case COMMON_TYPES.ADD_NEW_FRAME_ON_LAYER: {
      const { clone, layer, frameIndex } = action.payload;
      const tempLayers = [...state.layers];

      tempLayers[layer].pixelData.splice(frameIndex + 1, 0, clone.pixelData);
      tempLayers[layer].rawData.splice(frameIndex + 1, 0, clone.rawData);

      return {
        ...state,
        layers: tempLayers,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case COMMON_TYPES.ADD_NEW_LAYER: {
      let dummyFrames = 0;
      state.layers.forEach((layer) => {
        dummyFrames = Math.max(dummyFrames, layer.pixelData.length);
      });
      const tempLayers = [...state.layers];
      tempLayers.push({ pixelData: [], rawData: [] });
      tempLayers.forEach((layer) => {
        if (layer.pixelData.length < dummyFrames) {
          for (let i = 0; i < dummyFrames; i++) {
            layer.pixelData.push([]);
            layer.rawData.push([]);
          }
        }
      });

      return { ...state, layers: tempLayers };
    }
    case COMMON_TYPES.CLONE_CURRENT_FRAME_TO_REST: {
      const tempLayers = [...state.layers];
      const data = [...tempLayers[state.currentLayer].pixelData[state.currentFrame]];
      const totalFrames = tempLayers[state.currentLayer].rawData.length;

      for (let i = 0; i < totalFrames; i++) {
        tempLayers[state.currentLayer].pixelData[i] = data;
      }

      return { ...state, layers: tempLayers };
    }
    case COMMON_TYPES.SET_PIXELS: {
      const tempLayers = [...state.layers];
      tempLayers[state.currentLayer].pixelData[state.currentFrame] = action.payload;
      return {
        ...state,
        layers: tempLayers,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case COMMON_TYPES.UPDATE_LAYER_SNAPSHOT: {
      const tempCombined = [...state.combinedRaw];
      // console.log('tempCombined =', tempCombined)
      // console.log('index', state.currentFrame)
      // when loading an animation, the type ADD_NEW_FRAME doesn't get called why
      // we need to push slots to the stack
      if (!tempCombined[state.currentFrame] && state.loadingAnimation) {
        tempCombined.push({ data: action.payload || null });
      } else if (!tempCombined[state.currentFrame] && !state.loadingAnimation) {
        tempCombined.push({ data: action.payload || null });
      } else {
        const t = tempCombined[state.currentFrame];
        t.data = action.payload;
      }
      return { ...state, combinedRaw: tempCombined };
    }
    case COMMON_TYPES.UPDATE_FRAME_SNAPSHOT: {
      const tempLayers = [...state.layers];
      tempLayers[state.currentLayer].rawData[state.currentFrame] = action.payload;
      return { ...state, layers: tempLayers };
    }
    case COMMON_TYPES.SET_CURRENT_FRAME: {
      return { ...state, currentFrame: action.payload };
    }
    case COMMON_TYPES.CLONE_FRAME: {
      return { ...state, changesSinceLastSave: state.changesSinceLastSave + 1 };
    }
    case COMMON_TYPES.SELECT_LAYER: {
      return { ...state, currentLayer: action.payload };
    }
    case COMMON_TYPES.DELETE_LAYER: {
      const tempLayers = [...state.layers];
      tempLayers.splice(action.payload, 1);
      return {
        ...state,
        layers: tempLayers,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case COMMON_TYPES.DELETE_FRAME: {
      const tempLayers = [...state.layers];
      tempLayers.forEach((layer) => {
        layer.pixelData.splice(action.payload, 1);
        layer.rawData.splice(action.payload, 1);
      });
      const tempRaw = [...state.combinedRaw];
      tempRaw.splice(action.payload, 1);

      return {
        ...state,
        layers: tempLayers,
        combinedRaw: tempRaw,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case COMMON_TYPES.CLEAR_FRAME: {
      const tempLayers = [...state.layers];
      tempLayers[state.currentLayer].pixelData[action.payload] = [];
      tempLayers[state.currentLayer].rawData[action.payload] = [];

      const tempRaw = [...state.combinedRaw];
      tempRaw[state.currentFrame] = { data: null };

      return {
        ...state,
        layers: tempLayers,
        combinedRaw: tempRaw,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case COMMON_TYPES.LOAD_DATA: {
      return {
        ...initialState,
        animation: state.animation,
        loadingAnimation: true,
        layers: action.payload.data.layers,
      };
    }
    case COMMON_TYPES.REGENERATE_AFTER_DROP: {
      return {
        ...state,
        loadingAnimation: true,
      };
    }
    case COMMON_TYPES.REGENERATE_AFTER_DROP_COMPLETE: {
      return {
        ...state,
        loadingAnimation: false,
        droppedIndex: null,
      };
    }
    case COMMON_TYPES.LOAD_DATA_COMPLETE: {
      return {
        ...state,
        loadingAnimation: false,
      };
    }
    case UI_TYPES.SHOW_SAVE_VIEW: {
      return { ...state, showSaveView: true };
    }
    case COMMON_TYPES.CANCEL_SAVE: {
      return { ...state, showSaveView: false };
    }
    case UI_TYPES.HIDE_SAVE_VIEW: {
      return { ...state, showSaveView: false };
    }
    case COMMON_TYPES.SAVE_ANIMATION: {
      return {
        ...state,
        animation: {
          animationUID: action.payload.uid,
          name: action.payload.name,
          isPublic: action.payload.isPublic,
        },
        changesSinceLastSave: 0,
      };
    }
    case COMMON_TYPES.UPDATE_ANIMATION: {
      return { ...state, changesSinceLastSave: 0 };
    }
    case USER_TYPES.SIGN_IN_SUCCESS: {
      return { ...state, animationDetailsUID: null };
    }
    case USER_TYPES.SILENT_SIGN_IN_SUCCESS: {
      return { ...state, animationDetailsUID: null };
    }
    case USER_TYPES.SIGN_OUT_SUCCESS: {
      return {
        ...state,
        animation: {
          animationUID: null,
          name: null,
          thumb: null,
          animationDetailsUID: null,
          isPublic: null,
        },
      };
    }
    case COMMON_TYPES.SET_FPS: {
      return { ...state, changesSinceLastSave: state.changesSinceLastSave + 1 };
    }
    case COMMON_TYPES.FORCE_UNSAVED_CHANGE: {
      return { ...state, changesSinceLastSave: state.changesSinceLastSave + 1 };
    }
    case COMMON_TYPES.CLEAR_CHANGES_SINCE_LAST_SAVED: {
      return { ...state, changesSinceLastSave: 0 };
    }
    case COMMON_TYPES.SHOW_ANIMATION_DETAILS: {
      return { ...state, animationDetailsUID: action.payload };
    }
    case COMMON_TYPES.HIDE_ANIMATION_DETAILS: {
      return { ...state, animationDetailsUID: null };
    }
    case COMMON_TYPES.SAVE_CURRENT_DATA: {
      return { ...state, priorData: action.payload };
    }
    case UI_TYPES.MOVE_FRAME: {
      const temp = [...state.layers];
      const fromData = {
        pixelData: temp[state.currentLayer].pixelData[action.payload.dragged],
        rawData: temp[state.currentLayer].rawData[action.payload.dragged],
      };

      temp[state.currentLayer].pixelData.splice(action.payload.dragged, 1);
      temp[state.currentLayer].rawData.splice(action.payload.dragged, 1);

      temp[state.currentLayer].pixelData.splice(action.payload.dropped, 0, fromData.pixelData);
      temp[state.currentLayer].rawData.splice(action.payload.dropped, 0, fromData.rawData);

      return {
        ...state,
        layers: temp,
        droppedIndex: action.payload.dropped,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case UI_TYPES.MOVE_LAYER: {
      const temp = [...state.layers];
      const fromIndex = action.payload.dragged;
      const toIndex = action.payload.dropped;
      const swapedLayers = swap(fromIndex, toIndex)(temp);

      return {
        ...state,
        layers: swapedLayers,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case UI_TYPES.SHOW_LAYER_OPACITY: {
      return { ...state, showSetLayerOpacity: true };
    }
    case UI_TYPES.CLOSE_LAYER_OPACITY: {
      return { ...state, showSetLayerOpacity: false };
    }
    case UI_TYPES.SET_LAYER_OPACITY_INDEX: {
      return { ...state, opacityLayerIndex: action.payload };
    }
    case UI_TYPES.SET_LAYER_OPACITY: {
      const tempLayers = [...state.layers];
      const layerIndex = state.opacityLayerIndex;
      tempLayers[layerIndex].opacity = action.payload;
      return {
        ...state,
        layers: tempLayers,
        changesSinceLastSave: state.changesSinceLastSave + 1,
      };
    }
    case UI_TYPES.SET_BACKGROUND_COLOR: {
      return { ...state, changesSinceLastSave: state.changesSinceLastSave + 1 };
    }
    default:
  }

  return state;
}

const swap = (a, b) => (arr) => {
  const aux = (i, [x, ...xs]) => {
    if (x === undefined) {
      return [];
    }
    if (i === a) {
      return [arr[b], ...aux(i + 1, xs)];
    }
    if (i === b) {
      return [arr[a], ...aux(i + 1, xs)];
    }
    return [x, ...aux(i + 1, xs)];
  };
  return aux(0, arr);
};
