import React, { Component } from 'react';
import { View } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/RawCanvas.module.scss';
import { setRawCanvasRef } from '../../store/actions/canvasActions';

class RawCanvas extends Component {
  constructor(props) {
    super(props);
    this.rawCanvas = React.createRef();
  }

  componentDidMount() {
    this.props.setCanvasRef(this.rawCanvas.current);
  }

  render() {
    const { width } = this.props.screen;
    return (
      <View
        className={styles.container}
        style={{ left: (window.innerWidth - width) / 2, top: (window.innerHeight - width) / 2 }}
      >
        <View
          style={{
            left: (window.innerWidth - width) / 2,
            top: (window.innerHeight - 25 - width) / 2,
            width: width,
            height: width,
            maxWidth: width,
            maxHeight: width,
          }}
        >
          <canvas
            ref={this.rawCanvas}
            width={width}
            height={width}
            style={{ transform: `scale(${1.0 - this.props.scale})`, marginLeft: 0, marginTop: 0 }}
          />
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  scale: state.settings.scaleOffset,
  data: state.data,
  activeTool: state.tools.activeTool,
  activeColor: state.tools.activeColor,
  settings: state.settings,
});

export const mapDispatch = (dispatch) => ({
  setCanvasRef: (ref) => dispatch(setRawCanvasRef(ref)),
});

export default connect(mapState, mapDispatch)(RawCanvas);
