import React from 'react';
import { View, Button, Image } from '../BaseComponents';
import { connect } from 'react-redux';
import Camera from './Camera';
import { toggleExpand } from '../../store/actions/uiActions';
import { shouldBlurForAnimationView } from '../../store/selectors';
import styles from '../styles/AnimationView.module.scss';
import Animation from './Animation';
import AreaAnimationOverlay from './AreaAnimationOverlay'
import AnimationControls from './AnimationControls';
import { zoomCamera } from '../../store/actions/cameraActions';
import * as assets from '../../config/assets';

class AnimationView extends React.Component {
  constructor(props) {
    super(props);
    this.btnSize = '60%';
  }

  getPosX() {
    const rightPos = this.props.screen.orgW / 2 + this.props.screen.width / 2;
    return (this.props.screen.orgW - rightPos) / 2 - 75;
  }

  getPosY() {
    const topPos = (this.props.screen.orgH - 25) / 2 - this.props.screen.width / 2;
    return topPos > 100 ? topPos : 100;
  }

  getPosZoomY() {
    const topPos = (this.props.screen.orgH - 25) / 2 - this.props.screen.width / 2;
    const pos = topPos + 230;
    return pos > 300 ? pos : 330;
  }

  renderZoomOut() {
    return (
      <Button
        id="zoomOut"
        disabled={this.props.zoom === 1}
        onClick={() => (this.props.zoom > 1 ? this.props.zoomCamera('out') : {})}
        description="zoom out"
        track={{ category: 'Interface', action: 'Zoom out' }}
        style={{
          width: 50,
          alignItems: 'center',
        }}
      >
        <Image
          src={assets.zoomLess}
          style={{
            width: this.btnSize,
            height: this.btnSize,
            opacity: this.props.zoom > 1 ? 1 : 0.3,
          }}
        />
      </Button>
    );
  }
  renderZoomIn() {
    return (
      <Button
        id="zoomIn"
        disabled={this.props.zoom >= this.props.maxZoom}
        onClick={() => {
          this.props.zoomCamera('in');
        }}
        description="zoom in"
        track={{ category: 'Interface', action: 'Zoom in' }}
        style={{
          width: 50,
          alignItems: 'center',
        }}
      >
        <Image
          src={assets.zoomMore}
          style={{
            width: this.btnSize,
            height: this.btnSize,
            opacity: this.props.zoom < 3 ? 1 : 0.3,
          }}
        />
      </Button>
    );
  }

  render() {
    const { expanded, screen, blur, backgroundColor } = this.props;
    const expandedSize = 600;
    return (
      <View
        style={{
          position: 'absolute',
          zIndex: 300002,
          overflow: 'visible',
          width: this.props.screen.orgW,
        }}
      >
        <>
          <View
            className={styles.zoomView}
            style={{
              top: expanded ? screen.orgH / 2 - expandedSize / 2 : this.getPosZoomY(),
              right: expanded ? screen.orgW / 2 - expandedSize / 2 : this.getPosX(),
              filter: blur ? 'blur(5px)' : 'blur(0px)',
            }}
          >
            {this.renderZoomOut()}
            {this.renderZoomIn()}
          </View>
          <View
            className={styles.animationView}
            style={{
              top: expanded ? screen.orgH / 2 - expandedSize / 2 : this.getPosY(),
              right: expanded ? screen.orgW / 2 - expandedSize / 2 : this.getPosX(),
              width: expanded ? expandedSize : 150,
              height: expanded ? expandedSize : 230,
              overflow: 'hidden',
              filter: blur ? 'blur(5px)' : 'blur(0px)',
              background: backgroundColor !== 'transparent' ? backgroundColor : 'rgb(37, 37, 37)',
            }}
          >
            <AreaAnimationOverlay zoom={this.props.zoom} expanded={expanded} gridSize={this.props.gridSize} areaDefineActive={this.props.areaDefineActive} xA={this.props.xA} xB={this.props.xB} yA={this.props.yA} yB = {this.props.yB}/>
            <Camera />
            <Animation />

            <View
              style={{
                position: 'absolute',
                zIndex: 50002,
                marginTop: 0,
              }}
            >
              <AnimationControls />
            </View>
          </View>
        </>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  expanded: state.settings.expanded,
  blur: shouldBlurForAnimationView(state),
  zoom: state.settings.zoom,
  showColors: state.tools.showColors,
  backgroundColor: state.settings.backgroundColor,
  areaDefineActive: state.settings.areaDefineActive,
  xA: state.settings.xA,
  xB: state.settings.xB,
  yA: state.settings.yA,
  yB: state.settings.yB,
  gridSize: state.settings.gridSize
});

const mapDispatch = (dispatch) => ({
  toggleExpand: () => dispatch(toggleExpand()),
  zoomCamera: (args) => dispatch(zoomCamera(args)),
});

export default connect(mapState, mapDispatch)(AnimationView);
