import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hideFrames } from '../../store/actions/uiActions';
import styles from '../styles/FramesView.module.scss';
import FrameContainer from './FrameContainer';
import { View, Button } from '../BaseComponents';

class FramesView extends Component {
  renderFrameContainer() {
    return (
      <View className={styles.container}>
        {/* FRAMES */}
        <FrameContainer />
        {/* OVERLAY */}
        {this.props.show && (
          <Button
            className={styles.overlay}
            style={{
              backgroundColor: this.props.theme.themes[this.props.theme.current].overlay,
            }}
            onClick={() => {
              this.props.hideFrames();
            }}
          />
        )}
      </View>
    );
  }

  render() {
    return this.renderFrameContainer();
  }
}

const mapState = (state) => ({
  show: state.settings.showFrames,
  expanded: state.settings.expanded,
  theme: state.theme,
});

const mapDispatch = (dispatch) => ({
  hideFrames: () => dispatch(hideFrames()),
});

export default connect(mapState, mapDispatch)(FramesView);
