import { COMMON_TYPES, USER_TYPES, UI_TYPES } from '../actions/types/';
import { mockMode, fakeAnimationList, fakeUser } from './../../utils/mocks';

const fakeSignInState = {
  signedIn: true,
  onboarded: true,
  onboardNum: 0,
  user: fakeUser,
  animationList: JSON.parse(fakeAnimationList),
  showLoginScreen: false,
  signInAndSave: false,
  showProfileAfterSignIn: false,
  signingIn: false,
  signingOut: false,
  sip: null,
  autoSignedIn: false,
};

const baseState = {
  signedIn: false,
  onboarded: false,
  onboardNum: 0,
  user: null,
  animationList: [],
  showLoginScreen: false,
  signInAndSave: false,
  showProfileAfterSignIn: false,
  signingIn: false,
  signingOut: false,
  sip: null,
  autoSignedIn: false,
};

const initialState = mockMode ? fakeSignInState : baseState;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USER_TYPES.SET_SIP: {
      return {
        ...state,
        sip: action.payload,
      };
    }
    case USER_TYPES.SHOW_LOGIN_SCREEN: {
      return {
        ...state,
        showLoginScreen: true,
      };
    }
    case USER_TYPES.HIDE_LOGIN_SCREEN: {
      return {
        ...state,
        showLoginScreen: false,
      };
    }
    case UI_TYPES.ONBOARDED: {
      return {
        ...state,
        onboarded: true,
      };
    }
    case UI_TYPES.TRIGGER_ONBOARDING: {
      return {
        ...state,
        onboardNum: state.onboardNum + 1,
      };
    }
    case USER_TYPES.SIGN_IN: {
      return {
        ...state,
        signingIn: true,
      };
    }
    case USER_TYPES.SIGN_IN_SUCCESS: {
      return {
        ...state,
        signedIn: true,
        signingIn: false,
        user: action.payload,
      };
    }
    case USER_TYPES.SILENT_SIGN_IN_SUCCESS: {
      return {
        ...state,
        signedIn: true,
        signingIn: false,
        user: action.payload,
        autoSignedIn: true,
      };
    }
    case USER_TYPES.SIGN_OUT: {
      return {
        ...state,
        signingOut: true,
      };
    }
    case USER_TYPES.SIGN_OUT_SUCCESS: {
      return {
        ...state,
        signedIn: false,
        signingOut: false,
        user: null,
        animationList: [],
        showProfileAfterSignIn: false,
      };
    }
    case USER_TYPES.SIGN_IN_AND_SAVE: {
      return {
        ...state,
        signInAndSave: true,
      };
    }
    case UI_TYPES.HIDE_SAVE_VIEW: {
      return { ...state, signInAndSave: false, showLoginScreen: false };
    }
    case COMMON_TYPES.CANCEL_SAVE: {
      return {
        ...state,
        showLoginScreen: false,
        signInAndSave: false,
        showProfileAfterSignIn: false,
      };
    }
    case UI_TYPES.SHOW_PROFILE_AFTER_SIGN_IN: {
      return { ...state, showProfileAfterSignIn: true };
    }
    case UI_TYPES.SHOW_SETTINGS_VIEW: {
      let show;
      if (state.showLoginScreen) {
        show = state.showProfileAfterSignIn ? false : true;
      } else {
        show = false;
      }
      return {
        ...state,
        showLoginScreen: show,
        showProfileAfterSignIn: false,
        signInAndSave: false,
      };
    }
    case COMMON_TYPES.UPDATE_ANIMATION_LIST: {
      return { ...state, animationList: action.payload };
    }
    case COMMON_TYPES.SET_ANIMATION_PALETTE: {
      return { ...state, animationPalette: action.payload };
    }
    case UI_TYPES.DELETE_ANIMATION: {
      return { ...state, animationList: [] };
    }
    case COMMON_TYPES.APP_RESET: {
      return { ...state, animationList: [] };
    }
    default:
  }

  return state;
}
