import React from 'react';
import { View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/BackgroundSelector.module.scss';
import { shouldBlur } from '../../store/selectors';
import { setTransparentBackground } from '../../store/actions/toolsActions';

class BackgroundOpacitySelector extends React.Component {
  constructor(props) {
    super(props);
    this.btnSize = 25;
  }

  render() {
    const { blur, showBackgroundColorSelector, screen, backgroundColor } = this.props;
    const l = showBackgroundColorSelector
      ? screen.orgW / 2 + screen.width / 2 + 40
      : screen.orgW / 2 + screen.width / 2;

    return (
      <View
        className={styles.container}
        style={{
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          width: this.props.screen.width + 100,
          left: l,
          top: (this.props.screen.orgH - 25) / 2 - this.props.screen.width / 2 + 42,
          filter: blur ? 'blur(5px)' : 'blur(0px)',
          zIndex: 2000000,
        }}
      >
        <Button
          className={styles.quickNavBtn}
          style={{
            backgroundColor: '#222',
            pointerEvents: backgroundColor === 'transparent' ? 'none' : 'auto',
            opacity: backgroundColor === 'transparent' ? 0.3 : 1,
          }}
          description={'choose transparent background'}
          position={{ marginLeft: -25, marginTop: 25 }}
          track={{ category: 'Interface', action: 'Set background to transparent' }}
          onClick={() => {
            // console.log('click selector');
            this.props.setTransparentBackground();
          }}
        >
          <div className={styles.divColors}>
            <div className={styles.divTrans1}></div>
            <div className={styles.divTrans2}></div>
            <div className={styles.divTrans1}></div>
          </div>
        </Button>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showColors: state.tools.showColors,
  colors: state.tools.userColors,
  blur: shouldBlur(state),
  data: state.data,
  currentFrame: state.data.currentFrame,
  totalFrames: state.data.layers.length > 0 ? state.data.layers[state.data.currentLayer].pixelData.length : 1,
  theme: state.theme,
  showFramePrompt: state.settings.showAddFramePrompt,
  showBackgroundColorSelector: state.settings.showBackgroundColorSelector,
  backgroundColor: state.settings.backgroundColor,
});

const mapDispatch = (dispatch) => ({
  setTransparentBackground: () => dispatch(setTransparentBackground()),
});

export default connect(mapState, mapDispatch)(BackgroundOpacitySelector);
