import { COMMON_TYPES, UI_TYPES } from './types';
import { generateSpriteData } from './canvasActions';
import { setGrid } from './uiActions';

export const selectClone = () => ({ type: COMMON_TYPES.SELECT_CLONE });
export const selectMove = () => ({ type: COMMON_TYPES.SELECT_MOVE });
export const showColors = () => ({ type: UI_TYPES.SHOW_COLORS });
export const hideColors = () => ({ type: UI_TYPES.HIDE_COLORS });
export const selectDraw = () => ({ type: COMMON_TYPES.SELECT_DRAW });
export const selectSquare = () => ({ type: COMMON_TYPES.SELECT_SQUARE });
export const selectLine = () => ({ type: COMMON_TYPES.SELECT_LINE });
export const selectFill = () => ({ type: COMMON_TYPES.SELECT_FILL });
export const setBrush = (val) => ({ type: COMMON_TYPES.SET_BRUSH, payload: val });
export const undo = () => (dispatch) => {
  dispatch({ type: COMMON_TYPES.UNDO });
  dispatch(generateSpriteData());
};

export const selectEraser = () => (dispatch) => {
  dispatch({ type: COMMON_TYPES.SELECT_ERASER });
  dispatch(setColor('transparent'));
};

export const setColor = (color) => ({
  type: COMMON_TYPES.SET_COLOR,
  payload: color,
});
export const selectColorPicker = () => ({
  type: COMMON_TYPES.SELECT_COLORPICKER,
});

export const selectColor = (color) => (dispatch) => {
  dispatch({ type: COMMON_TYPES.SELECT_COLOR, payload: color });
  dispatch(selectDraw());
};

export const selectBackgroundColor = (color) => (dispatch) => {
  dispatch({ type: UI_TYPES.SET_BACKGROUND_COLOR, payload: color });
  dispatch(hideBackgroundSelector());
};

export const showBackgroundSelector = () => (dispatch) => {
  dispatch({ type: UI_TYPES.SHOW_BACKGROUND_COLOR_SELECTOR });
};

export const hideBackgroundSelector = () => (dispatch) => {
  dispatch({ type: UI_TYPES.HIDE_BACKGROUND_COLOR_SELECTOR });
};

export const clearBackground = () => (dispatch) => {
  dispatch({ type: UI_TYPES.CLEAR_BACKGROUND_COLOR });
};

export const setTransparentBackground = () => (dispatch) => {
  dispatch({ type: UI_TYPES.CLEAR_BACKGROUND_COLOR });
  dispatch(setGrid(true));
};

export const setPalette = (palette) => (dispatch, getState) => {
  if (getState().settings.palette !== palette) {
    dispatch({ type: COMMON_TYPES.SET_PALETTE, payload: palette });
  } else {
    dispatch({ type: UI_TYPES.SHOW_COLORS });
  }
};

export const setAnimationPalette = (palette) => (dispatch) => {
  dispatch({ type: COMMON_TYPES.SET_ANIMATION_PALETTE, payload: palette });
};

export const loadColors = (colors) => ({
  type: COMMON_TYPES.LOAD_COLORS,
  payload: colors,
});

export const selectMirror = () => ({ type: COMMON_TYPES.SELECT_MIRROR });

export const selectMirrorX = () => ({ type: COMMON_TYPES.SELECT_MIRROR_X });
export const selectMirrorY = () => ({ type: COMMON_TYPES.SELECT_MIRROR_Y });
