import React from 'react';
import { View, Button, Image } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/FrameQuickNav.module.scss';
import * as assets from '../../config/assets';
import { shouldBlur } from '../../store/selectors';
import { setQuickNavFrame, showFrameDialog, addFrameConfirm } from '../../store/actions/uiActions';
class FrameQuickNav extends React.Component {
  constructor(props) {
    super(props);
    this.btnSize = 25;
  }

  render() {
    const { blur, showBackgroundColorSelector } = this.props;
    return (
      <View
        className={styles.container}
        style={{
          pointerEvents: 'none',
          backgroundColor: 'transparent',
          width: this.props.screen.width + 100,
          left: this.props.screen.orgW / 2 - this.props.screen.width / 2 - 50,
          top: (this.props.screen.orgH - 25) / 2 + this.props.screen.width / 2 - 60,
          filter: blur ? 'blur(5px)' : 'blur(0px)',
        }}
      >
        <Button
          disabled={this.props.currentFrame === 0}
          className={styles.quickNavBtn}
          style={{ backgroundColor: '#111111', pointerEvents: 'auto', opacity: this.props.currentFrame > 0 ? 1 : 0.5, left: this.props.areaControlActive ? -20 : 0 }}
          onClick={() => this.props.setFrame(this.props.currentFrame - 1)}
          track={{ category: 'Interface', action: 'Quick frame - previous' }}
          description="go to previous frame (q)"
          position={{ marginLeft: -35, marginTop: 25 }}
        >
          <Image
            src={assets.qn_prev}
            style={{
              width: this.btnSize,
              height: this.btnSize,
              opacity: this.props.currentFrame > 0 ? 1 : 0.25,
            }}
          />
        </Button>

        <Button
          className={styles.quickNavBtn}
          style={{
            backgroundColor: this.props.currentFrame >= this.props.totalFrames - 1 ? '#222' : '#111',
            pointerEvents: 'auto',
            position: 'relative',
            left: showBackgroundColorSelector ? 40 : 0,
          }}
          description={
            this.props.currentFrame >= this.props.totalFrames - 1 ? 'add a new frame (w)' : 'go to next frame (w)'
          }
          position={{ marginLeft: -25, marginTop: 25 }}
          track={{ category: 'Interface', action: 'Quick frame - next' }}
          onClick={() => {
            if (this.props.currentFrame >= this.props.totalFrames - 1) {
              if (this.props.showFramePrompt) {
                this.props.showFrameDialog();
              } else {
                this.props.addFrame();
              }
            } else {
              this.props.setFrame(this.props.currentFrame + 1);
            }
          }}
        >
          <Image
            src={this.props.currentFrame >= this.props.totalFrames - 1 ? assets.quickAdd : assets.qn_next}
            style={{
              width: this.btnSize,
              height: this.btnSize,
              opacity: 1,
            }}
          />
        </Button>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showColors: state.tools.showColors,
  colors: state.tools.userColors,
  blur: shouldBlur(state),
  data: state.data,
  currentFrame: state.data.currentFrame,
  totalFrames: state.data.layers.length > 0 ? state.data.layers[state.data.currentLayer].pixelData.length : 1,
  theme: state.theme,
  showFramePrompt: state.settings.showAddFramePrompt,
  showBackgroundColorSelector: state.settings.showBackgroundColorSelector,
  areaControlActive: state.settings.areaControlActive
});

const mapDispatch = (dispatch) => ({
  setFrame: (frame) => dispatch(setQuickNavFrame(frame)),
  showFrameDialog: () => dispatch(showFrameDialog()),
  addFrame: () => dispatch(addFrameConfirm()),
});

export default connect(mapState, mapDispatch)(FrameQuickNav);
