import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import credentials from './../firebase.credentials';
import AsyncStorage from '@callstack/async-storage';

const SignInProvider = class SIP {
  static async setup() {
    if (!SignInProvider.initialized) {
      SignInProvider.provider = new Firebase.auth.GoogleAuthProvider();
      Firebase.initializeApp({
        ...credentials,
      });
      SignInProvider.initialized = true;
    }
  }

  static asyncStorageKey = 'access_token';

  getFirebaseRefreshToken = (refreshToken) => {
    // console.log('getFirebaseRefreshToken', refreshToken);

    const endpoint = 'https://securetoken.googleapis.com/v1/token';
    const grantType = 'refresh_token';

    return fetch(
      `${endpoint}?key=${credentials.apiKey}
    &grant_type=${grantType}&refresh_token=${refreshToken}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
  };

  async getToken() {
    try {
      const key = SignInProvider.asyncStorageKey;
      const token = await AsyncStorage.getItem(key);
      // console.log('get token', token); // eslint-disable-line
      return token;
    } catch (error) {
      // console.log('getToken - error', error); // eslint-disable-line
      return null;
    }
  }

  async setTokenAsync(token) {
    try {
      // console.log('get token async'); // eslint-disable-line
      await AsyncStorage.setItem('access_token', token);
      this.getToken();
    } catch (error) {
      // console.log('setToken - error', error); // eslint-disable-line
    }
  }

  async clearToken() {
    try {
      const key = SignInProvider.asyncStorageKey;
      await AsyncStorage.removeItem(key, () => {
        // console.log(`item key:${key} removed`); // eslint-disable-line
      });
    } catch (error) {
      // console.log('setToken - error', error); // eslint-disable-line
    }
  }

  static handleUserSignin(userData) {
    return new Promise((resolve, reject) => {
      const firebaseDatabaseRef = Firebase.database();
      if (userData.additionalUserInfo.isNewUser) {
        firebaseDatabaseRef.ref(`${'users/'}${userData.user.uid}`).set({
          id: userData.user.uid,
          name: userData.user.displayName,
          email: userData.user.email,
          photo: userData.user.photoURL,
        });

        firebaseDatabaseRef
          .ref('metadata')
          .once('value')
          .then((snapshot) => {
            const total = snapshot.val().total_users;

            firebaseDatabaseRef
              .ref('metadata')
            .update({ total_users: total + 1 }) // eslint-disable-line
              .then(() => {
                // console.log("update total users - success");
              })
              .catch(() => {
                // console.log("update total users - failure");
              });
          })
          .catch((error) => {
            // console.log("read total users - failure");
            reject(error);
          });

        resolve({ type: 'new' });
      } else {
        firebaseDatabaseRef.ref(`${'users/'}${userData.user.uid}`).update({
          photo: userData.user.photoURL,
        });
        // old user
        resolve({ type: 'old' });
      }
    });
  }

  signInWithGoogle(onGoogleLoginSuccess, onFirebaseLoginSuccess, onLoginError) {
    Firebase.auth()
      .signInWithPopup(SignInProvider.provider)
      .then((data) => {
        // add user to state so we can display the avatar
        onGoogleLoginSuccess(data);

        const credential = Firebase.auth.GoogleAuthProvider.credential(
          data.credential.idToken,
          data.credential.accessToken
        );

        // login with credential
        return Firebase.auth().signInWithCredential(credential);
      })
      .then((firebaseResponse) => {
        // const isOldUser = SignInProvider.handleUserSignin(currentUser).type === 'old';
        // //  console.log('current user', currentUser);
        // onFirebaseLoginSuccess(currentUser, isOldUser);
        SignInProvider.handleUserSignin(firebaseResponse)
          .then(async (obj) => {
            const isOldUser = obj.type === 'old';
            // let component do its thing
            onFirebaseLoginSuccess(firebaseResponse, isOldUser);
          })
          .catch((error) => {
            onLoginError(error);
          });
      })
      .catch((err) => {
        // console.log('WRONG SIGNIN', err)
        onLoginError(err);
      });
  }

  signOut(onGoogleSignOutSuccess, onGoogleSignOutError) {
    Firebase.auth()
      .signOut()
      .then(() => {
        // console.log("firebase signout, google signout");
        onGoogleSignOutSuccess();
      })
      .catch((err) => onGoogleSignOutError(err));
  }

  getFirebaseRef() {}
};

export default SignInProvider;
