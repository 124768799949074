export const normalizePixels = (pixels) => {
  const normalized = [];
  pixels &&
    pixels.forEach((p) => {
      if (p.shape) {
        normalized.push(...extractPixelsFromShape(p.shape));
      } else {
        normalized.push(p);
      }
    });

  return normalized;
};

const extractPixelsFromShape = (shape) => {
  const h = shape.b + 1 - shape.a;
  const v = shape.d + 1 - shape.c;
  const set = [];
  for (let x = shape.a; x < shape.a + h; x++) {
    for (let y = shape.c; y < shape.c + v; y++) {
      set.push({ color: shape.e, x, y });
    }
  }

  return set;
};

// --------------------- test normalizePixels --------------------- //
/* eslint-disable */
const test = [
  {
    shape: {
      a: 10,
      b: 12,
      c: 10,
      d: 14,
      e: '#8BC34A',
    },
  },
  {
    color: '#8BC34A',
    x: 10,
    y: 23,
  },
  {
    color: '#8BC34A',
    x: 11,
    y: 23,
  },
  {
    color: '#8BC34A',
    x: 12,
    y: 23,
  },
]

const testnormalizePixels = false

if (testnormalizePixels) {
  normalizePixels(test)
}
/* eslint-enable */
