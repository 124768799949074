export const brushLarge = (points) => {
  const modifiedPoints = [];
  points.forEach((p) => {
    Array.prototype.push.apply(modifiedPoints, [
      { x: p.x - 1, y: p.y - 1 },
      { x: p.x, y: p.y - 1 },
      { x: p.x + 1, y: p.y - 1 },
      { x: p.x - 1, y: p.y },
      { x: p.x, y: p.y },
      { x: p.x + 1, y: p.y },
      { x: p.x - 1, y: p.y + 1 },
      { x: p.x, y: p.y + 1 },
      { x: p.x + 1, y: p.y + 1 },
    ]);
  });
  return modifiedPoints;
};

export const brushTiltDu = (points) => {
  const modifiedPoints = [];
  points.forEach((p) => {
    Array.prototype.push.apply(modifiedPoints, [
      { x: p.x - 1, y: p.y + 1 },
      { x: p.x, y: p.y },
      { x: p.x + 1, y: p.y - 1 },
    ]);
  });
  return modifiedPoints;
};

export const brushTiltUd = (points) => {
  const modifiedPoints = [];
  points.forEach((p) => {
    Array.prototype.push.apply(modifiedPoints, [
      { x: p.x - 1, y: p.y - 1 },
      { x: p.x, y: p.y },
      { x: p.x + 1, y: p.y + 1 },
    ]);
  });
  return modifiedPoints;
};
