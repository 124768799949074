import React, { Component } from 'react';
import { View } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Tools.module.scss';
import layout from '../../config/layout';
import MainTools from './MainTools';
import SubTools from './SubTools';
import { shouldBlur } from '../../store/selectors';

import {
  selectColorPicker,
  selectEraser,
  selectMirror,
  selectFill,
  selectMove,
  selectClone,
  selectSquare,
  selectLine,
  undo,
} from '../../store/actions/toolsActions';

import { startAnimation, stopAnimation } from '../../store/actions/animationActions';

class Tools extends Component {
  selectMoveTool = () => {
    this.props.selectMove();
  };

  selectCloneTool = () => {
    this.props.selectClone();
  };

  selectSquareTool = () => {
    this.props.selectSquare();
  };

  selectLineTool = () => {
    this.props.selectLine();
  };

  fill = () => {
    this.props.selectFill();
  };

  copyColor = () => {
    this.props.selectColorPicker();
  };

  mirror = () => {
    this.props.mirror();
  };

  undo = () => {
    this.props.undo();
  };

  erase = () => {
    this.props.selectEraser();
  };

  render() {
    const { blur, screen, docked, showColors } = this.props;
    let yPos, xPos, toolHeight;
    if (!docked) {
      xPos = screen.orgW / 2 - screen.width / 2 - 80;
      yPos = (screen.orgH - 25) / 2 - screen.width / 2 > 100 ? (screen.orgH - 25) / 2 - screen.width / 2 : 100;
      toolHeight = screen.width - 310;
    } else {
      xPos = showColors ? 290 : 250;
      yPos = 240;
      toolHeight = screen.orgH - 541;
    }
    return (
      <View
        className={styles.container}
        style={{
          filter: blur ? 'blur(5px)' : 'blur(0px)',
          top: yPos + 80,
          left: xPos,
        }}
      >
        <View className={styles.direction}>
          <MainTools dimensions={layout.dimensions} />
          <SubTools
            expanded={this.props.expanded}
            copyColor={this.copyColor}
            undo={this.props.undo}
            mirror={this.mirror}
            height={toolHeight}
            undoEnabled={this.props.data.priorData.length > 0}
            fill={this.fill}
            selectSquareTool={this.selectSquareTool}
            selectLineTool={this.selectLineTool}
            selectMoveTool={this.selectMoveTool}
            selectCloneTool={this.selectCloneTool}
            erase={this.erase}
            dimensions={layout.dimensions}
            activeButton={this.props.tools.activeTool}
            activeColor={this.props.tools.activeColor}
            marginRight={10}
          />
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  tools: state.tools,
  data: state.data,
  screen: state.settings.screen,
  expanded: state.settings.expanded,
  animate: state.settings.animate,
  theme: state.theme,
  zoom: state.settings.zoom,
  blur: shouldBlur(state),
  docked: state.settings.dockFrames,
  undoEnabled: state.data.priorData.length > 0,
  showColors: state.tools.showColors,
});

const mapDispatch = (dispatch) => ({
  selectColorPicker: () => dispatch(selectColorPicker()),
  undo: () => dispatch(undo()),
  selectEraser: () => dispatch(selectEraser()),
  selectFill: () => dispatch(selectFill()),
  selectMove: () => dispatch(selectMove()),
  selectClone: () => dispatch(selectClone()),
  mirror: () => dispatch(selectMirror()),
  play: () => dispatch(startAnimation()),
  stop: () => dispatch(stopAnimation()),
  selectSquare: () => dispatch(selectSquare()),
  selectLine: () => dispatch(selectLine()),
});

export default connect(mapState, mapDispatch)(Tools);
