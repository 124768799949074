// import Worker from 'worker-loader!../../workers/gifGenWorker.js';
// import * as Comlink from 'comlink';

import { COMMON_TYPES, UI_TYPES } from './types';
import { getUsedColors } from '../../utils/colors';
import { loadColors } from './toolsActions';
import {
  addLayer,
  addFrame,
  hideFrames,
  showFrames,
  getRemainingLayers,
  setCurrentFrame,
  setLayerOpacityIndex,
} from './uiActions';
import { saveAnimation } from './animationActions';
import { mockMode } from '../../utils/mocks/index';
import {
  updateDrawCanvas,
  updateRAWFrameCanvas,
  updateRAWCanvas,
  generateSpriteData,
  updateBgCanvas,
  saveCurrentPixelData,
  generateGIF,
  centerFrameContent,
  clearArea,
} from './canvasActions';

export const init = () => (dispatch) => {
  if (!mockMode) {
    dispatch(addLayer());
    dispatch(
      addFrame(null, () => {
        dispatch({ type: COMMON_TYPES.INIT });
      })
    );
  }
};

export const appReset = () => (dispatch) => {
  dispatch({ type: COMMON_TYPES.APP_RESET });
  dispatch(updateDrawCanvas());
  dispatch(updateRAWFrameCanvas(null, null));
  dispatch(updateRAWCanvas(null, null));
};

export const generateGIFAnimation = () => async (dispatch, getState) => { // eslint-disable-line
  dispatch(generateGIF());
};

// export const saveGIFAnimation = buffer => () => saveGIF(buffer);

export const cloneCurrentFrameToRest = () => (dispatch, getState) => {
  const data = getState().data;
  const { currentFrame, currentLayer, layers } = data;
  const pixels = layers[currentLayer].pixelData[currentFrame];

  const layersCopy = JSON.stringify(layers);
  dispatch(saveCurrentPixelData(JSON.parse(layersCopy)));

  dispatch({ type: COMMON_TYPES.CLONE_CURRENT_FRAME_TO_REST, payload: pixels });

  dispatch(generateSpriteData());

  const layerData = dispatch(getRemainingLayers());
  dispatch(updateData(layerData, false, false));

  dispatch({ type: COMMON_TYPES.FORCE_UNSAVED_CHANGE });
};

export const renderNewGridSize = (size, fullLoad = false) => (dispatch, getState) => {
  if (getState().data.layers[0].pixelData[0].length > 0) {
    // ask to center all frames when changing resolution
    // dispatch(promptCenterFrames());
  }

  dispatch(setGridSize(size));

  const layerData = dispatch(getRemainingLayers());
  dispatch(updateData(layerData, false, fullLoad, !fullLoad));

  if (!fullLoad) {
    dispatch({ type: COMMON_TYPES.FORCE_UNSAVED_CHANGE });
  }

  dispatch(updateBgCanvas());
};

const promptCenterFrames = () => (dispatch) => { // eslint-disable-line
  // const layers = getState().data.layers;
  // const totalFrames = layers[0].pixelData.length;
  const list = [];
  let steps = 0;
  const current = 1;
  const layerData = dispatch(getRemainingLayers());
  const colors = getUsedColors(layerData.layers);
  layerData.layers.forEach((layer, layerIndex) => {
    layer.pixelData.forEach((frame, frameIndex) => {
      list.push({
        layer: {
          index: layerIndex,
          data: layer,
          total: layerData.layers.length,
        },
        frame: { index: frameIndex, data: frame, total: layer.length },
      });
      steps += 1;
    });
  });

  dispatch(handleList(list, steps, current, colors, false, false, true));
}; // eslint-disable-line

export const setGridSize = (size) => (dispatch) => {
  dispatch({
    type: COMMON_TYPES.SET_GRIDSIZE,
    payload: size,
  });

  dispatch(clearArea());
};

export const loadData = (data, uid, isPublic) => (dispatch, getState) => {
  const layerData = JSON.parse(data.layerData);

  if (getState().user.signedIn) {
    dispatch(saveAnimation({ uid, name: data.name, isPublic }));
  }

  const size = data.gridSize || 48;
  const { gridSize } = getState().settings;

  if (size !== gridSize) {
    dispatch(setGridSize(size));
    dispatch(updateBgCanvas());
  }

  const background = data.background ? data.background : 'transparent';
  dispatch({ type: UI_TYPES.SET_BACKGROUND_COLOR, payload: background });

  dispatch({
    type: COMMON_TYPES.LOAD_DATA,
    payload: { data: layerData, public: isPublic },
  });

  dispatch({
    type: COMMON_TYPES.SET_FPS,
    payload: data.fps,
  });

  dispatch(renderNewGridSize(size, true));
};

const handleList = (
  list,
  steps,
  current,
  colors = null,
  isDragDropping = false,
  fullLoad = false,
  centerContent = false
) => async (dispatch, getState) => {
  const layer = list[0].layer;
  const frame = list[0].frame;

  dispatch(setLayerOpacityIndex(layer.index));
  dispatch({ type: UI_TYPES.SET_LAYER_OPACITY, payload: layer.data.opacity });

  dispatch({ type: COMMON_TYPES.SELECT_LAYER, payload: layer.index });
  dispatch(setCurrentFrame(frame.index));

  const payload = isDragDropping
    ? `UPDATING LAYER: ${layer.index + 1} / FRAME: ${frame.index + 1}`
    : `GENERATING LAYER: ${layer.index + 1} / FRAME: ${frame.index + 1}`;

  if (centerContent) {
    dispatch(centerFrameContent());
  }

  dispatch({
    type: COMMON_TYPES.UPDATE_LOAD_TEXT,
    payload,
  });
  dispatch({
    type: COMMON_TYPES.UPDATE_LOAD_PERCENTAGE,
    payload: current / steps,
  });

  setTimeout(() => {
    dispatch(generateSpriteData(layer.index, frame.index));

    list.shift();
    current += 1;

    if (list.length > 0) {
      dispatch(handleList(list, steps, current, colors, isDragDropping, fullLoad, centerContent));
    } else {
      dispatch({
        type: COMMON_TYPES.UPDATE_LOAD_TEXT,
        payload: 'READY!',
      });

      colors && dispatch(loadColors(colors));

      // load complete
      if (isDragDropping) {
        if (getState().settings.showLayerSortView) {
          //
          dispatch({ type: COMMON_TYPES.SELECT_LAYER, payload: 0 });
          dispatch({ type: COMMON_TYPES.SET_CURRENT_FRAME, payload: 0 });
          dispatch(updateDrawCanvas());
          dispatch({ type: COMMON_TYPES.REGENERATE_AFTER_DROP_COMPLETE });
        } else {
          dispatch({
            type: COMMON_TYPES.SET_CURRENT_FRAME,
            payload: getState().data.droppedIndex,
          });
          dispatch({ type: COMMON_TYPES.REGENERATE_AFTER_DROP_COMPLETE });
        }
      } else {
        dispatch({ type: COMMON_TYPES.SELECT_LAYER, payload: 0 });
        dispatch({ type: COMMON_TYPES.SET_CURRENT_FRAME, payload: 0 });
        dispatch(updateDrawCanvas());
        dispatch({ type: COMMON_TYPES.LOAD_DATA_COMPLETE });

        if (getState().settings.showFrames) {
          if (!getState().settings.dockFrames) {
            dispatch(hideFrames());
          }

          if (fullLoad) {
            dispatch({ type: COMMON_TYPES.CLEAR_CHANGES_SINCE_LAST_SAVED });
          } else {
            // grid size change
            dispatch({ type: COMMON_TYPES.FORCE_UNSAVED_CHANGE });
          }
        }
      }
    }
  }, 0);
};

export const updateData = (layerData, isDragDropping = false, fullLoad = false, centerContent = false) => (
  dispatch
) => {
  setTimeout(() => {
    if (isDragDropping) {
      dispatch({
        type: COMMON_TYPES.REGENERATE_AFTER_DROP,
      });
    } else {
      dispatch({
        type: COMMON_TYPES.LOAD_DATA,
        payload: { data: layerData },
      });
    }

    dispatch(showFrames());

    const list = [];
    let steps = 0;
    const current = 1;
    const colors = getUsedColors(layerData.layers);
    layerData.layers.forEach((layer, layerIndex) => {
      layer.pixelData.forEach((frame, frameIndex) => {
        list.push({
          layer: {
            index: layerIndex,
            data: layer,
            total: layerData.layers.length,
          },
          frame: { index: frameIndex, data: frame, total: layer.length },
        });
        steps += 1;
      });
    });

    dispatch(handleList(list, steps, current, colors, isDragDropping, fullLoad, centerContent));
  }, 0);
};

export const updateLayerOpacity = () => (dispatch, getState) => {
  setTimeout(() => {
    const list = [];
    let steps = 0;
    const current = 1;
    const currentFrame = getState().data.currentFrame;
    // const currentLayer = getState().data.currentLayer;

    const layers = getState().data.layers;
    // layers.forEach((layer, layerIndex) => {
    //   layer.pixelData.forEach((frame, frameIndex) => {
    //     list.push({
    //       layer: {
    //         index: layerIndex,
    //         data: layer,
    //         total: layers.length,
    //       },
    //       frame: { index: frameIndex, data: frame, total: layer.length },
    //     });
    //     steps += 1;
    //   });
    // });
    const opacityLayerIndex = getState().data.opacityLayerIndex;
    const changedOpacityLayer = layers[opacityLayerIndex];
    changedOpacityLayer.pixelData.forEach((frame, frameIndex) => {
      list.push({
        layer: {
          index: opacityLayerIndex,
          data: changedOpacityLayer,
          total: 1,
        },
        frame: { index: frameIndex, data: frame, total: changedOpacityLayer.length },
      });
      steps += 1;
    });

    dispatch(clickThroughAllFrames(list, steps, current, currentFrame, opacityLayerIndex));
  }, 0);
};

const clickThroughAllFrames = (list, steps, current, currentFrame, currentLayer) => async (dispatch) => {
  const layer = list[0].layer;
  const frame = list[0].frame;
  dispatch({ type: COMMON_TYPES.SELECT_LAYER, payload: layer.index });
  dispatch(setCurrentFrame(frame.index));

  const payload = `UPDATING LAYER: ${layer.index + 1} / FRAME: ${frame.index + 1}`;

  dispatch({
    type: COMMON_TYPES.UPDATE_LOAD_TEXT,
    payload,
  });
  dispatch({
    type: COMMON_TYPES.UPDATE_LOAD_PERCENTAGE,
    payload: current / steps,
  });

  setTimeout(() => {
    dispatch(generateSpriteData(layer.index, frame.index));
    list.shift();
    current += 1;
    if (list.length > 0) {
      dispatch(clickThroughAllFrames(list, steps, current, currentFrame, currentLayer));
    } else {
      dispatch({
        type: COMMON_TYPES.UPDATE_LOAD_TEXT,
        payload: 'READY!',
      });
      dispatch({ type: COMMON_TYPES.SELECT_LAYER, payload: currentLayer });
      dispatch({ type: COMMON_TYPES.SET_CURRENT_FRAME, payload: currentFrame });

      dispatch(generateSpriteData());

      dispatch({ type: COMMON_TYPES.FORCE_UNSAVED_CHANGE });
    }
  }, 0);
};

export const exportData = () => (dispatch, getState) => {
  const layers = getState().data.layers;
  const combinedRaw = getState().data.combinedRaw;
  const exportLayers = [];
  layers.forEach((layer) => {
    exportLayers.push({ pixelData: layer.pixelData, rawData: [], opacity: layer.opacity || 100 });
  });
  const animation = {
    data: { layers: exportLayers },
    thumb: combinedRaw[0],
  };
  return animation;
};

export const loadFakeData = (data) => (dispatch, getState) => {
  const size = data.gridSize;
  const gridSize = getState().settings.gridSize;

  if (size !== gridSize) {
    dispatch(setGridSize(size));
    dispatch(updateBgCanvas());
  }

  const layerData = data;
  dispatch({
    type: COMMON_TYPES.LOAD_DATA,
    payload: { data: layerData },
  });

  dispatch({
    type: COMMON_TYPES.SET_FPS,
    payload: layerData.fps,
  });

  dispatch(renderNewGridSize(size, true));
};
