export default {
  PEN: 'PEN',
  ERASER: 'ERASER',
  COLORPICKER: 'COLORPICKER',
  MIRROR: 'MIRROR',
  MIRROR_X: 'MIRROR_X',
  MIRROR_Y: 'MIRROR_Y',
  COLOR: 'COLOR',
  FILL: 'FILL',
  UNDO: 'UNDO',
  MOVE: 'MOVE',
  MOVE_CLONE: 'MOVE_CLONE',
  SQUARE: 'SQUARE',
  LINE: 'LINE',
};
