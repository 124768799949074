import React from 'react';
import { ScrollView, View, Image, Text, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Profile.module.scss';
import {
  showSignIn,
  signOutSuccess,
  loadAnimationByUID,
  showAnimationDetails,
  hideAnimationDetails,
  setAnimationPrivacySetting,
  deleteAnimation,
} from '../../store/actions/userActions';
import { mockMode } from '../../utils/mocks';
import {
  showDeleteConfirm,
  hideDeleteConfirm,
  generateZipOfGif,
  saveFramesAsZip,
  saveCroppedFramesAsZip,
  generateSpriteSheet,
} from '../../store/actions/uiActions';

import { generateGIFAnimation } from '../../store/actions';
import layout from '../../config/layout';
import SignInProvider from '../../utils/SignInProvider';
import * as assets from '../../config/assets';
import LockBar from './LockBar';
import { THEME_TYPES } from '../../store/actions/types';
import { loadFakeData, exportData } from '../../store/actions/';
import ExportOptions from './ExportOptions';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.signInProvider = new SignInProvider();

    this.state = {
      isGenerating: false,
    };
  }

  renderUserAnimations() {
    const { animationList, animation, theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <ScrollView className={styles.list} style={{ height: this.props.screen.orgH - 320 }}>
        {animationList.map((item, i) => {
          const active = animation.animationUID === item.uid;
          return (
            <View
              key={i}
              className={styles.listItem}
              style={{
                border: '1px solid',
                borderLeftColor: active ? '#2d2d2d' : 'transparent',
                borderRightColor: active ? '#2d2d2d' : 'transparent',
                borderTopColor: active ? '#2d2d2d' : 'transparent',
                borderBottomColor: active ? '#2d2d2d' : th.loadBorderBottom,
                backgroundColor: active ? th.loadItemActiveBg : i % 2 ? th.loadItemBg : th.loadItemBg2,
              }}
            >
              {item.thumb && (
                <View
                  className={styles.listItemThumb}
                  style={{
                    borderColor: active ? th.loadThumbActiveBorder : th.loadThumbBorder,
                  }}
                >
                  <Image src={item.thumb} className={styles.thumb} />
                </View>
              )}

              <Text className={styles.listItemText} style={{ color: th.loadItemText }}>
                {item.name}
                {active && <Text className={styles.listItemTextActive}> (editing)</Text>}
              </Text>

              <Button
                className={styles.loadItem}
                style={{
                  opacity: active ? 1 : 1,
                  backgroundColor: th.loadBtnBg,
                  borderColor: th.loadItemBorder,
                }}
                description={active ? 'reload animation' : 'load animation'}
                position={{ marginLeft: active ? -12 : -8, marginTop: 4 }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  // console.log('ITEM', item);
                  this.props.loadAnimationByUID(item.uid, item.public);
                  // console.log('LOAD', item);
                }}
              >
                <Text className={styles.loadItemTxt} style={{ color: th.loadBtnTextColor }}>
                  {active ? 'Reload' : 'Load'}
                </Text>
              </Button>

              <View className={styles.settingsItem} style={{ opacity: active ? 1 : 0.25 }}>
                <Button
                  disabled={!active}
                  description="view animation details"
                  position={{ marginLeft: -88, marginTop: -12 }}
                  onClick={() => {
                    this.props.loadAnimationDetailsByUID(item.uid);
                  }}
                >
                  <Image src={assets.option_details} className={styles.settingsIcon} />
                </Button>
              </View>
            </View>
          );
        })}
      </ScrollView>
    );
  }

  renderProfile() {
    const { user, theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View className={styles.container} style={{ backgroundColor: th.panelBackground }}>
        <View
          className={styles.profileInfo}
          style={{ borderBottomColor: user ? th.profileBorderBottom : 'transparent' }}
        >
          <View
            className={styles.avatarContainer}
            style={{
              marginBottom: user ? 0 : 10,
              backgroundColor: user ? '#181818' : 'transparent',
            }}
          >
            {user ? (
              <View
                className={styles.avatar}
                style={{
                  borderColor: 'white',
                  shadowColor: 'black',
                  shadowRadius: 10,
                  shadowOpacity: 0.4,
                  shadowOffset: { width: 0, height: 10 },
                }}
              >
                <Image src={user.photo || user.user.photoURL} className={styles.avatarImage} />
              </View>
            ) : (
              <View className={styles.logo}>
                <Image src={assets.logo} className={styles.logoImage} />
              </View>
            )}
          </View>
          {user && (
            <View className={styles.usernameWrapper}>
              <Text className={styles.username}>{user.name || user.user.displayName}</Text>
            </View>
          )}
          {!user && (
            <View className={styles.usernameWrapper}>
              <Text className={styles.signInPlease}>Sign in to save your work</Text>
            </View>
          )}
        </View>
        <View className={styles.bottomSection}>
          <View className={styles.signInOutWrapper}>
            {user ? (
              <Button
                className={styles.signOutInBtn}
                style={{ backgroundColor: th.buttonColor }}
                description="sign out of spritelove"
                position={{ marginLeft: -38, marginTop: 6 }}
                onClick={() => {
                  const { signOutSuccess } = this.props;

                  this.signInProvider.signOut(
                    () => {
                      // on sign out success
                      signOutSuccess();
                    },
                    () => {
                      // console.log(error);
                    }
                  );
                }}
              >
                <Text className={styles.signInOutBtnText}>Sign out</Text>
              </Button>
            ) : (
              <>
                <Button
                  className={styles.signOutInBtn}
                  style={{ backgroundColor: th.buttonColor }}
                  description="sign in with google"
                  position={{ marginLeft: -35, marginTop: 20 }}
                  onClick={() => {
                    this.props.signIn();
                  }}
                >
                  <Text className={styles.signInOutBtnText}>Sign in</Text>
                </Button>
                {mockMode ? (
                  <>
                    <Button
                      className={styles.signOutInBtn}
                      style={{ backgroundColor: th.buttonColor, marginLeft: 10, marginRight: 10 }}
                      onClick={() => {
                        // const data = this.props.exportData();
                        // const json = JSON.stringify(data.data);
                        // console.clear(); // eslint-disable-line
                        // console.log(json); // eslint-disable-line
                      }}
                    >
                      <Text className={styles.signInOutBtnText}>get fake data</Text>
                    </Button>
                  </>
                ) : null}
              </>
            )}
          </View>
        </View>
        <View className={styles.detailsList} style={{ zIndex: this.props.animationList.length > 0 ? 1 : -1 }}>
          {this.getList()}
        </View>
      </View>
    );
  }

  renderAnimationDetails() {
    const { animationList, animationDetailsUID, theme } = this.props;
    const totalFrames = this.props.layers.length > 0 ? this.props.layers[this.props.currentLayer].pixelData.length : 1;
    const th = theme.themes[theme.current];
    const light = theme.current === THEME_TYPES.LIGHT_THEME;
    return (
      <View style={{ overflowY: 'scroll', flex: 1, marginTop: 5, height: this.props.screen.orgH - 320 }}>
        <View
          style={{
            display: 'flex',
            backgroundColor: light ? '#181818' : '#181818',
          }}
        >
          {animationList
            .filter((x) => x.uid === animationDetailsUID)
            .map((item, index) => (
              <View id={`animationList_${index}`} key={`details${index}`} className={styles.detailsInfo}>
                <View className={styles.detailsTopInfo}>
                  <Button
                    className={styles.closeDetailsBtn}
                    style={
                      { backgroundColor: light ? '#181818' : '#181818' } // { backgroundColor: light ? "#EEE" : "#181818" }
                    }
                    description="go back"
                    position={{ marginLeft: 0, marginTop: 30 }}
                    onClick={() => this.props.hideDetails()}
                  >
                    <Image src={assets.back} className={styles.logoImage} />
                  </Button>
                  <Text className={styles.detailsTitleText} style={{ color: 'white' }}>
                    {item.name}
                  </Text>
                </View>
                {this.props.palette && this.props.palette.length > 0 && (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: 5,
                      paddingLeft: 20,
                      paddingRight: 20,
                      marginBottom: 20,
                      marginTop: 30,
                    }}
                  >
                    {this.props.palette &&
                      this.props.palette.length > 0 &&
                      this.props.palette.map((color, paletteIndex) => (
                        <View
                          key={`color_${paletteIndex}`}
                          style={{
                            flex: 1,
                            width: 350 / this.props.palette.length,
                            backgroundColor: color,
                          }}
                        />
                      ))}
                  </View>
                )}
                <View className={styles.gif} style={{ backgroundColor: th.buttonColor }}>
                  {/* <Image
                    src={item.isGenerating ? item.thumb : `data:image/gif;base64,${item.gif}`}
                    style={{ width: 96, height: 96 }}
                  /> */}
                  <Image src={item.thumb} style={{ width: 96, height: 96 }} />
                </View>
                <Text className={styles.detailsText}>
                  {item.isGenerating
                    ? 'Generating gif...'
                    : `Created: ${new Date(item.created).toISOString().substr(0, 10)}`}
                </Text>
                <View className={styles.section}>
                  <Text className={styles.sectionTitle}>Export options</Text>
                </View>
                <ExportOptions />
                <View className={styles.section}>
                  <Text className={styles.sectionTitle}>Gif</Text>
                </View>
                <View>
                  <View className={styles.downloadGifBtn}>
                    <Button
                      description="download animation"
                      position={{ position: 'relative' }}
                      track={{ category: 'Interface', action: 'Download gif' }}
                      onClick={this.props.saveGif}
                    >
                      <Text className={styles.downloadGifText}>Download gif</Text>
                    </Button>
                  </View>
                  <View className={styles.section}>
                    <Text className={styles.sectionTitle}>Download frames </Text>
                  </View>
                  <View className={styles.downloadGifBtn}>
                    <Button
                      description="download frames"
                      position={{ marginLeft: 0, position: 'relative' }}
                      track={{ category: 'Interface', action: 'Download frames' }}
                      onClick={this.props.saveFrames}
                    >
                      <Text className={styles.downloadGifText}>{`Download frames (${totalFrames})`}</Text>
                    </Button>
                  </View>
                  <View className={styles.downloadGifBtn}>
                    <Button
                      description="download cropped frames"
                      position={{ marginLeft: 0, position: 'relative' }}
                      track={{ category: 'Interface', action: 'Download cropped frames' }}
                      onClick={this.props.saveCropped}
                    >
                      <Text className={styles.downloadGifText}>{`Download cropped frames (${totalFrames})`}</Text>
                    </Button>
                  </View>
                  <View className={styles.section}>
                    <Text className={styles.sectionTitle}>Download spritesheet</Text>
                  </View>
                  <View className={styles.downloadGifBtn}>
                    <Button
                      description="Download spritesheet with json"
                      position={{ marginLeft: 0, position: 'relative' }}
                      track={{ category: 'Interface', action: 'Download spritesheet with json' }}
                      onClick={() => {
                        this.props.generateSpriteSheet(false);
                      }}
                    >
                      <Text className={styles.downloadGifText}>Download spritesheet & JSON</Text>
                    </Button>
                  </View>
                  <View className={styles.downloadGifBtn}>
                    <Button
                      description="Download cropped spritesheet with json"
                      position={{ marginLeft: 0, position: 'relative' }}
                      track={{ category: 'Interface', action: 'Download cropped spritesheet with json' }}
                      onClick={() => {
                        this.props.generateSpriteSheet(true);
                      }}
                    >
                      <Text className={styles.downloadGifText}>Download cropped spritesheet & JSON</Text>
                    </Button>
                  </View>
                </View>
                <View className={styles.section}>
                  <Text className={styles.sectionTitle}>Privacy options</Text>
                </View>
                <LockBar
                  light={light}
                  theme={th}
                  options={['Public', 'Private']}
                  selectedIndex={item.public ? 0 : 1}
                  lock={(index, privacy) => {
                    if (item.public && privacy === 'Private') {
                      this.props.setPrivacySetting('private', animationDetailsUID, item.thumb);
                    } else if (!item.public && privacy === 'Public') {
                      this.props.setPrivacySetting('public', animationDetailsUID, item.thumb);
                    }
                  }}
                />
                {/* delete animation */}
                <View className={styles.section}>
                  <Text className={styles.sectionTitle}>Danger</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    position: 'relative',
                    height: 70,
                    width: 240,
                    marginTop: 0,
                    alignItems: 'center',
                    overflow: 'hidden',
                    alignSelf: 'center',
                  }}
                >
                  <View
                    style={{
                      position: 'absolute',
                      marginTop: this.props.showDeleteConfirm ? -70 : 0,
                      flexDirection: 'column',
                      transition: 'margin-top 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)',
                    }}
                  >
                    <View
                      className={styles.buttons}
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginTop: 70,
                        marginBottom: 10,
                        flexDirection: 'column',
                        height: 60,
                        width: 200,
                      }}
                    >
                      <Button
                        className={styles.optionSelector}
                        style={{
                          backgroundColor: th.buttonColor,
                          height: 40,
                          width: '100%',
                        }}
                        description="delete animation"
                        position={{ marginLeft: 0, marginTop: 30 }}
                        onClick={() => this.props.showDelete()}
                      >
                        <Text
                          style={{
                            color: 'gray',
                          }}
                        >
                          Delete animation
                        </Text>
                      </Button>
                    </View>
                    {/* RESET */}
                    <View
                      className={styles.buttons}
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        marginTop: 0,
                        marginBottom: 10,
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        height: 60,
                        display: 'flex',
                      }}
                    >
                      <Button
                        className={styles.confirmBtns}
                        style={{
                          backgroundColor: layout.cancelBtnColor,
                          height: 50,
                          width: '48%',
                        }}
                        onClick={() => this.props.hideDelete()}
                      >
                        <Text
                          style={{
                            color: 'white',
                          }}
                        >
                          Cancel
                        </Text>
                      </Button>
                      <Button
                        className={styles.confirmBtns}
                        style={{
                          backgroundColor: layout.buttonColor,
                          height: 50,
                          width: '48%',
                        }}
                        onClick={() => {
                          this.props.hideDelete();
                          this.props.deleteAnimation(animationDetailsUID);
                        }}
                      >
                        <Text
                          style={{
                            color: 'white',
                          }}
                        >
                          Ok
                        </Text>
                      </Button>
                    </View>
                  </View>
                </View>
              </View>
            ))}
        </View>
      </View>
    );
  }

  getList() {
    const { user, animationList, animationDetailsUID } = this.props;
    if (user) {
      if (animationList.length > 0) {
        return (
          <View
            className={styles.listWrapper}
            style={{
              flex: 1,
              marginBottom: 10,
              marginLeft: animationDetailsUID ? -350 : 0,
            }}
          >
            {this.renderUserAnimations()}
            {this.renderAnimationDetails()}
          </View>
        );
      }

      return (
        <View className={styles.noAnimations}>
          <Text className={styles.noAnimationsText}>
            “start making the world a better place, one GIF at a time” - N.Delfino
          </Text>
        </View>
      );
    }
  }

  render() {
    return this.renderProfile();
  }
}

const mapState = (state) => ({
  currentFPS: state.settings.fps,
  onionPrev: state.settings.onionFramesPrev,
  onionNext: state.settings.onionFramesNext,
  onionLayers: state.settings.onionLayers,
  grid: state.settings.showGrid,
  show: state.settings.showSettings,
  screen: state.settings.screen,
  user: state.user.user,
  palette: state.tools.userColors,
  layers: state.data.layers,
  currentLayer: state.data.currentLayer,
  animationList: state.user.animationList,
  animation: state.data.animation,
  animationDetailsUID: state.data.animationDetailsUID,
  showDeleteConfirm: state.settings.showDeleteConfirm,
  theme: state.theme,
});

const mapDispatch = (dispatch) => ({
  signIn: () => dispatch(showSignIn()),
  signOutSuccess: () => dispatch(signOutSuccess()),
  loadAnimationByUID: (uid, isPublic, background) => dispatch(loadAnimationByUID(uid, isPublic, background)),
  loadAnimationDetailsByUID: (uid) => dispatch(showAnimationDetails(uid)),
  hideDetails: () => dispatch(hideAnimationDetails()),
  genGIF: (layers, width, height, fps, size) => dispatch(generateGIFAnimation(layers, width, height, fps, size)),
  setPrivacySetting: (setting, uid, thumb) => dispatch(setAnimationPrivacySetting(setting, uid, thumb)),
  showDelete: () => dispatch(showDeleteConfirm()),
  hideDelete: () => dispatch(hideDeleteConfirm()),
  deleteAnimation: (uid) => dispatch(deleteAnimation(uid)),
  loadFake: (data) => dispatch(loadFakeData(data)),
  exportData: () => dispatch(exportData()),
  saveGif: () => dispatch(generateZipOfGif()),
  saveFrames: () => dispatch(saveFramesAsZip()),
  saveCropped: () => dispatch(saveCroppedFramesAsZip()),
  generateSpriteSheet: (crop) => dispatch(generateSpriteSheet(crop)),
});

export default connect(mapState, mapDispatch)(Profile);
