import { TOOL_TYPES } from '../store/actions/types';

export const debounce = (func, wait = 0, immediate = false) => {
  let timeout;
  return function debounced(...args) {
    const later = () => {
      timeout = null;
      if (!immediate) func(...args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func(...args);
  };
};

export const throttle = (func, wait, options) => {
  let context;
  let args;
  let result;
  let timeout = null;
  let previous = 0;
  if (!options) options = {};
  const later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    const now = Date.now();
    if (!previous && options.leading === false) previous = now;
    const remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

export const validDrawToolSelected = (props) => {
  const { activeTool } = props;
  return (
    activeTool === TOOL_TYPES.PEN ||
    activeTool === TOOL_TYPES.ERASER ||
    activeTool === TOOL_TYPES.MIRROR ||
    activeTool === TOOL_TYPES.COLORPICKER ||
    activeTool === TOOL_TYPES.FILL
  );
};

export const validBrushToolSelected = (props) => {
  const { activeTool } = props;
  return activeTool === TOOL_TYPES.PEN || activeTool === TOOL_TYPES.ERASER;
};

export const toolMode = (activeTool) => ({
  drawMode: activeTool === TOOL_TYPES.PEN,
  eraseMode: activeTool === TOOL_TYPES.ERASER,
  colorPickMode: activeTool === TOOL_TYPES.COLORPICKER,
  mirrorMode: activeTool === TOOL_TYPES.MIRROR,
  fillMode: activeTool === TOOL_TYPES.FILL,
  moveMode: activeTool === TOOL_TYPES.MOVE,
  cloneMode: activeTool === TOOL_TYPES.MOVE_CLONE,
  squareMode: activeTool === TOOL_TYPES.SQUARE,
  lineMode: activeTool === TOOL_TYPES.LINE,
});

export const snapshotToArray = (snapshot) => {
  const returnArr = [];
  Object.entries(snapshot).forEach(([key, value]) =>
    returnArr.push({
      uid: key,
      name: value.name,
      thumb: value.thumb,
      // gif: value.gif,
      isGenerating: false,
      created: value.created,
      saved: value.saved,
      public: value.public,
    })
  );

  return returnArr.reverse();
};

export const hasNextFrame = (data) => {
  const current = data.currentFrame + 1;
  // console.log('length', data.layers[data.currentLayer].pixelData.length)
  const is = current < data.layers[data.currentLayer].pixelData.length;
  // console.log('current', current, 'is', is)
  return is;
};
