import React from 'react';
import { View, Text, Button, Image } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from './../styles/SettingsOptions.module.scss';
import layout from '../../config/layout';
import Options from './Options';
import Profile from './Profile';
import * as assets from '../../config/assets';
import { showSettingsOptions, showSettingsProfile } from '../../store/actions/uiActions';

class SettingsOptions extends React.Component {
  render() {
    const { showOptions, showProfile, theme } = this.props;
    const th = theme.themes[theme.current];
    return (
      <View
        className={styles.container}
        style={{
          marginLeft: this.props.show ? -layout.menuViewWidth : 0,
        }}
      >
        <View className={styles.settingsSelector}>
          <Button
            className={styles.selector}
            style={{
              backgroundColor: showOptions ? th.optionTabSelectedColor : th.optionTabColor,
            }}
            description="show settings"
            position={{ marginTop: 25 }}
            onClick={() => {
              this.props.selectOptions();
            }}
          >
            <Image src={assets.option_settings} className={styles.icon} />
            <Text
              className={styles.selectorText}
              style={{
                color: showOptions ? th.optionTabTextSelectedColor : th.optionTabTextColor,
              }}
            >
              Settings
            </Text>
          </Button>
          <Button
            className={styles.selector}
            style={{
              backgroundColor: showProfile ? th.optionTabSelectedColor : th.optionTabColor,
              cursor: 'pointer',
            }}
            description="show profile"
            position={{ marginTop: 25 }}
            onClick={() => {
              this.props.selectProfile();
            }}
          >
            <Image src={assets.option_profile} className={styles.icon} />
            <Text
              className={styles.selectorText}
              style={{
                color: showProfile ? th.optionTabTextSelectedColor : th.optionTabTextColor,
              }}
            >
              Profile
            </Text>
          </Button>
        </View>
        <View className={styles.scrollWrapper}>
          <View>
            <View
              style={{
                marginLeft: showOptions ? 0 : -layout.menuViewWidth,
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Options />
              <Profile />
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  show: state.settings.showSettings,
  showOptions: state.settings.showSettingsOptions,
  showProfile: state.settings.showSettingsProfile,
  theme: state.theme,
});

const mapDispatch = (dispatch) => ({
  selectOptions: () => dispatch(showSettingsOptions()),
  selectProfile: () => dispatch(showSettingsProfile()),
});

export default connect(mapState, mapDispatch)(SettingsOptions);
