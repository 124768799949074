import React, { Fragment } from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { View } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/FrameSlider.module.scss';
import { setQuickNavFrame } from '../../store/actions/uiActions';

/****************************/
// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  position: 'absolute',
  width: '100%',
  height: 42,
  transform: 'translate(0%, -50%)',
  borderRadius: 7,
  cursor: 'pointer',
  // border: '1px solid white',
};

const railInnerStyle = {
  position: 'absolute',
  width: '100%',
  height: 5,
  transform: 'translate(0%, -50%)',
  borderRadius: 0,
  pointerEvents: 'none',
  backgroundColor: '#332f3e',
};

export function SliderRail({ getRailProps }) {
  return (
    <Fragment>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </Fragment>
  );
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps }) {
  return (
    <Fragment>
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          WebkitTapHighlightColor: 'rgba(0,0,0,0)',
          zIndex: 5,
          width: 28,
          height: 42,
          cursor: 'pointer',
          backgroundColor: 'none',
        }}
        {...getHandleProps(id)}
      />
      <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          zIndex: 2,
          width: 10,
          height: 20,
          borderRadius: '10%',
          backgroundColor: disabled ? '#666' : '#ccc',
        }}
      />
    </Fragment>
  );
}

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({ domain: [min, max], handle: { id, value, percent }, disabled, getHandleProps }) {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: disabled ? '#666' : '#ffc400',
      }}
      {...getHandleProps(id)}
    />
  );
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: 14,
        zIndex: 1,
        backgroundColor: disabled ? '#999' : '#b28900',
        borderRadius: 7,
        cursor: 'pointer',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, currentFrame }) { // eslint-disable-line
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: 15,
          width: 1,
          height: 4,
          backgroundColor: tick.value.toString().indexOf('.5') === -1 ? 'gray' : 'transparent',
          // backgroundColor: '#525252',
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 22,
          fontSize: window.getComputedStyle(document.documentElement).getPropertyValue('--small-size'),
          textAlign: 'center',
          color: tick.value === currentFrame + 1 ? 'white' : 'gray',
          backgroundColor: tick.value === currentFrame + 1 ? '#111' : 'transparent',
          marginLeft: -20, // `${-(50 / count) / 2}%`,
          width: 40, //`${50 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value.toString().indexOf('.5') === -1 && tick.value}
      </div>
    </div>
  );
}

const sliderStyle = {
  position: 'relative',
  width: '100%',
};

class FrameSlider extends React.Component {
  constructor(props) {
    super(props);

    this.debounceTimeout = false;
    this.debounceDelay = 0;

    this.onUpdate = this.onUpdate.bind(this);
    this.changeFrame = this.changeFrame.bind(this);

    this.state = {
      totalFrames: this.props.totalFrames,
      currentFrame: this.props.currentFrame,
      values: [this.props.currentFrame],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.currentFrame !== nextProps.currentFrame) {
      return {
        totalFrames: nextProps.totalFrames,
        currentFrame: nextProps.currentFrame,
        values: [nextProps.currentFrame + 1],
      };
    }
    return null;
  }

  onUpdate(e) {
    // clearTimeout(this.debounceTimeout);
    // this.debounceTimeout = setTimeout(() => {
    // }, this.debounceDelay);
    this.changeFrame(e);
  }

  changeFrame(value) {
    const v = parseInt(value, 10);
    const frame = Math.min(Math.max(parseInt(v - 1), 0), this.props.totalFrames - 1);
    if (frame !== this.props.currentFrame) {
      this.props.setFrame(frame);
    }
  }

  renderSlider() {
    return (
      <div
        style={{ marginLeft: 20, marginRight: 20, height: 10, marginBottom: 8, width: '100%', background: '#1f1f1f' }}
      >
        <Slider
          mode={1}
          step={1}
          disabled={this.props.totalFrames === 1}
          domain={[1, this.props.totalFrames]}
          rootStyle={sliderStyle}
          onUpdate={this.onUpdate}
          values={this.state.values}
        >
          <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={handle.id}
                    handle={handle}
                    domain={[1, this.props.totalFrames]}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                ))}
              </div>
            )}
          </Tracks>
          <Ticks count={this.props.totalFrames}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map((tick) => (
                  <Tick key={tick.id} tick={tick} currentFrame={this.props.currentFrame} count={ticks.length} />
                ))}
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    );
  }

  render() {
    return (
      <View
        className={styles.container}
        style={{
          position: 'absolute',
          background: '#222',
          zIndex: 300000,
          // top: this.props.screen.orgH / 2 + this.props.screen.width / 2,
          // left: this.props.screen.orgW / 2 - this.props.screen.width / 2,
          bottom: 30,
          left: 250,
          width: this.props.screen.orgW - 260,
          height: 52,
          padding: 4,
          // opacity: this.props.expanded || this.props.showFrames ? 0 : 1,
          color: '#333',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 0,
          borderTopWidth: 1,
          borderColor: '#313131',
          borderStyle: 'solid',
          fontSize: window.getComputedStyle(document.documentElement).getPropertyValue('--small-size'),
        }}
      >
        {this.props.totalFrames > 1 && this.renderSlider()}
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  currentFrame: state.data.currentFrame,
  expanded: state.settings.expanded,
  showFrames: state.settings.showFrames,
  totalFrames: state.data.layers.length > 0 ? state.data.layers[state.data.currentLayer].pixelData.length : 1,
});

const mapDispatch = (dispatch) => ({
  setFrame: (frame) => dispatch(setQuickNavFrame(frame)),
});

export default connect(mapState, mapDispatch)(FrameSlider);
