import React from 'react';
import { View, Text } from '../BaseComponents';
import styles from '../styles/LayerOpacity.module.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class LayerOpacity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentOpacity: this.props.currentOpacity,
    };
  }

  render() {
    return (
      <View className={styles.container}>
        <View className={styles.section}>
          <Text>Current opacity: {this.state.currentOpacity}</Text>
        </View>
        <View style={{ paddingTop: 10 }}>
          <Slider
            min={0}
            max={100}
            defaultValue={this.props.currentOpacity}
            // value={this.state.currentOpacity}
            marks={{ '0': 0, 100: 100 }}
            step={2}
            railStyle={{ background: '#292929' }}
            trackStyle={{ background: '#5d5d5d' }}
            onChange={(e) => {
              this.setState({ currentOpacity: e });
            }}
            onAfterChange={(e) => {
              this.props.setOpacity(e);
            }}
          />
        </View>
      </View>
    );
  }
}

export default LayerOpacity;
