/* eslint-disable no-undef */
import { COMMON_TYPES, UI_TYPES, THEME_TYPES } from './types';
import { updateData, updateLayerOpacity } from './index';
import {
  updateDrawCanvas,
  updateBgCanvas,
  generateSpriteData,
  getFrameDataWithOffset,
  getFlattenedFrameDataWithOffset,
  renderSpriteSheetWithData,
  generateGIF,
} from './canvasActions';
import { hasNextFrame } from '../../utils';
import { stopAnimation } from './animationActions';
import { resetCamera } from './cameraActions';
import { fetchUserDataOnce } from './userActions';
import JSZip from 'jszip';
import FileSaver from 'file-saver';

export const addLayer = () => ({ type: COMMON_TYPES.ADD_NEW_LAYER });
export const hideSettingsView = () => ({ type: UI_TYPES.HIDE_SETTINGS_VIEW });
export const toggleExpand = () => ({ type: COMMON_TYPES.TOGGLE_EXPAND });
export const showResetConfirm = () => ({ type: UI_TYPES.SHOW_RESET_CONFIRM });
export const hideResetConfirm = () => ({ type: UI_TYPES.HIDE_RESET_CONFIRM });
export const startFrameDrag = () => ({ type: UI_TYPES.START_FRAME_DRAG });
export const stopFrameDrag = () => ({ type: UI_TYPES.STOP_FRAME_DRAG });
export const triggerOnboarding = () => ({ type: UI_TYPES.TRIGGER_ONBOARDING });
export const onboarded = (val) => ({ type: UI_TYPES.ONBOARDED, payload: val });
export const showSortLayersView = () => ({ type: UI_TYPES.SHOW_SORT_LAYER_VIEW });
export const showDimensionView = () => ({ type: UI_TYPES.SHOW_DIMENSION_VIEW });
export const hideDimensionView = () => ({ type: UI_TYPES.HIDE_DIMENSION_VIEW });
export const showFrameDialog = () => ({ type: UI_TYPES.SHOW_ADD_FRAME_DIALOG });

export const enableShowFramePrompt = () => ({ type: UI_TYPES.ENABLE_SHOW_FRAME_PROMPT });
export const disableShowFramePrompt = () => ({ type: UI_TYPES.DISABLE_SHOW_FRAME_PROMPT });

export const hideSortLayersView = () => (dispatch) => {
  dispatch({ type: UI_TYPES.HIDE_SORT_LAYER_VIEW });
};

export const showLayerOpacity = () => (dispatch) => {
  dispatch({ type: UI_TYPES.SHOW_LAYER_OPACITY });
};

export const setLayerOpacityIndex = (index) => (dispatch) => {
  dispatch({ type: UI_TYPES.SET_LAYER_OPACITY_INDEX, payload: index });
};

export const setLayerOpacity = (val) => (dispatch) => {
  dispatch({ type: UI_TYPES.SET_LAYER_OPACITY, payload: val });
  dispatch(updateLayerOpacity());
};

export const closeLayerOpacity = () => (dispatch) => {
  dispatch({ type: UI_TYPES.CLOSE_LAYER_OPACITY });
};

export const setExportSize = (val) => ({ type: UI_TYPES.SET_EXPORT_SIZE, payload: val });

export const toggleTheme = () => (dispatch, getState) => {
  const light = getState().theme.current === THEME_TYPES.LIGHT_THEME;
  if (light) {
    dispatch(selectDarkTheme());
  } else {
    dispatch(selectLightTheme());
  }
};

export const selectLightTheme = () => (dispatch) => {
  dispatch({ type: UI_TYPES.SELECT_LIGHT_THEME });
};
export const selectDarkTheme = () => (dispatch) => {
  dispatch({ type: UI_TYPES.SELECT_DARK_THEME });
};

export const showDeleteConfirm = () => ({
  type: UI_TYPES.SHOW_DELETE_ANIMATION_CONFIRM,
});
export const hideDeleteConfirm = () => ({
  type: UI_TYPES.HIDE_DELETE_ANIMATION_CONFIRM,
});

export const clearFrame = (index) => (dispatch) => {
  dispatch({
    type: COMMON_TYPES.CLEAR_FRAME,
    payload: index,
  });
  dispatch(updateDrawCanvas());
};

export const showSettingsOptions = () => ({
  type: UI_TYPES.SHOW_SETTINGS_OPTIONS,
});
export const showSettingsProfile = () => (dispatch, getState) => {
  const { data } = getState();
  if (!data.gif && data.animation.animationUID) {
    // console.log('no available gif, generate');
    dispatch(generateGIF());
  }
  dispatch({ type: UI_TYPES.SHOW_SETTINGS_PROFILE });
};

export const setQuickNavFrame = (index) => (dispatch) => {
  dispatch(clearDraw());
  dispatch(setCurrentFrame(index));
  dispatch(updateDrawCanvas());
};

const clearDraw = () => (dispatch, getState) => {
  const settings = getState().settings;
  const canvas = settings.drawCanvasRef;
  const size = settings.screen.width;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, size, size);
};

export const setGrid = (val) => (dispatch) => {
  dispatch({ type: COMMON_TYPES.SET_GRID, payload: val });
  dispatch(updateBgCanvas());
};

export const setRenderFactor = (val) => (dispatch) => {
  dispatch({ type: COMMON_TYPES.SET_RENDER_FACTOR, payload: val });
};

export const setLightGrid = (val) => (dispatch) => {
  dispatch({ type: COMMON_TYPES.LIGHT_GRID, payload: val });
  dispatch(updateBgCanvas());
};

export const selectLayer = (index) => (dispatch) => {
  dispatch({ type: COMMON_TYPES.SELECT_LAYER, payload: index });
  dispatch(updateDrawCanvas());
};

export const deleteLayer = (index) => (dispatch, getState) => {
  if (getState().data.layers.length > 1) {
    dispatch(selectLayer(Math.max(0, getState().data.currentLayer - 1)));
    dispatch({ type: COMMON_TYPES.DELETE_LAYER, payload: index });

    setImmediate(() => {
      const remainingLayers = dispatch(getRemainingLayers());
      dispatch(updateData(remainingLayers));
    });
  }
};

export const hideFrames = () => (dispatch, getState) => {
  dispatch({ type: UI_TYPES.HIDE_FRAMES_VIEW });
  dispatch(generateSpriteData());
  dispatch(updateDrawCanvas());
  setTimeout(() => {
    dispatch(updateBgCanvas());

    if (getState().settings.showLayerSortView) {
      dispatch(hideSortLayersView());
    }
  }, 0);
};

export const addFrameConfirm = () => (dispatch, getState) => {
  const currentFrame = getState().data.currentFrame;
  dispatch(addFrame());
  dispatch(setCurrentFrame(currentFrame + 1));
  setImmediate(() => {
    dispatch(updateDrawCanvas());
  });
};

export const addFrameDialogReject = () => ({
  type: UI_TYPES.HIDE_ADD_FRAME_DIALOG,
});

export const addFrame = (clone = null, cb = null, clickIndex = null) => (dispatch, getState) => {
  dispatch({
    type: COMMON_TYPES.ADD_NEW_FRAME,
    payload: { clone, clickIndex },
  });
  const data = getState().data;

  setImmediate(() => {
    if (!clone) {
      if (hasNextFrame(data)) {
        dispatch(setCurrentFrame(data.currentFrame + 1));
      }
    } else {
      dispatch(setCurrentFrame(clickIndex + 1));
    }
  });
  if (cb) {
    cb();
  }
};

export const cloneFrame = (index) => (dispatch, getState) => {
  if (getState().data.layers.length > 1) {
    dispatch(cloneFrameOnAllLayers(index));
  } else {
    dispatch(cloneOnSingleLayer(index));
  }
  dispatch({ type: COMMON_TYPES.CLONE_FRAME });
};

export const deleteFrame = (index) => (dispatch, getState) => {
  const { currentLayer } = getState().data;
  if (getState().data.layers[currentLayer].pixelData.length === 1 && index === 0) {
    dispatch(clearFrame(index));
  } else {
    dispatch({ type: COMMON_TYPES.DELETE_FRAME, payload: index });
    dispatch(setCurrentFrame(Math.max(0, index - 1)));
  }
};

export const setCurrentFrame = (index) => async (dispatch) => {
  dispatch({ type: COMMON_TYPES.SET_CURRENT_FRAME, payload: index });
  await dispatch(generateSpriteData());
};

export const toggleOnionPrev = () => (dispatch) => {
  dispatch({ type: UI_TYPES.SHOW_ONION_SKIN_FRAMES_PREV });
  dispatch(updateDrawCanvas());
};

export const toggleOnionNext = () => (dispatch) => {
  dispatch({ type: UI_TYPES.SHOW_ONION_SKIN_FRAMES_NEXT });
  dispatch(updateDrawCanvas());
};

export const toggleOnionLayers = () => (dispatch) => {
  dispatch({ type: UI_TYPES.SHOW_ONION_SKIN_FRAMES_LAYERS });
  dispatch(updateDrawCanvas());
};

export const showFrames = () => (dispatch, getState) => {
  dispatch(stopAnimation());
  dispatch(resetCamera());
  dispatch({ type: UI_TYPES.SHOW_FRAMES_VIEW });
  dispatch(setCurrentFrame(getState().data.currentFrame));
};
export const showExpandedFrameTools = () => (dispatch) => dispatch({ type: UI_TYPES.SHOW_EXPANDED_FRAME_TOOLS });
export const hideExpandedFrameTools = () => (dispatch) => dispatch({ type: UI_TYPES.HIDE_EXPANDED_FRAME_TOOLS });

export const showSettingsView = () => (dispatch, getState) => {
  dispatch({ type: UI_TYPES.SHOW_SETTINGS_VIEW });

  if (getState().user.signedIn) {
    dispatch(fetchUserDataOnce());
  }
};

export const getRemainingLayers = () => (dispatch, getState) => {
  const layers = getState().data.layers;
  const exportLayers = [];
  layers.forEach((layer) => {
    exportLayers.push({ pixelData: layer.pixelData, rawData: [], opacity: layer.opacity });
  });
  const animation = {
    layers: exportLayers,
  };
  return animation;
};

const cloneOnSingleLayer = (index) => (dispatch, getState) => {
  const cloneFrame = {
    pixelData: JSON.parse(JSON.stringify(getState().data.layers[getState().data.currentLayer].pixelData[index])),
    rawData: JSON.parse(JSON.stringify(getState().data.layers[getState().data.currentLayer].rawData[index])),
  };
  dispatch(addFrame(cloneFrame, null, index));
};

export const cloneFrameFillRest = (index) => (dispatch, getState) => {
  const cloneFrame = {
    pixelData: JSON.parse(JSON.stringify(getState().data.layers[getState().data.currentLayer].pixelData[index])),
    rawData: JSON.parse(JSON.stringify(getState().data.layers[getState().data.currentLayer].rawData[index])),
  };
  dispatch(addFrame(cloneFrame, null, index));
};

export const cloneFrameOnAllLayers = (index) => (dispatch, getState) => {
  getState().data.layers.forEach((layer, layerIndex) => {
    const cloneFrame = {
      pixelData: JSON.parse(JSON.stringify(layer.pixelData[index])),
      rawData: JSON.parse(JSON.stringify(layer.rawData[index])),
    };
    dispatch({
      type: COMMON_TYPES.ADD_NEW_FRAME_ON_LAYER,
      payload: { clone: cloneFrame, layer: layerIndex, frameIndex: index },
    });
  });

  dispatch({ type: COMMON_TYPES.CLONE_FRAME });
};

export const swapFrames = (fromIndex, toIndex) => (dispatch) => {
  dispatch({
    type: UI_TYPES.MOVE_FRAME,
    payload: { dragged: fromIndex, dropped: toIndex },
  });
  const layerData = dispatch(getRemainingLayers());
  dispatch(updateData(layerData, true));
};

export const swapLayers = (fromIndex, toIndex) => (dispatch) => {
  dispatch({
    type: UI_TYPES.MOVE_LAYER,
    payload: { dragged: fromIndex, dropped: toIndex },
  });

  const layerData = dispatch(getRemainingLayers());
  dispatch(updateData(layerData, true));
};

export const generateZipOfGif = (test) => (dispatch, getState) => { // eslint-disable-line
  dispatch(
    generateGIF((gif) => {
      const zip = new JSZip();
      zip.file('spritelove.gif', gif, { base64: true });
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        FileSaver.saveAs(blob, 'spritelove.zip');
      });
    })
  );
};

const generateZipOfFrames = (frames, name) => {
  const zip = new JSZip();
  frames.forEach((frame, index) => {
    if (frame.data) {
      // data is without data:image/png;base64
      zip.file(`${name}_${index}.png`, frame.data, { base64: true });
    }
  });
  zip.generateAsync({ type: 'blob' }).then((blob) => {
    FileSaver.saveAs(blob, `spritelove_${name}.zip`);
  });
};

export const saveFramesAsZip = () => (dispatch, getState) => {
  const frames = [];
  const animationName = getState().data.animation.name || 'frames';
  const { data } = getState();
  const layers = data.layers;
  const numFrames = layers[0].pixelData.length;
  for (let frameIndex = 0; frameIndex < numFrames; frameIndex++) {
    const frameData = dispatch(getFlattenedFrameDataWithOffset(frameIndex, false));
    frames.push({
      frame: frameIndex,
      data: frameData.data.split(',')[1],
    });
  }

  generateZipOfFrames(frames, animationName);
};

export const saveCroppedFramesAsZip = () => (dispatch, getState) => {
  const frames = [];
  const animationName = getState().data.animation.name || 'frames';
  const { data } = getState();
  const layers = data.layers;
  const numFrames = layers[0].pixelData.length;
  for (let frameIndex = 0; frameIndex < numFrames; frameIndex++) {
    const frameData = dispatch(getFlattenedFrameDataWithOffset(frameIndex, true));
    frames.push({
      frame: frameIndex,
      data: frameData.data.split(',')[1],
    });
  }

  generateZipOfFrames(frames, animationName);
};

export const generateSpriteSheet = (crop = false, flattenLayers = true) => async (dispatch, getState) => {
  const frames = dispatch(generateCroppedSpriteSheetData(crop, flattenLayers));
  const name = getState().data.animation.name || 'frames';
  const animationName = `spritelove_spritesheet_${name.replace(' ', '_').toLowerCase()}`;

  const spritesheet = await dispatch(renderSpriteSheetWithData(frames, crop, animationName));
  const jsonBlob = new Blob([JSON.stringify(spritesheet.json)], { type: 'application/json;charset=utf-8' });

  const blobData = [
    { data: spritesheet.data, fileName: `${animationName}.png` },
    { data: jsonBlob, fileName: `${animationName}.json` },
  ];

  saveMultipleFiles(blobData);
};

const saveMultipleFiles = (blobs) => {
  blobs.forEach((blob) => {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = window.URL.createObjectURL(blob.data);
    a.download = blob.fileName;
    a.click();
    a = null;
  });
};

export const generateCroppedSpriteSheetData = (crop, flattenLayers) => (dispatch, getState) => {
  const frames = [];

  if (flattenLayers) {
    getState().data.layers[0].pixelData.forEach((frame, frameIndex) => {
      if (frame.length > 0) {
        const frameData = dispatch(getFrameDataWithOffset(frame, crop, flattenLayers, frameIndex));
        frames.push({
          data: frameData.data,
          offsetX: frameData.offsetX,
          offsetY: frameData.offsetY,
          gridSize: frameData.grid,
          layerIndex: 0,
          frameIndex: frameIndex,
          frameWidth: frameData.width,
          frameHeight: frameData.height,
        });
      }
    });
  } else {
    getState().data.layers.forEach((layer, layerIndex) => {
      layer.pixelData.forEach((frame, frameIndex) => {
        if (frame.length > 0) {
          const frameData = dispatch(getFrameDataWithOffset(frame, crop, flattenLayers));
          frames.push({
            data: frameData.data,
            offsetX: frameData.offsetX,
            offsetY: frameData.offsetY,
            gridSize: frameData.grid,
            layerIndex: layerIndex,
            frameIndex: frameIndex,
            frameWidth: frameData.width,
            frameHeight: frameData.height,
          });
        }
      });
    });
  }

  return frames;
};
