import React, { Component } from 'react';
import { View } from '../BaseComponents';
import { debugMode } from '../../utils/mocks';
import { connect } from 'react-redux';
import styles from '../styles/SpritesheetCanvas.module.scss';
import { setSpritesheetCanvasRef } from '../../store/actions/canvasActions';

class RawFrameCanvas extends Component {
  constructor(props) {
    super(props);
    this.spritesheetCanvas = React.createRef();
  }

  componentDidMount() {
    this.props.setCanvasRef(this.spritesheetCanvas.current);
  }

  render() {
    const { width } = this.props.screen;
    return (
      <View
        className={styles.container}
        style={{
          left: (window.innerWidth - width) / 2,
          top: (window.innerHeight - 25 - width) / 2,
          ...(debugMode && { zIndex: 100, opacity: 1 }),
        }}
      >
        <View
          style={{
            display: 'flex',
            left: (window.innerWidth - width) / 2,
            top: (window.innerHeight - width) / 2,
          }}
        >
          <canvas ref={this.spritesheetCanvas} id="spritesheetCanvas" width={width} height={width} />
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  scale: state.settings.scaleOffset,
  data: state.data,
  activeTool: state.tools.activeTool,
  activeColor: state.tools.activeColor,
  settings: state.settings,
});

export const mapDispatch = (dispatch) => ({
  setCanvasRef: (ref) => dispatch(setSpritesheetCanvasRef(ref)),
});

export default connect(mapState, mapDispatch)(RawFrameCanvas);
