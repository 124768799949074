import React from 'react';
import { View, Image, Text, TextInput, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Save.module.scss';
import layout from '../../config/layout';
import * as assets from '../../config/assets';
import { saveAnimation } from '../../store/actions/animationActions';
import { exportData } from '../../store/actions/';
import {
  signIn,
  signInSuccess,
  signOut,
  signOutSuccess,
  requestSave,
  requestCancel,
  hideSaveView,
  firebaseSave,
} from '../../store/actions/userActions';
import SignInProvider from '../../utils/SignInProvider';

class Save extends React.Component {
  constructor(props) {
    super(props);
    this.state = { text: '', isPublic: true, showSave: this.props.showSave };
    this.signInProvider = new SignInProvider();

    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.showSave && !nextProps.showSave) {
      return { text: '', isPublic: false };
    }
    return null;
  }

  saveToFirebase() {
    this.props.firebaseSave(this.state.text, this.state.isPublic);
  }

  renderPrivacyButtons() {
    return (
      <View className={styles.buttonWrapper}>
        <Button
          className={styles.btn}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: !this.state.isPublic ? 'white' : layout.buttonColor,
          }}
          onClick={() => {
            this.setState({ isPublic: false });
          }}
        >
          <Text className={styles.btnText} style={{ color: !this.state.isPublic ? '#000000' : '#CCCCCC' }}>
            PRIVATE
          </Text>
        </Button>
        <Button
          disabled={this.state.isPublic}
          className={styles.btn}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: !this.state.isPublic ? layout.buttonColor : 'white',
          }}
          onClick={() => {
            this.setState({ isPublic: true });
          }}
        >
          <Text className={styles.btnText} style={{ color: !this.state.isPublic ? '#CCCCCC' : '#000000' }}>
            PUBLIC
          </Text>
        </Button>
      </View>
    );
  }

  renderSaveCancel() {
    return (
      <View className={styles.buttonWrapper}>
        <Button
          className={styles.btn}
          style={{ backgroundColor: layout.cancelBtnColor }}
          onClick={() => {
            this.signInProvider.signOut(
              () => {
                // console.log("sign out");
              },
              () => {
                // console.log(error);
              }
            );

            this.props.cancelSave();
          }}
        >
          <Text className={styles.btnText}>CANCEL</Text>
        </Button>
        <Button
          disabled={this.state.text === ''}
          className={styles.btn}
          onClick={() => {
            if (this.state.text !== '') {
              this.saveToFirebase();
            }
          }}
        >
          <View style={{ opacity: this.state.text !== '' ? 1 : 0.5 }}>
            <Text className={styles.btnText}>SAVE</Text>
          </View>
        </Button>
      </View>
    );
  }

  renderDone() {
    return (
      <View className={styles.buttonWrapper} style={{ justifyContent: 'center' }}>
        <Button
          className={styles.btn}
          onClick={() => {
            this.props.hideSaveView();
          }}
        >
          <Text className={styles.btnText}>CLOSE</Text>
        </Button>
      </View>
    );
  }

  renderSaveDialog() {
    const { screen } = this.props;
    return this.props.showSave ? (
      <View
        className={styles.c}
        style={{
          opacity: this.props.showSave ? 1 : 0,
          width: 400,
          height: 500,
          left: screen.orgW / 2 - 210,
          top: this.props.showSave ? screen.orgH / 2 - 250 : screen.orgH,
        }}
      >
        <View className={styles.container} style={{ width: 400, height: 500 }}>
          <View className={styles.header}>
            <Image src={assets.logo} className={styles.logo} />
            <Text className={styles.headerTitle}>Save</Text>
            <Text className={styles.headerText}>
              {this.props.animation.animationUID ? '...what a creative name!' : 'Name your work:'}
            </Text>
            <TextInput
              disabled={this.props.animation.animationUID}
              placeholder="...dungeon bat"
              className={styles.inputText}
              style={{
                fontSize: window.getComputedStyle(document.documentElement).getPropertyValue('--big-size'),
                borderColor: this.props.animation.animationUID ? 'transparent' : 'gray',
              }}
              onChange={this.handleChange}
              value={this.state.text}
            />
          </View>
          {!this.props.animation.animationUID && (
            <View style={{ marginTop: 20, marginBottom: 20 }}>{this.renderPrivacyButtons()}</View>
          )}

          {!this.props.animation.animationUID ? this.renderSaveCancel() : this.renderDone()}
        </View>
      </View>
    ) : null;
  }

  handleChange(event) {
    this.setState({ text: event.target.value });
  }

  render() {
    return this.props.showSave ? this.renderSaveDialog() : null;
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showLoginScreen: state.user.showLoginScreen,
  showSave: state.data.showSaveView,
  signInAndSave: state.user.signInAndSave,
  signedIn: state.user.signedIn,
  animation: state.data.animation,
});

const mapDispatch = (dispatch) => ({
  signIn: () => dispatch(signIn()),
  signInSuccess: (user) => dispatch(signInSuccess(user)),
  signOut: () => dispatch(signOut()),
  signOutSuccess: () => dispatch(signOutSuccess()),
  requestSave: () => dispatch(requestSave()),
  generateData: () => dispatch(exportData()),
  cancelSave: () => dispatch(requestCancel()),
  saveAnimation: (uid) => dispatch(saveAnimation(uid)),
  hideSaveView: () => dispatch(hideSaveView()),
  firebaseSave: (name, isPublic) => dispatch(firebaseSave(name, isPublic)),
});

export default connect(mapState, mapDispatch)(Save);
