import React, { Component } from 'react';
import { View, Text } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/LoadView.module.scss';

class LoadView extends Component {
  renderLoadContainer() {
    const { width, orgW, orgH } = this.props.screen;
    return (
      <View className={styles.container}>
        <View className={styles.overlay} style={{ width: orgW, height: orgH }}>
          <View
            className={styles.loadContainer}
            style={{ width: width, height: width, left: orgW / 2 - width / 2, top: orgH / 2 - width / 2 - 50 }}
          >
            <Text className={styles.loadText} style={{ width: width }}>
              <div style={{ paddingLeft: 20 }}>{this.props.loadString}</div>
            </Text>
            <View
              className={styles.bar}
              style={{
                width: width,
              }}
            >
              <View
                className={styles.progressBar}
                style={{
                  width: width * this.props.loadPercentage,
                }}
              />
            </View>
          </View>
        </View>
      </View>
    );
  }

  render() {
    return this.props.show ? this.renderLoadContainer() : null;
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  show: state.settings.showLoad,
  loadString: state.settings.loadText,
  loadPercentage: state.settings.loadPercentage,
});

export default connect(mapState)(LoadView);
