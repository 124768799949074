import React, { Component } from 'react';
import { View } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/BackgroundCanvas.module.scss';
// import { THEME_TYPES } from '../../store/actions/types';
import { setAreaCanvasRef, setAreaValue, updateTempAreaValue } from '../../store/actions/canvasActions';

class AreaCanvas extends Component {
  constructor(props) {
    super(props);
    this.areaCanvas = React.createRef();

    this.state = {
      isHovering: false,
    };

    this.pressXY = null;
    this.hoverX = null;
    this.hoverY = null;

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  componentDidMount() {
    this.props.setCanvasRef(this.areaCanvas.current);
    const canvas = this.areaCanvas.current;

    canvas.addEventListener('mousedown', this.onMouseDown, false);
    canvas.addEventListener('mouseup', this.onMouseUp, false);
    canvas.addEventListener('mousemove', this.onMouseMove, false);
  }

  getCursorPosition(event) {
    const factor = 1 - this.props.scale;
    const canvas = this.areaCanvas.current;
    const rect = canvas.getBoundingClientRect();
    const isIos = this.iphone || this.ipad;
    const clientX = isIos ? event.touches[0].clientX : event.clientX;
    const clientY = isIos ? event.touches[0].clientY : event.clientY;
    const cX = clientX / factor;
    const cY = clientY / factor;
    const rectLeft = rect.left / factor;
    const rectTop = rect.top / factor;
    const x = cX - rectLeft;
    const y = cY - rectTop;
    const locX = x;
    const locY = y;
    const w = (this.props.screen.width / this.props.settings.gridSize) * this.props.settings.zoom;
    const h = (this.props.screen.width / this.props.settings.gridSize) * this.props.settings.zoom;
    const _x = Math.floor(locX / w);
    const _y = Math.floor(locY / h);
    return {
      x: _x,
      y: _y,
    };
  }

  getPixel(e) {
    return this.getCursorPosition(e);
  }

  onMouseDown(evt) {
    this.pressXY = {
      x: this.getPixel(evt).x,
      y: this.getPixel(evt).y,
    };

    this.props.setAreaValue(this.pressXY);
  }

  onMouseMove(evt) {
    if (this.props.areaDefineActive) {
      this.handleMove(evt);
    }
  }

  onMouseUp() {
    this.pressXY = null;
    this.setState({ isHovering: false });
  }

  handleMove(e) {
    const { x, y } = this.getPixel(e);
    if (this.x !== x) {
      this.x = x;
      this.updatePos();
    }
    if (this.y !== y) {
      this.y = y;
      this.updatePos();
    }
  }

  updatePos() {
    this.props.updateTempAreaValue({ x: this.x, y: this.y });
  }

  render() {
    const { width } = this.props.screen;
    return (
      <View
        className={styles.container}
        style={{
          left: (window.innerWidth - width) / 2,
          top: (window.innerHeight - 25 - width) / 2,
          width: width,
          height: width,
          background: 'rgba(255,0,0,0)',
          zIndex: 30000000000,
          pointerEvents: this.props.areaDefineActive ? 'auto' : 'none',
        }}
      >
        <canvas
          width={width}
          height={width}
          ref={this.areaCanvas}
          style={{ transform: `scale(${1.0 - this.props.scale})`, marginLeft: 0, marginTop: 0 }}
        />
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  scale: state.settings.scaleOffset,
  gridSize: state.settings.gridSize,
  settings: state.settings,
  areaDefineActive: state.settings.areaDefineActive,
});

export const mapDispatch = (dispatch) => ({
  setCanvasRef: (ref) => dispatch(setAreaCanvasRef(ref)),
  setAreaValue: (val) => dispatch(setAreaValue(val)),
  updateTempAreaValue: (val) => dispatch(updateTempAreaValue(val)),
});

export default connect(mapState, mapDispatch)(AreaCanvas);
