import React from 'react';
import { View, Text } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Statusbar.module.scss';
import { shouldBlur } from '../../store/selectors';
import { selectColor } from '../../store/actions/toolsActions';

class StatusBottomBar extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.gridSize !== this.props.gridSize ||
      nextProps.zoom !== this.props.zoom ||
      nextProps.screen !== this.props.screen ||
      nextProps.fps !== this.props.fps ||
      nextProps.isAnimating !== this.props.isAnimating ||
      nextProps.animationFrame !== this.props.animationFrame ||
      nextProps.blur !== this.props.blur ||
      nextProps.layers !== this.props.layers ||
      nextProps.currentFrame !== this.props.currentFrame ||
      nextProps.currentLayer !== this.props.currentLayer
    ) {
      return true;
    }

    return false;
  }
  renderStatusBar() {
    const totalFrames = this.props.layers.length > 0 ? this.props.layers[this.props.currentLayer].pixelData.length : 1;
    const { theme } = this.props;
    const th = theme.themes[theme.current];
    const { blur, gridSize, zoom } = this.props;

    return (
      <View
        style={{
          position: 'absolute',
          left: this.props.screen.orgW * 0.4,
          bottom: 4,
          width: this.props.screen.orgW * 0.6,
          height: 25,
          zIndex: 300000,
          padding: 0,
          backgroundColor: th.statusBar,
          display: 'flex',
          flexDirection: 'row',
          filter: blur ? 'blur(5px)' : 'blur(0px)',
        }}
      >
        <View className={styles.fpsBox} style={{ backgroundColor: th.statusBarFPSBg }}>
          <Text className={styles.fpsBoxText}>{`SIZE - ${gridSize} x ${gridSize} - ZOOM: ${zoom} / 3`}</Text>
        </View>
        <View className={styles.frameIndicator} style={{ backgroundColor: th.statusBarFramesBg }}>
          <Text className={styles.frameIndicatorText} style={{ color: th.frameIndicatorText }}>
            <Text className={styles.frameIndicatorTextCurrent} style={{ color: th.frameIndicatorTextCurrent }}>
              {this.props.isAnimating ? `${this.props.animationFrame + 1} ` : `${this.props.currentFrame + 1} `}
            </Text>
            <Text>{`/ ${totalFrames}`}</Text>
          </Text>
        </View>
        <View className={styles.fpsBox} style={{ backgroundColor: th.statusBarFPSBg }}>
          <Text className={styles.fpsBoxText}>{`${this.props.fps} FPS`}</Text>
        </View>
      </View>
    );
  }

  render() {
    return this.props.expanded === false && this.renderStatusBar();
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  fps: state.settings.fps,
  isAnimating: state.settings.animate,
  animationFrame: state.settings.currentAnimationFrame,
  blur: shouldBlur(state),
  expanded: state.settings.expanded,
  layers: state.data.layers,
  currentFrame: state.data.currentFrame,
  currentLayer: state.data.currentLayer,
  theme: state.theme,
  zoom: state.settings.zoom,
  gridSize: state.settings.gridSize,
});

const mapDispatch = (dispatch) => ({
  setColor: (color) => dispatch(selectColor(color)),
});

export default connect(mapState, mapDispatch)(StatusBottomBar);
