/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as assets from '../../config/assets';
import styles from '../styles/TopSection.module.scss';
import { exportData } from '../../store/actions/';
import { showFrames, showSettingsView, toggleTheme } from '../../store/actions/uiActions';
import { undo } from '../../store/actions/toolsActions';
import { generateGIFOnSave } from '../../store/actions/userActions';
import { THEME_TYPES } from '../../store/actions/types';
import { shouldBlur } from '../../store/selectors';
import { View, Button, Image } from '../BaseComponents';
import { fakeData } from '../../utils/mocks';
import { loadFakeData } from '../../store/actions/';

class TopSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fps: this.props.fps || 4,
    };
  }

  getDiscIcon() {
    const { animation, edits } = this.props;
    if (animation.animationUID) {
      return edits === 0 ? assets.saved : assets.unsaved;
    }
    return edits === 0 ? assets.noedits : assets.unsaved;
  }

  render() {
    const w = (window.innerHeight + 20) / 3;
    const { theme, blur } = this.props;
    const light = theme.current === THEME_TYPES.LIGHT_THEME;
    return (
      <View className={styles.container} style={{ filter: blur ? 'blur(5px)' : 'blur(0px)' }}>
        <View className={styles.directionContainer}>
          <View
            style={{
              display: 'flex',
              width: w,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <View
              className={styles.framesBtn}
              description="frames view"
              position={{ marginTop: 50 }}
              track={{ category: 'Interface', action: 'Open frames view' }}
              onClick={() => {
                // this.props.showFramesView();
              }}
            >
              <Image alt="" src={light ? assets.L_frames : assets.frames} className={styles.framesBtnImage} />
            </View>

            {/* <Button
              disabled={!this.props.undoEnabled}
              className={styles.framesBtn}
              description="undo"
              position={{ marginTop: 50 }}
              track={{ category: 'Interface', action: 'Undo' }}
              onClick={() => {
                this.props.undo();
              }}
            >
              <Image
                alt=""
                src={assets.undo}
                className={styles.framesBtnImage}
                style={{ opacity: this.props.undoEnabled ? 1 : 0.25 }}
              />
            </Button> */}
          </View>

          <View
            style={{ display: 'flex', width: w, alignItems: 'center', justifyContent: 'center' }}
            track={{ category: 'Interface', action: 'Click on Spritelove logo' }}
          >
            <Image alt="" src={assets.logo} className={styles.logo} />
          </View>
          <div style={{ position: 'absolute', left: this.props.screen.orgW / 2 + 140, top: 40 }}>
            <Button
              className={styles.layersBtn}
              description="load demo animation"
              position={{ marginTop: 30 }}
              track={{ category: 'Interface', action: 'Load demo animation' }}
              onClick={() => {
                this.props.loadFake(fakeData);
              }}
            >
              <div className={styles.loadBtn}>load demo</div>
            </Button>
          </div>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: w,
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              className={styles.layersBtn}
              disabled={this.props.edits === 0}
              description="save"
              position={{ marginTop: 50 }}
              track={{ category: 'Interface', action: 'Request save' }}
              onClick={() => {
                this.props.requestSave();
              }}
            >
              <Image
                alt=""
                src={this.getDiscIcon()}
                className={styles.layersBtnImage}
                style={{
                  marginLeft: -10,
                  opacity: !this.props.animation.animationUID && this.props.edits === 0 ? 0.2 : 1,
                }}
              />
            </Button>

            <Button
              className={styles.layersBtn}
              description="settings"
              position={{ marginTop: 50 }}
              track={{ category: 'Interface', action: 'Show settings view' }}
              onClick={() => {
                this.props.showSettingsView();
              }}
            >
              <Image
                alt=""
                src={light ? assets.settings : assets.settings} // src={light ? assets.settingsSel : assets.settings}
                className={styles.layersBtnImage}
              />
            </Button>
          </View>
        </View>
      </View>
    );
  }
}

const mapState = (state) => ({
  fps: state.settings.fps,
  data: state.data,
  screen: state.settings.screen,
  settings: state.settings.showSettings,
  animation: state.data.animation,
  blur: shouldBlur(state),
  edits: state.data.changesSinceLastSave,
  theme: state.theme,
  undoEnabled: state.data.priorData.length > 0,
});
const mapDispatch = (dispatch) => ({
  showFramesView: () => dispatch(showFrames()),
  showSettingsView: () => dispatch(showSettingsView()),
  export: () => dispatch(exportData(true)),
  requestSave: () => dispatch(generateGIFOnSave()),
  undo: () => dispatch(undo()),
  toggleTheme: () => dispatch(toggleTheme()),
  loadFake: (data) => dispatch(loadFakeData(data)),
});

export default connect(mapState, mapDispatch)(TopSection);
