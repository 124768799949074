import React, { Component } from 'react';
import { Text, Image, View, Button } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/Layers.module.scss';
import {
  deleteLayer,
  selectLayer,
  closeLayerOpacity,
  showLayerOpacity,
  setLayerOpacityIndex,
  setLayerOpacity,
} from '../../store/actions/uiActions';
import * as assets from '../../config/assets';
import LayerOpacitySelector from './LayerOpacitySelector';

class Layers extends Component {
  getLayerStyle(index) {
    return index === this.props.data.currentLayer
      ? { color: 'white' }
      : { color: this.props.light ? 'white' : 'white' };
  }

  renderLayers() {
    const { showSortLayersView, theme, showSetLayerOpacity, opacityLayerIndex } = this.props;
    // const opacity = this.props.layers[this.props.opacityLayerIndex].opacity || 100;

    const layers = this.props.data.layers.map((layer, index) => {
      const layerOpacity = this.props.layers[index].opacity || 100;
      return (
        <View
          key={`layer_${index}`}
          className={styles.layer}
          style={{ borderRightColor: this.props.light ? '#444' : '#444' }}
        >
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 100,
              opacity: showSetLayerOpacity && opacityLayerIndex === index ? 1 : 0,
              pointerEvents: showSetLayerOpacity && opacityLayerIndex === index ? 'auto' : 'none',
            }}
          >
            <LayerOpacitySelector
              theme={theme}
              currentOpacity={layerOpacity}
              setOpacity={(e) => {
              console.log('set opacity event', e) // eslint-disable-line
                this.props.setLayerOpacity(e);
              }}
              close={() => this.props.closeLayerOpacity()}
            />
          </View>
          <Button
            className={styles.layerSelector}
            style={{
              borderColor: this.props.light ? '#444' : '#444',
              backgroundColor: index === this.props.data.currentLayer ? 'rgb(16, 16, 16)' : 'transparent',
            }}
            onClick={() => this.props.selectLayer(index)}
          >
            <Text
              style={{
                ...this.getLayerStyle(index),
                position: 'absolute',
                zIndex: 99,
                background: index === this.props.data.currentLayer ? 'black' : '#303030',
                padding: 4,
              }}
            >
              {index + 1}
            </Text>
            <View
              className={index === this.props.data.currentLayer ? styles.opacityOverlaySelected : styles.opacityOverlay}
              style={{
                opacity: layerOpacity !== 100 ? 1 : 1,
                width: `calc(100% * ${layerOpacity / 100})`,
              }}
            ></View>
          </Button>
          <Button
            className={styles.opacityLayer}
            onClick={() => {
              this.props.setLayerOpacityIndex(index);
              setTimeout(() => {
                this.props.showLayerOpacity();
              }, 0);
            }}
            description="opacity"
            position={{ marginLeft: -20 }}
          >
            <Image src={assets.opacity} className={styles.btnImage} />
          </Button>
          <Button
            className={styles.deleteLayer}
            onClick={() => this.props.deleteLayer(index)}
            description="delete"
            position={{ marginLeft: -20 }}
          >
            <Image src={assets.del} className={styles.btnImage} />
          </Button>
        </View>
      );
    });

    return (
      <View
        className={styles.container}
        style={{
          opacity: showSortLayersView ? 0.3 : 1,
          pointerEvents: showSortLayersView ? 'none' : 'auto',
        }}
      >
        {layers}
      </View>
    );
  }

  render() {
    return this.renderLayers();
  }
}

const mapState = (state) => ({
  data: state.data,
  theme: state.theme,
  showSortLayersView: state.settings.showLayerSortView,
  showSetLayerOpacity: state.data.showSetLayerOpacity,
  opacityLayerIndex: state.data.opacityLayerIndex,
  layers: state.data.layers,
});

const mapDispatch = (dispatch) => ({
  selectLayer: (index) => dispatch(selectLayer(index)),
  deleteLayer: (index) => dispatch(deleteLayer(index)),
  setLayerOpacityIndex: (index) => dispatch(setLayerOpacityIndex(index)),
  showLayerOpacity: () => dispatch(showLayerOpacity()),
  closeLayerOpacity: () => dispatch(closeLayerOpacity()),
  setLayerOpacity: (val) => dispatch(setLayerOpacity(val)),
});

export default connect(mapState, mapDispatch)(Layers);
