import { COMMON_TYPES } from './types';

import { toggleResumeAnimationAfterAction, startAnimation, stopAnimation } from './animationActions';
import { updateDrawCanvas, updateBgCanvas } from './canvasActions';
import { selectDraw } from './toolsActions';

export const moveCamera = (direction) => (dispatch, getState) => {
  const settings = getState().settings;
  // console.log('move camera, action:', settings.zoom > 1)
  let tempCameraX = settings.cameraX;
  let tempCameraY = settings.cameraY;
  const cameraStep = 1;

  const box = 150;
  const zoomSquare = box / settings.zoom;
  const stepX = box / settings.gridSize;
  const stepY = box / settings.gridSize;
  const currentXSpace = stepX * tempCameraX + zoomSquare;
  const currentYSpace = stepY * tempCameraY + zoomSquare;

  if (settings.zoom > 1) {
    if (direction === 'right') {
      tempCameraX = currentXSpace + (stepX * cameraStep - 1) < box ? settings.cameraX + cameraStep : tempCameraX;
    } else if (direction === 'left') {
      tempCameraX = settings.cameraX - cameraStep > 0 ? settings.cameraX - cameraStep : 0;
    } else if (direction === 'up') {
      tempCameraY = settings.cameraY - cameraStep > 0 ? settings.cameraY - cameraStep : 0;
    } else if (direction === 'down') {
      tempCameraY = currentYSpace + (stepY * cameraStep - 1) < box ? settings.cameraY + cameraStep : tempCameraY;
    }

    dispatch(updateCameraPosition(tempCameraX, tempCameraY));
    dispatch(updateDrawCanvas());
  }
};

export const setCameraPosition = (x, y) => (dispatch) => {
  dispatch(updateCameraPosition(x, y));
  dispatch(updateDrawCanvas());
};

export const updateCameraPosition = (x, y) => ({
  type: COMMON_TYPES.UPDATE_CAMERA_POSITON,
  payload: { cameraX: x, cameraY: y },
});

export const zoomCamera = (arg) => (dispatch, getState) => {
  const settings = getState().settings;
  let zoom;
  let shouldResetCamera = false;
  if (arg === 'in') {
    dispatch(selectDraw());
    if (settings.zoom < 4) {
      zoom = settings.zoom + 1;
    }
  } else {
    if (settings.zoom > 2) {
      zoom = settings.zoom - 1;
    } else {
      shouldResetCamera = true;
      zoom = 1;
    }
  }

  dispatch(updateCameraPosition(0, 0));

  if (shouldResetCamera && zoom === 1) {
    dispatch(resetCamera());
  } else {
    if (settings.zoom === 1 && zoom === 2) {
      dispatch(updateCameraPosition(8, 8));
    }
    if (getState().settings.animate) {
      dispatch(toggleResumeAnimationAfterAction());
      dispatch(stopAnimation());
    }
    dispatch({ type: COMMON_TYPES.SET_ZOOM, payload: zoom });
  }

  dispatch(updateBgCanvas());
  dispatch(updateDrawCanvas());
};

export const resetCamera = () => (dispatch, getState) => {
  dispatch({ type: COMMON_TYPES.RESET_CAMERA });
  if (getState().settings.resumeAnimation) {
    dispatch(toggleResumeAnimationAfterAction());
    dispatch(startAnimation());
  }
};
