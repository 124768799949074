import React from 'react';
import { View, Button, Text, Image } from '../BaseComponents';
import { connect } from 'react-redux';
import styles from '../styles/SignIn.module.scss';
import * as assets from '../../config/assets';
import SignInProvider from '../../utils/SignInProvider';
import {
  signIn,
  signInSuccess,
  signOut,
  signOutSuccess,
  requestSave,
  fetchUserData,
  firebaseSignInSuccess,
} from '../../store/actions/userActions';
import { showSettingsView } from '../../store/actions/uiActions';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.signInProvider = new SignInProvider();
  }

  componentDidMount() {
    SignInProvider.setup();
  }

  handleGoogleSignIn() {
    this.props.signIn();
    this.signInProvider.signInWithGoogle(
      (data) => {
        // google
        this.props.signInSuccess(data);
      },
      (firebaseData, isOldUser = false) => {
        // firebase
        if (this.props.signInAndSave) {
          // show save view
          this.props.requestSave();
        }
        if (!this.props.signInAndSave && this.props.showProfileAfterSignIn) {
          this.props.showSettingsView();
        }
        if (isOldUser) {
          this.props.fetchUserData();
        }

        // stuff
        this.props.firebaseSignInSuccess(firebaseData);
      },
        err => { // eslint-disable-line
        // show base screen
      }
    );
  }

  getBtnContainerStyles() {
    if (this.props.user) {
      return styles.buttonContainer + ' ' + styles.buttonContainerAnimated;
    }

    return styles.buttonContainer;
  }

  handleGoogleSignOut() {
    const { signOut, signOutSuccess } = this.props;

    this.signInProvider.signOut(
      () => {
        signOut();
        this.setState({ user: null });
        signOutSuccess();
      },
      error => { //eslint-disable-line
        // console.log(error); //eslint-disable-line
      }
    );
  }

  renderSignIn() {
    return (
      <View
        className={styles.c}
        style={{
          left: this.props.screen.orgW / 2 - 210,
          opacity: this.props.showLoginScreen ? 1 : 0,
          top: this.props.showLoginScreen ? this.props.screen.orgH / 2 - 250 : this.props.screen.orgH,
        }}
      >
        <View className={styles.container}>
          <View className={styles.header}>
            {/* <Button onClick={() => this.handleGoogleSignOut()} >
              
            </Button> */}
            <Image src={assets.logo} className={styles.logo} />
            <Text className={styles.headerTitle}>
              {this.props.user && this.props.user.user ? 'Welcome' : 'Sign in'}
            </Text>
            <Text className={styles.headerText}>
              {this.props.user && this.props.user.user
                ? this.props.user.user.displayName
                : 'Sign in with your Google account to save your sprites and animations'}
            </Text>
          </View>
          <View className={styles.bottomWrapper}>
            <View className={this.getBtnContainerStyles()}>
              {this.props.user && this.props.user.user ? (
                <View
                  className={styles.avatar}
                  style={{
                    borderColor: 'white',
                  }}
                >
                  <Image
                    src={this.props.user.user.photoURL}
                    style={{
                      width: 150,
                      height: 150,
                    }}
                  />
                </View>
              ) : (
                <View className={styles.signInWrapper} style={{ zIndex: 101, top: 'calc(50% - 25)' }}>
                  <Button
                    className={styles.btn}
                    onClick={this.handleGoogleSignIn.bind(this)}
                    description="sign in"
                    track={{ category: 'Interface', action: 'Sign in' }}
                    position={{ marginTop: 30 }}
                  >
                    <Text className={styles.btnText}>SIGN IN</Text>
                  </Button>
                </View>
              )}
            </View>

            {/* <View
              className={styles.signInWrapper}
              style={{
                opacity: 0,
                zIndex: 100,
                bottom: 10
              }}
            >
              <Button
                className={styles.btn}
                style={{ backgroundColor: '#181818' }}
                onClick={() => this.handleGoogleSignOut()}
              >
                <Text className={styles.btnText}>SIGN OUT</Text>
              </Button>
            </View> */}
          </View>
        </View>
      </View>
    );
  }

  render() {
    return this.props.showLoginScreen ? (!this.props.showSave ? this.renderSignIn() : null) : null;
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  showLoginScreen: state.user.showLoginScreen,
  signInAndSave: state.user.signInAndSave,
  showSave: state.data.showSaveView,
  showProfileAfterSignIn: state.user.showProfileAfterSignIn,
  signedIn: state.user.signedIn,
  firebaseRef: state.user.firebaseRef,
  user: state.user.user,
  theme: state.theme,
});

const mapDispatch = (dispatch) => ({
  signIn: () => dispatch(signIn()),
  signInSuccess: (user) => dispatch(signInSuccess(user)),
  signOut: () => dispatch(signOut()),
  signOutSuccess: () => dispatch(signOutSuccess()),
  requestSave: () => dispatch(requestSave()),
  showSettingsView: () => dispatch(showSettingsView()),
  fetchUserData: () => dispatch(fetchUserData()),
  firebaseSignInSuccess: (firebaseData) => dispatch(firebaseSignInSuccess(firebaseData)),
});

export default connect(mapState, mapDispatch)(SignIn);
