import { COMMON_TYPES, UI_TYPES } from './types';

export const startAnimation = () => ({ type: COMMON_TYPES.START_ANIMATION });
export const stopAnimation = () => ({ type: COMMON_TYPES.STOP_ANIMATION });
export const setFPS = (fps) => ({ type: COMMON_TYPES.SET_FPS, payload: fps });

export const updateAnimationFrame = (frame) => ({
  type: UI_TYPES.UPDATE_ANIMATION_FRAME,
  payload: frame,
});
export const saveAnimation = (animation) => ({
  type: COMMON_TYPES.SAVE_ANIMATION,
  payload: animation,
});
export const toggleResumeAnimationAfterAction = () => ({
  type: COMMON_TYPES.RESUME_ANIMATION,
});
