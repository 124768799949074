import React from 'react';
import { Provider } from 'react-redux';
import SpriteLove from './Spritelove';
import configureStore from './store';
import './components/styles/Main.module.scss';

const { store } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <div style={{ display: 'flex' }}>
        <SpriteLove />
      </div>
    </Provider>
  );
}

export default App;
