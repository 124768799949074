import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  generateSpriteData,
  saveCurrentPixelData,
  setDrawCanvasRef,
  setPixels,
  setCanvasHover,
} from '../../store/actions/canvasActions';
import { selectColor } from '../../store/actions/toolsActions';
import { BRUSH_TYPES, TOOL_TYPES } from '../../store/actions/types';
import { toolMode, validDrawToolSelected } from '../../utils';
import { brushLarge, brushTiltDu, brushTiltUd } from '../../utils/brushes';
import { fillPixels } from '../../utils/fillPixels';
import { generateLineFromPoints } from '../../utils/math';
import { normalizePixels } from '../../utils/normalizePixels';
import { reducePixels } from '../../utils/reducePixels';
import { View } from '../BaseComponents';
import styles from '../styles/DrawCanvas.module.scss';

class DrawCanvas extends Component {
  constructor(props) {
    super(props);

    this.drawCanvas = React.createRef();

    this.state = {
      isDrawing: false,
      isFilling: false,
    };

    this.iphone = window.navigator.userAgent.match('iPhone') || window.navigator.userAgent.match('iPod') ? true : false;
    this.ipad = window.navigator.userAgent.match('iPad') ? true : false;

    // keep track of last pixels
    this.lastPixel = null;
    this.lastMirroredPxHorisontal = null;
    this.lastMirroredPxVertical = null;
    this.lastMirroredPxVerticalHorisontal = null;
    // store stream of unique pixels for state processing
    this.pixelStream = [];
    this.localSet = [];

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.updateLocalSet = this.updateLocalSet.bind(this);
  }

  componentDidMount() {
    const canvas = this.drawCanvas.current;
    this.props.setCanvasRef(canvas);
    this.updateLocalSet(this.props);

    if (this.iphone || this.ipad) {
      canvas.addEventListener('touchstart', this.onMouseDown, false);
      canvas.addEventListener('touchend', this.onMouseUp, false);
      canvas.addEventListener('touchmove', this.onMouseMove, false);
    } else {
      canvas.addEventListener('mousedown', this.onMouseDown, false);
      canvas.addEventListener('mouseup', this.onMouseUp, false);
      canvas.addEventListener('mousemove', this.onMouseMove, false);
    }

    // canvas.addEventListener('mouseover', () => {
    //   this.props.hover(true);
    // });

    // canvas.addEventListener('mouseout', () => {
    //   this.props.hover(false);
    // });
  }

  onMouseDown(evt) {
    this.pixelStream = [];
    this.handleTouch(evt);
  }

  onMouseMove(evt) {
    if (this.state.isDrawing) {
      this.handleTouch(evt);
    }
  }

  onMouseLeave() {
    this.onDragEnd();
  }

  onMouseUp() {
    this.onDragEnd();
  }

  onDragEnd() {
    // reset pixels
    this.lastPixel = null;
    this.lastMirroredPxHorisontal = null;
    this.lastMirroredPxVertical = null;
    this.lastMirroredPxVerticalHorisontal = null;
    this.onRelease();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data.layers[prevProps.data.currentLayer].pixelData.length >= 1 &&
      this.props.data.layers[this.props.data.currentLayer].pixelData.length === 0
    ) {
      this.resetLocal();
    } else {
      if (this.props.frame !== prevProps.frame || prevProps.data.currentLayer !== this.props.data.currentLayer) {
        // load reduced pixels and transform them
        this.updateLocalSet(this.props);
      } else if (prevProps.loading === true && this.props.loading === false) {
        // update local set when loading an animation or single frame
        this.updateLocalSet(this.props);
      } else if (this.props.data.combinedRaw !== prevProps.data.combinedRaw) {
        this.updateLocalSet(this.props);
      } else {
        return null;
      }
    }
  }

  resetLocal() {
    this.lastPixel = null;
    this.lastMirroredPxHorisontal = null;
    this.lastMirroredPxVertical = null;
    this.lastMirroredPxVerticalHorisontal = null;
    this.pixelStream = [];
    this.localSet = [];
  }

  // localSet:
  // create local pixel set from state when the current active frame in state changes,
  // parses the received payload (a set of reduced pixels) and normalizes it to a pixel by pixel array.
  // when dragging - update this set using our push / replace and delete methods.
  // when releasing - run reducePixels and overwrite global redux state.
  updateLocalSet(props) {
    try {
      this.localSet = normalizePixels(props.data.layers[props.data.currentLayer].pixelData[props.data.currentFrame]);
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log('updateLocalSet error', error);
    }
  }

  getCursorPosition(event) {
    const factor = 1 - this.props.scale;
    const canvas = this.drawCanvas.current;
    const rect = canvas.getBoundingClientRect();
    const isIos = this.iphone || this.ipad;
    const clientX = isIos ? event.touches[0].clientX : event.clientX;
    const clientY = isIos ? event.touches[0].clientY : event.clientY;
    const cX = clientX / factor;
    const cY = clientY / factor;
    const rectLeft = rect.left / factor;
    const rectTop = rect.top / factor;
    const x = cX - rectLeft;
    const y = cY - rectTop;
    return { x, y };
  }

  handleTouch(e) {
    if (!validDrawToolSelected(this.props)) {
      return;
    }

    if (!this.state.isDrawing) {
      this.setState({ isDrawing: true });
    }

    const { x, y } = this.getCursorPosition(e);

    const locX = x;
    const locY = y;
    const w = (this.props.screen.width / this.props.settings.gridSize) * this.props.settings.zoom;
    const h = (this.props.screen.width / this.props.settings.gridSize) * this.props.settings.zoom;
    const _x = Math.floor(locX / w);
    const _y = Math.floor(locY / h);
    const { activeTool, activeColor } = this.props;

    // mode
    const { drawMode, eraseMode, colorPickMode, mirrorMode, fillMode } = toolMode(activeTool);
    // negative x of touch
    const _xn = mirrorMode ? this.props.settings.gridSize - 1 - _x : null;
    const _yn = mirrorMode ? this.props.settings.gridSize - 1 - _y : null;

    let points = [];
    if (drawMode || eraseMode) {
      const { brush } = this.props;

      if (brush === BRUSH_TYPES.BRUSH_SMALL) {
        points = this.modeDrawEraseBrushSmall(_x, _y);
      } else if (brush === BRUSH_TYPES.BRUSH_LARGE) {
        points = this.modeDrawEraseBrushLarge(_x, _y);
      } else if (brush === BRUSH_TYPES.BRUSH_TILT_DU) {
        points = this.modeDrawEraseBrushTiltDu(_x, _y);
      } else if (brush === BRUSH_TYPES.BRUSH_TILT_UD) {
        points = this.modeDrawEraseBrushTiltUd(_x, _y);
      }
    } else if (mirrorMode) {
      points = this.modeMirror(_x, _xn, _y, _yn);
    } else if (colorPickMode) {
      this.modePickColor(_x, _y, activeColor);
    } else if (fillMode && !this.state.isFilling) {
      points = this.modeFill(_x, _y, activeColor);
      this.setState({ isFilling: true });
    }

    this.updateCanvas(points, w, h);
  }

  // modes
  modeDrawEraseBrushSmall(_x, _y) {
    const { gridSize } = this.props;
    let points = null;

    this.lastPixel = this.lastPixel ? this.lastPixel : { x: _x, y: _y };
    points = generateLineFromPoints(this.lastPixel.x, this.lastPixel.y, _x, _y, gridSize);

    this.addToStream(points);
    this.lastPixel = { x: _x, y: _y };

    return points;
  }

  modeDrawEraseBrushLarge(_x, _y) {
    const { gridSize } = this.props;
    this.lastPixel = this.lastPixel ? this.lastPixel : { x: _x, y: _y };
    const points = generateLineFromPoints(this.lastPixel.x, this.lastPixel.y, _x, _y, gridSize);
    const modifiedPoints = brushLarge(points);

    this.addToStream(modifiedPoints);
    this.lastPixel = { x: _x, y: _y };

    return modifiedPoints;
  }

  modeDrawEraseBrushTiltDu(_x, _y) {
    const { gridSize } = this.props;
    this.lastPixel = this.lastPixel ? this.lastPixel : { x: _x, y: _y };
    const points = generateLineFromPoints(this.lastPixel.x, this.lastPixel.y, _x, _y, gridSize);
    const modifiedPoints = brushTiltDu(points);

    this.addToStream(modifiedPoints);
    this.lastPixel = { x: _x, y: _y };

    return modifiedPoints;
  }

  modeDrawEraseBrushTiltUd(_x, _y) {
    const { gridSize } = this.props;
    this.lastPixel = this.lastPixel ? this.lastPixel : { x: _x, y: _y };
    const points = generateLineFromPoints(this.lastPixel.x, this.lastPixel.y, _x, _y, gridSize);
    const modifiedPoints = brushTiltUd(points);

    this.addToStream(modifiedPoints);
    this.lastPixel = { x: _x, y: _y };

    return modifiedPoints;
  }

  modeMirror(_x, _xn, _y, _yn) {
    const { gridSize, mirrorX, mirrorY } = this.props;
    let points = null;
    this.lastPixel = this.lastPixel ? this.lastPixel : { x: _x, y: _y };
    this.lastMirroredPxHorisontal = this.lastMirroredPxHorisontal ? this.lastMirroredPxHorisontal : { x: _xn, y: _y };
    this.lastMirroredPxVertical = this.lastMirroredPxVertical ? this.lastMirroredPxVertical : { x: _x, y: _yn };
    this.lastMirroredPxVerticalHorisontal = this.lastMirroredPxVerticalHorisontal
      ? this.lastMirroredPxVerticalHorisontal
      : { x: _xn, y: _yn };

    points = generateLineFromPoints(this.lastPixel.x, this.lastPixel.y, _x, _y, gridSize);
    this.lastPixel = { x: _x, y: _y };

    if (mirrorX) {
      points = points.concat(
        generateLineFromPoints(this.lastMirroredPxHorisontal.x, this.lastMirroredPxHorisontal.y, _xn, _y, gridSize)
      );
      this.lastMirroredPxHorisontal = { x: _xn, y: _y };
    }

    if (mirrorY) {
      points = points.concat(
        generateLineFromPoints(
          this.lastMirroredPxVertical.x,
          this.lastMirroredPxVertical.y,
          this.lastMirroredPxVertical.x,
          this.lastMirroredPxVertical.y,
          gridSize
        )
      );
      this.lastMirroredPxVertical = { x: _x, y: _yn };

      points = points.concat(
        generateLineFromPoints(
          this.lastMirroredPxVerticalHorisontal.x,
          this.lastMirroredPxVerticalHorisontal.y,
          this.lastMirroredPxVerticalHorisontal.x,
          this.lastMirroredPxVerticalHorisontal.y,
          gridSize
        )
      );

      this.lastMirroredPxVerticalHorisontal = { x: _xn, y: _yn };
    }

    this.addToStream(points);
    return points;
  }

  modePickColor(_x, _y, activeColor) {
    const data = {
      x: _x + this.props.settings.cameraX,
      y: _y + this.props.settings.cameraY,
      color: activeColor,
    };
    const pixelIndex = this.localSet.findIndex((px) => px.x === data.x && px.y === data.y);
    if (pixelIndex !== -1) {
      this.props.setColor(this.localSet[pixelIndex].color);
    }
  }

  modeFill(_x, _y, activeColor) {
    const pixel = {
      x: _x + this.props.settings.cameraX,
      y: _y + this.props.settings.cameraY,
      color: activeColor,
    };

    const points = fillPixels(this.localSet, pixel);

    points.forEach((p) => {
      p.x -= this.props.settings.cameraX;
      p.y -= this.props.settings.cameraY;
    });

    this.addToStream(points);
    return points;
  }

  // push unique points to the pixel stream array
  addToStream(points) {
    points.forEach((point) => {
      if (this.pixelStream.findIndex((p) => p.x === point.x && p.y === point.y) === -1) {
        this.pixelStream.push(point);
      }
    });
  }

  // update canvas context (draw feedback)
  updateCanvas(points, w, h) {
    const { activeTool, activeColor } = this.props;
    const blur = 0;
    const ctx = this.drawCanvas.current.getContext('2d');
    ctx.fillStyle = activeColor;

    points.forEach((point) => {
      if (activeTool === TOOL_TYPES.ERASER) {
        ctx.clearRect(point.x * w - blur, point.y * h - blur, w + blur, h + blur);
      } else if (activeTool === TOOL_TYPES.PEN || activeTool === TOOL_TYPES.MIRROR || activeTool === TOOL_TYPES.FILL) {
        ctx.fillRect(point.x * w - blur, point.y * h - blur, w + blur, h + blur);
      }
    });
  }

  processPoints(createSet) {
    const { activeColor, settings } = this.props;
    const { gridSize, cameraX, cameraY } = settings;
    this.pixelStream.forEach((pixel) => {
      const pixelData = {
        x: pixel.x + cameraX,
        y: pixel.y + cameraY,
        color: activeColor,
      };

      if (pixel.x <= gridSize && pixel.y <= gridSize) {
        if (pixel.x >= 0 && pixel.y >= 0) {
          const activeFrame = this.localSet;
          const pixelIndex = activeFrame.findIndex((px) => px.x === pixelData.x && px.y === pixelData.y);

          // replace pixel at index if its already drawned on screen
          if (pixelIndex > -1 && activeFrame[pixelIndex].color !== pixelData.color) {
            if (pixelData.color !== 'transparent') {
              this.replacePixel(pixelIndex, pixelData.color);
            } else {
              // delete pixel if it exists and replace color is transparent
              this.deletePixel(pixelIndex);
            }
          } else if (pixelIndex === -1) {
            // push new pixel to reducer if not transparent
            if (pixelData.color !== 'transparent') {
              this.pushPixel(pixelData);
            }
          }
        }
      }
    });

    // on release
    createSet();
  }

  // local set methods
  pushPixel(pixelData) {
    this.localSet.push(pixelData);
  }

  replacePixel(replaceIndex, color) {
    this.localSet[replaceIndex].color = color;
  }

  deletePixel(deleteIndex) {
    this.localSet.splice(deleteIndex, 1);
  }

  onRelease() {
    this.setState({ isDrawing: false, isFilling: false });
    const layersCopy = JSON.stringify(this.props.data.layers);
    this.props.saveCurrentPixelData(JSON.parse(layersCopy));

    this.processPoints(() => {
      // console.log(this.localSet)

      const reducedSet = [];
      reducePixels(this.localSet, reducedSet);
      const newSet = reducedSet.sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y); // eslint-disable-line

      /*
      console.log('\n\n\n\n')
      console.log('local set length', this.localSet.length)
      console.log('new set length', newSet.length)
      console.log('new set', newSet)
      */

      this.props.setPixels(newSet);
      this.props.generateSpriteData();
    });
  }

  render() {
    const { width } = this.props.screen;
    return (
      <View
        className={styles.container}
        style={{
          backgroundColor: 'transparent', // this.state.isDrawing ? 'rgba(0,0,0,0.05)' : 'transparent',
          left: (window.innerWidth - width) / 2,
          top: (window.innerHeight - 25 - width) / 2,
          width: width,
          height: width,
        }}
      >
        <canvas
          width={width}
          height={width}
          ref={this.drawCanvas}
          style={{ transform: `scale(${1.0 - this.props.scale})`, marginLeft: 0, marginTop: 0 }}
        />
      </View>
    );
  }
}

const mapState = (state) => ({
  screen: state.settings.screen,
  frame: state.data.currentFrame,
  brush: state.settings.brush,
  loading: state.data.loadingAnimation,
  data: state.data,
  tools: state.tools,
  activeTool: state.tools.activeTool,
  activeColor: state.tools.activeColor,
  settings: state.settings,
  gridSize: state.settings.gridSize,
  scale: state.settings.scaleOffset,
  mirrorX: state.tools.mirrorX,
  mirrorY: state.tools.mirrorY,
});

const mapDispatch = (dispatch) => ({
  setPixels: (data) => dispatch(setPixels(data)),
  setCanvasRef: (ref) => dispatch(setDrawCanvasRef(ref)),
  setColor: (color) => dispatch(selectColor(color)),
  generateSpriteData: () => dispatch(generateSpriteData()),
  saveCurrentPixelData: (layers) => dispatch(saveCurrentPixelData(layers)),
  hover: (bool) => dispatch(setCanvasHover(bool)),
});

export default connect(mapState, mapDispatch)(DrawCanvas);
