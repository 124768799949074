import React from 'react';
import { Text, Image, View, Button } from '../BaseComponents';
import * as assets from '../../config/assets';
import styles from '../styles/Frames.module.scss';

class FrameRow extends React.Component {
  getFrameBorder(index) {
    return index === this.props.data.currentFrame ? '#ffffff' : '#333333';
  }

  getFrameIndexBgColor(index) {
    return index === this.props.data.currentFrame ? '#000' : '#303030';
  }

  getFrameIndexColor(index) {
    return index === this.props.data.currentFrame ? '#FFF' : '#707070';
  }

  getFrameBg(index) {
    return index % 2 ? '#222222' : '#252525';
  }

  getframeBottomBorder(index) {
    return index === this.props.data.currentFrame ? 'white' : 'transparent';
  }

  hasValid64SData(index = this.props.data.currentFrame) {
    return (
      this.props.data.layers[this.props.data.currentLayer] &&
      this.props.data.layers[this.props.data.currentLayer].rawData[index] &&
      parseInt(this.props.data.layers[this.props.data.currentLayer].rawData[index].length, 10) > 20
    );
  }

  renderFrame64(index) {
    if (this.hasValid64SData(index)) {
      return (
        <Image
          src={this.props.data.layers[this.props.data.currentLayer].rawData[index]}
          className={styles.frame64Image}
          style={{
            // backgroundColor: index === this.props.data.currentFrame ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.1)',
            width: 100,
            height: 100,
          }}
        />
      );
    }
    return (
      <View
        className={styles.frame64Image}
        style={{
          backgroundColor: 'transparent',
          width: 100,
          height: 100,
        }}
      />
    );
  }

  getBackground(index) {
    return index === this.props.data.currentFrame ? '#1c1c1c' : this.getFrameBg(index);
  }

  getframeTopBorder() {
    return 'transparent';
  }

  render() {
    const index = this.props.def || 0;
    const backgroundColor = this.props.backgroundColor;
    return (
      <View
        className={styles.frameRow}
        style={{
          borderBottomColor: this.getframeBottomBorder(index),
          borderTopColor: this.getframeBottomBorder(index),
          backgroundColor: this.props.isCurrent ? '#101010' : this.getBackground(index),
        }}
      >
        <View
          className={styles.frameSnapshotContainer}
          style={{ background: backgroundColor !== 'transparent' ? backgroundColor : 'rgb(37, 37, 37)' }}
        >
          <Button
            className={styles.frameSnapshot}
            style={{ borderColor: this.getFrameBorder(index), position: 'relative' }}
            description="select frame"
            position={{ marginTop: 40, marginLeft: 10 }}
            onClick={() => {
              this.props.selectFrame(index);
              // backgroundImage: assets.frametra,
            }}
          >
            <View className={styles.frameSnapshotImageContainer}>{this.renderFrame64(index)}</View>
            <View className={styles.frameIndexBox} style={{ backgroundColor: this.getFrameIndexBgColor(index) }}>
              <Text
                className={styles.frameSnapshotIndexText}
                style={{
                  color: this.getFrameIndexColor(index),
                }}
              >
                {index + 1}
              </Text>
            </View>
          </Button>
        </View>

        <View className={styles.framesBtnContainer}>
          <Button
            className={styles.btnContainer}
            style={{
              borderColor: '#333333',
              backgroundColor: 'transparent',
            }}
            description="clone (c)"
            position={{ marginTop: '35px' }}
            onClick={() => this.props.cloneFrame(index)}
          >
            <Image src={assets.clone} className={styles.btnImage} />
          </Button>
          <Button
            className={styles.btnContainer}
            style={{
              borderColor: '#333333',
              backgroundColor: 'transparent',
            }}
            description="clear (x)"
            position={{ marginTop: '35px' }}
            onClick={() => this.props.clearFrame(index)}
          >
            <Image src={assets.clear} className={styles.btnImage} />
          </Button>
          <Button
            className={styles.btnContainer}
            style={{
              borderColor: '#333333',
              backgroundColor: 'transparent',
            }}
            description="delete"
            position={{ marginLeft: '-10px', marginTop: '35px' }}
            onClick={() => this.props.deleteFrame(index)}
          >
            <Image src={assets.del} className={styles.btnImage} />
          </Button>
        </View>
      </View>
    );
  }
}

export default FrameRow;
